.banner-text2 {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  margin-left: 45px;
}
.banner-section2 {
  float: right;
  width: 16%;
  max-width: 100%;
  border: 1px solid #00ced1;
  background-color: #00ced1;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-radius: 50px;
}
.banner-border2 {
  border: 1px solid #40e0d0;
  background-color: #00a5a7;
  float: left;
  width: 99.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 10px;
  max-width: 100%;
}
.banner-text2 {
  margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin-left: 40px;
}
