.category-save {
  padding: 8px;
  width: 20%;
  border: 1px solid #3fad72;
  background: #3fad72;
  color: #fff;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cat-inputll {
  margin-top: 20px;
  margin-left: 7px;
  width: 20%;
  border: 1px solid #ccc;
  background-color: #3fad7282;
}

.edit-namePP {
  float: left;
  margin-top: 20px;
  margin-left: 50px;
}

.save-category-section {
  float: left;
  width: 95%;
  margin-left: 20px;
  /* border-top: 2px solid darkgrey;
    border-bottom: 2px solid darkgray;
    background-color: #eee; */
}

.added-categories {
  float: left;
  width: 100%;
  max-width: 100%;
}

.asterisk {
  color: red;
}

.cat-check {
  margin-left: 10px;
  margin-top: 25px;
  float: left;
}

.cat-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  list-style: none;
}

.cat-header>li {
  float: left;
  margin-top: 18px;
  font-size: 17px;
  font-weight: 700;
  width: 235px;
}

.cat-header22 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 6px;
}

.cat-header22>li {
  margin-top: 12px;
  font-size: 13px;

  font-weight: 600;
  width: 180px;
  text-align: center;
}

.categories-border {
  float: left;
  border: 1px solid green;
  background-color: green;
  color: #fff;
  width: 91%;
  max-width: 100%;
  margin-left: 50px;
  margin-top: 50px;
}

.categories-border2 {
  float: left;
  border: 1px solid #3fad7263;
  background-color: #3fad7263;
  color: #000;
  width: 76%;
  max-width: 100%;
  margin-left: 50px;
  border-top: none;
}
.categoryName{
  position: relative;
}
.categories-border33 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #3fad7282;
  background-color: #3fad7282;
  color: #000;
  width: 91%;
  max-width: 100%;
  margin-left: 50px;
  border-top: none;
}

.cat-edit {
  float: left;
  margin-left: 35px;
  width: 15%;
  max-width: 100%;
  margin-top: 15px;
  padding-left: 0;
}

.count-category {
  width: 41px;
}

.cat-edit>li {
  float: left;
  list-style: none;
  border-right: 1px solid #000;
  border-color: #999;
  margin-right: 10px;
  padding-right: 10px;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
}
.cat-drop .invalid-feedback{
  position: absolute;
}
.CatInput {
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #DEE2E6;
  border-radius: 3px;
  width: 100%;
  padding: 0px 10px;
  position: relative;
  background-repeat: no-repeat;
  background-image: unset !important;
}
.add-new-category:focus{
  color: #fff;
  background-color: #00833D;
  border-color: #00833D;
  box-shadow:none;
}
.CatInput input::placeholder {
  color: #B1B1B1;
}

.cat-drop {
  position: relative;
}

.Catdown {
  position: absolute;
  bottom: 14px;
  right: 10px;
  height: 20px;
  width: 20px;
  z-index: 9;
  color: #666666;

}
label.form-label{
  font-size: 15px;
  line-height: 18px;
}
.add-new-category{
  width: 100%;
  height: 40px;
  border: 1px solid #00833D;
  border-radius: 3px !important;
  background-color: #00833D;

}
.add-new-category:hover{
  background-color: #078a44;
}

.add-category-section {
  border: 1px solid #fff;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 15%); */
  width: -webkit-fill-available;
  max-width: 100%;
  float: left;
  /* margin-left: 28px; */
  border-radius: 10px;
  margin-top: 60px;
  padding: 0px 20px;
}

.searchButton-table {
  position: unset;
  white-space: nowrap;
}

.searchbar-table {
  /* margin-right: 12px; */
}