.cart-section {
    float: right;
    width: 16%;
    border: 1px solid #4265af;
    background-color: #4265af;
    border-radius: 50px;
    margin-right: 10px;
    margin-top: 15px;
    padding: 5px;
}
.cart-icon {
    font-size: 65px;
    color: #fff;
    margin-top: 0px;
    margin-left: 0px;
    float: left;
}
.order-number {
    margin-bottom: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #fff;
}
.order-text {
    margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
}
.order-numbers {
    float: left;
    margin-left: 18px;
    margin-top: 14px;
}
.order-border {
    border: 1px solid darkblue;
    background-color: darkblue;
    float: left;
    width: 99.5%;
    border-bottom-right-radius:10px ;
    border-bottom-left-radius:10px ;
    margin-top: 10px;
}
.border-text {
    margin: 0;
    color: #fff;
    margin-left: 10px;
    font-size: 11px;
    padding: 5px;
}
.order-icon2 {
    font-size: 56px;
    margin-top: 7px;
    margin-left: 4px;
    float: left;
    border: 1px solid #4265af;
    padding: 8px;
    background-color: #fff;
    color: #4265af;
    border-radius: 28px;
}