.MuiPopover-paper {
    margin-left: 101px;
    margin-top: 23px;
    width: 7%;
    padding-bottom: 5px;
}
.MuiList-root > li {
    color: #fff;
    text-align: center;
    padding-bottom: 24px !important;
    height: 0;
    font-size: 8px;
    margin-top: 5px;
    width: 88%;
    position: relative;
    left: 6px;
    padding-top: 4px !important;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
}
/* .MuiButtonBase-root {
    display: inherit !important;
}
.MuiListItem-root {
    display: inherit !important;
} */
.MuiMenu-paper {
    left: 1040px !important;
}
.videomenu-setting {
    font-size: 19px;
    float: right;
    border: 1px solid green;
    border-radius: 50px;
    background-color: green;
    color: #fff;
    padding: 2px;
    position: relative;
    left: -9px;
    top: 5px;
}
.remove-btn {
    border: 1px solid blue !important;
    background-color: blue !important;
}
.likes-btn {
    border: 1px solid gold;
    background-color: gold !important;
}
.dislike-btn {
    border: 1px solid red;
    background-color: red !important;
}