.attribute-select {
  float: left;
  margin-left: 20px;
  margin-top: 30px;
  width: 100%;
}
.attribute-select > select {
  padding: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border: none;
}
.attribute-select > button {
  border: 1px solid #eee;
  padding: 4px;
  width: 8%;
  margin-left: 20px;
  border-bottom: 2px solid #ccc;
}
.attribute-bottom {
  border-bottom: 1px solid #eee;
  float: left;
  width: 100%;
  margin-top: 20px;
}
.attribute-name {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  width: 36%;
}
.attribute-name > p {
  margin-bottom: 0;
}
.attribute-name > input {
  width: 85%;
  border: 1px solid #ccc;
  margin-top: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.attribute-value {
  float: left;
  margin-top: 20px;
  width: 59%;
}
.attribute-value > p {
  margin-bottom: 0;
}
.attribute-value > textarea {
  width: 95%;
  border: 1px solid #ccc;
  margin-top: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  height: 110px;
}
.att-visible {
  float: left;
  width: 85%;
  margin-left: 0;
  margin-top: 38px;
  font-size: 12px;
  margin-bottom: 0 !important;
}
.att-image-upload > .fileContainer {
  width: 100% !important;
}
.att-type {
  float: left;
  width: 100%;
  border: 1px solid green;
  background-color: green;
  margin-left: 10px;
  color: #fff;
  position: relative;
  text-align: center;
}
.att-type > p {
  margin-bottom: 0;
  text-align: center;
}
.price-input::placeholder {
  padding-left: 5px;
}
.price-input {
  width: 100%;
  padding-left: 5px;
  margin-left: 10px;
  border: 1px solid #ccc;
}

.child {
  border: 1px solid #eee;
  padding: 4px;
  width: 30%;
  margin-left: 20px;
  border-bottom: 2 px solid #ccc;
}
.more-att-btn {
  margin-top: 50px;
    float: left;
    width: 100%;
    margin-left: 20px;
}
.more-att-btn > button {
  padding: 8px;
    width: 25%;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
}