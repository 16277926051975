.calender-section {
    float: left;
    width: 80%;
    max-width: 100%;
    margin-left: 30px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 0;
    padding: 20px;
}
.calender-text {
    margin-left: 28px;
    float: left;
    margin-bottom: 0;
    width: 65%;
    margin-top: 20px;
}
.day-pick {
    width: 7%;
    margin-right: 20px;
    padding: 5px;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.picker {
    float: left;
    width: 100%;
    margin-left: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.calender-image {
    display: block;
    margin: 0 auto;
}
.calender-section2 {
    float: left;
    width: 65%;
    margin-left: 28px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 40px;
    padding: 20px;
}
.world-image {
    width: 100%;
}
.circular-image {
    float: right;
    width: 15%;
    max-width: 100%;
    margin-right: 5px;
}
.calender-event-btn {
    position: relative;
    left: 720px;
    top: 40px;
    border: 1px solid green;
    background-color: green;
    color: #fff;
    padding: 7px;
    width: 9%;
    border-radius: 5px;
} 
.calender-heading {
    float: left;
    width: 65%;
}