.order-product-section {
    float: left;
    border: 1px solid #ccc;
    width: 50%;
    margin-top: 50px;
    border-top: none;
    border-bottom: none;
    height: 505px;
}
.order-icon {
    font-size: 50px;
    display: block;
    margin: 0 auto;
    color: #3fad72;
}
.product-order {
    float: left;
    width: 23%;
    border: 5px solid #ccc;
    padding: 12px;
    margin-bottom: 20px;
    margin-right: 32px;
}
.product-order > img {
    float: left;
    width: 100%;
}
.order-single-info {
    float: left;
    width: 25%;
    margin-top: 50px;
    margin-left: 34px;
}
.order-checkout-section {
    float: left;
    width: 100%;
    border: 1px solid #eee;
    background-color: #eee;
    border-radius: 10px;
}
.guest-check {
    margin-left: 20px;
    margin-top: 10px;
    font-weight: 700;
}
.guest-email {
    margin-left: 20px;
    margin-top: 10px;
    color: #3fad72;
    float: left;
}
.order-user-email {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
}
.order-checkout-section2 {
    float: left;
    width: 100%;
    border: 1px solid #eee;
    background-color: #eee;
    border-radius: 10px;
    margin-top: 20px;
}
.order-user-email2 {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
}
.order-user-email3 {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
}
.card-expiry {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 20px;
}
.cvv-code {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 20px;
}
.order-user-email4 {
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 10;
}
.order-sub {
    float: left;
    font-size: 13px;
    font-weight: 600;
    margin-top: 40px;
    margin-left: 20px;
}
.order-sub-amount {
    float: right;
    font-size: 13px;
    font-weight: 600;
    margin-top: 40px;
    margin-right: 20px;
}
.order-sub2 {
    float: left;
    font-size: 13px;
    font-weight: 600;
    margin-left: 20px;
}
.order-sub-amount2 {
    float: right;
    font-size: 13px;
    font-weight: 600;
    margin-right: 20px;
}
.order-sub3 {
    float: left;
    font-size: 15px;
    margin-left: 20px;
    font-weight: 700;
}
.order-sub-amount3 {
    float: right;
    font-size: 15px;
    font-weight: 700;
    margin-right: 20px;
}