.MuiList-root > li {
    color: #fff;
    text-align: center;
    height: 57px;
    border-radius: 1px 1px 1px 1px !important;
    margin-left: 0 !important;
}
/* .MuiButtonBase-root {
    display: inherit !important;
}
.MuiListItem-root {
    display: inherit !important;
} */
.MuiMenu-paper {
    left: 1040px !important;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiList-root > .refund-menu {
    background-color: red !important;
}
.MuiList-root > .reorder-menu {
    background-color: gold !important;
}
.MuiList-root > .track-menu {
    background-color: blue !important;
}   
.MuiList-root > .inv-menu {
    background-color: orange !important;
}
.receipt-gear {
    margin-top: 5px;
}

#receipt-menu > .MuiMenu-paper {
    left: 1003px !important;
    margin-top: 15px;
    background-color: #3FAD72;
}
#receipt-menu > .MuiMenu-paper > .MuiMenu-list > li {
    background-color: #3FAD72 !important;
    font-size: 10px !important;
    height: 24px !important;
    text-align: center !important;
    left: 3px !important;
}