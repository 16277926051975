.banner-card {
  float: left;
  width: 77%;
  max-width: 100%;
  margin-left: 25px;
  margin-top: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  padding-bottom: 50px;
}
.mobile2 {
  float: left;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 25px;
  width: 21%;
  max-width: 100%;
  margin: 70px;
  height: 750px;
}
.not-text {
  margin-bottom: 0;
  margin-left: 15px;
  margin-top: 20px;
  font-weight: 700;
}

.saveyour {
  padding: 8px;
  /* width: 10%; */
  border: 1px solid #3fad72;
  background: #3fad72;
  color: #fff;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.saveyourCat {
  padding: 8px;
  width: 40%;
  border: 1px solid grey;
  background: #ffffff;
  color: #000;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.saveyourAdd {
  padding: 8px;
  /* width: 10%; */
  border: 1px solid #3fad72;
  background: #3fad72;
  color: #fff;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.FFF {
  display: flex;
  flex-direction: row;
}
.products-scroll2 {
  /* float: right; */
  overflow-y: scroll;
  height: 580px;
}
.alice-carousel__dots {
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;
  float: left;
  width: 100%;
}
