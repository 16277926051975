.pin-card {
    border: 1px solid #eee;
    float: left;
    width: 24%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-right: 10px;
}
.pin-image {
    width: 45%;
    border-radius: 100px;
}
.pinned-user {
    margin-bottom: 0;
    color: #3fad72;
    font-weight: 700;
}
.pinned-class {
    font-size: 15px;
    font-weight: 600;
}
.pinn-icon1 {
    margin-right: 20px;
    color: royalblue;
    font-size: 22px;
}
.pinn-icon2 {
    margin-right: 20px;
    color: green;
    font-size: 22px;
}
.pinn-icon3 {
    color: indigo;
    font-size: 22px;
}
.pinn-btn1 {
    border: 1px solid green;
    background-color: green;
    color: #fff;
    width: 40%;
    margin-right: 15px;
    border-radius: 5px;
    padding-bottom: 5px;
    margin-left: 19px;
    font-size: 14px;
}
.pinn-btn2 {
    border: 1px solid cyan;
    background-color: cyan;
    color: #fff;
    width: 40%;
    margin-right: 15px;
    border-radius: 5px;
    padding-bottom: 5px;
    margin-bottom: 40px;
    font-size: 14px;
}
.pin-btn-new {
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
    padding: 6px;
    margin-left: 50px;
    margin-top: 30px;
    width: 6%;
}
.pin-btn-new > a {
    color: #fff;
    text-decoration: none;
}
.pin-card > a {
    color: #888;
    text-decoration: none;
}