.pin-card {
  border: 1px solid #eee;
  float: left;
  width: 24%;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  margin-right: 10px;
}
.pin-image {
  width: 45%;
  border-radius: 100px;
}
.pinned-user {
  margin-bottom: 0;
  color: #3fad72;
  font-weight: 700;
}
.pinned-class {
  font-size: 15px;
  font-weight: 600;
}
.pinn-icon1 {
  margin-right: 20px;
  color: royalblue;
  font-size: 22px;
}
.pinn-icon2 {
  margin-right: 20px;
  color: green;
  font-size: 22px;
}
.pinn-icon3 {
  color: indigo;
  font-size: 22px;
}
.pinn-btn1 {
  border: 1px solid green;
  background-color: green;
  color: #fff;
  width: 40%;
  margin-right: 15px;
  border-radius: 5px;
  padding-bottom: 5px;
  margin-left: 19px;
  font-size: 14px;
}
.pinn-btn2 {
  border: 1px solid cyan;
  background-color: cyan;
  color: #fff;
  width: 40%;
  margin-right: 15px;
  border-radius: 5px;
  padding-bottom: 5px;
  margin-bottom: 40px;
  font-size: 14px;
}
.support-btn {
  border: 1px solid purple;
  background-color: purple;
  border-radius: 40px;
  margin-top: 40px;
  width: 100%;
  float: left;
}
.support-btn2 {
  border: 1px solid blue;
  background-color: blue;
  border-radius: 40px;
  margin-top: 40px;
  width: 100%;
  float: left;
}
.support-btn3 {
  border: 1px solid red;
  background-color: red;
  border-radius: 40px;
  margin-top: 40px;
  width: 100%;
  float: right;
}
.download-section {
  float: right;
  width: 16%;
  border: 1px solid gold;
  background-color: gold;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 15px;
}
.download-border {
  border: 1px solid goldenrod;
  background-color: goldenrod;
  float: left;
  width: 99.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 10px;
}
.order-text-support {
  margin: 0;
  text-align: left;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}
.order-numbers-support {
  float: left;
  margin-left: 8px;
  margin-top: 14px;
}
