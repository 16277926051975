.search-1 {
    float: left;
    width: 70%;
    margin-left: 135px;
    margin-top: 60px;
}

.page-title {
    letter-spacing: 0px;
    color: #020202;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    /* margin-left: 10px; */
}

.search-page-result {
    border: 1px solid #fff;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 15%); */
    width: -webkit-fill-available;
    max-width: 100%;
    float: left;
    /* margin-left: 28px; */
    border-radius: 10px;
    margin-top: 60px;
    padding: 0px 20px;
}

.img-search {
    height: 51px;
    width: 51px;
    background-color: #f5f5f5;
}

.search-row {
    padding: 10px;
    box-shadow: 0px 3px 6px #DEE2E6;
    border: 1px solid #DEE2E6;
    border-radius: 3px;
    background-color: #fff;
    margin-top: 12px;
}

.product-name {
    font-size: 18px;
    line-height: 24px;
    color: #020202;
    font-weight: 600;
}

.table-border-right {
    border-right: 1px solid #DEE2E6;
}

.img-profile {
    height: 39px;
    width: 39px;
    background-color: #f5f5f5;
    border-radius: 50%;
}

.profile-search-name,
.search-table-price {
    font-size: 15px;
    line-height: 20px;
    color: #020202;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 0px;
}

.search-delete {
    background-color: #dc3644 !important;
    color: #fff;
    border-radius: 5px !important;
    border-color: #AC0210;
    border: 1px solid;
}

.search-result-count {
    font-size: 15px;
    line-height: 20px;
    color: #666666;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: right;
}
.search-margin{
    margin: 15px 0;
}
.search-margin-5{
    margin: 5px 0;
}
.search-margin-6{
    margin: 6px 0;
}