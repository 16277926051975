.mobile1 {
  float: left;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 25px;
  width: 21%;
  max-width: 100%;
  margin: 70px;
  height: 650px;
}
.speaker {
  display: block;
  margin: 0 auto;
  width: 20%;
  max-width: 100%;
  margin-top: 20px;
  border-bottom: 5px solid #ccc;
}
.banner-image {
  margin-top: 40px;
  border: 6px solid #fff;
}
.banner-image > h2 {
  color: #fff;
  text-align: center;
  padding-top: 100px;
}
.mob-product1 {
  float: left;
  width: 40%;
  max-width: 100%;
  height: 200;
  margin-left: 27px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.mob-product1 > img {
  display: block;
  margin: 0 auto;
  width: 85%;
  max-width: 100%;
}
.mob-product-details {
  text-align: center;
  max-width: 100%;
  margin-bottom: 10px;
}
.mob-product-details > p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.products-scroll {
  float: right;
  overflow-y: scroll;
  height: 414px;
}
.sliderimg {
  width: 100%;
  height: 150px;
  background-size: contain;
  object-fit: cover;
  border: 6px solid #fff;

  margin-top: 5px;
}
.alice-carousel__stage-item {
  width: 100% !important;
}