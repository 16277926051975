.coupon-select-email {
    float: left;
    margin-left: 128px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-email > h5 {
    float: left;
}
.coupon-select-business {
    float: left;
    margin-left: 130px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-business > h5 {
    float: left;
}
.coupon-select-password {
    float: left;
    margin-left: 150px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-password > h5 {
    float: left;
}
.coupon-heading {
    float: left;
    margin-left: 50px;
}
.coupon-select-country {
    float: left;
    margin-left: 160px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-country > h5 {
    float: left;
}
.coupon-select-city {
    float: left;
    margin-left: 197px;
    margin-top: 60px;
    width: 69.4%;
}
.coupon-select-city > h5 {
    float: left;
}
.coupon-select-street {
    float: left;
    margin-left: 100px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-street > h5 {
    float: left;
}
.coupon-select-post {
    float: left;
    margin-left: 125px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-post > h5 {
    float: left;
}
.coupon-select-phone {
    float: left;
    margin-left: 93px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-phone > h5 {
    float: left;
}
.coupon-select-card {
    float: left;
    margin-left: 160px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-card > h5 {
    float: left;
}
.coupon-select-choice {
    float: left;
    margin-left: 125px;
    margin-top: 60px;
    width: 70%;
}
.coupon-select-choice > h5 {
    float: left;
}
.fileContainer {
    width: 70% !important;
    height: 160px !important;
}
.coupon-area {
    margin-left: 10px;
    width: 45%;
    border: 1px solid #ccc;
    padding-left: 5px;
    height: 140px;
}
.hava-versions {
    float: left;
    margin-left: 95px;
    margin-top: 10px;
}
.reg-vendor {
    float: left;
    width: 21%;
    margin-left: 325px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    color: #fff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.vendor-1 {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 6px;
    padding-left: 5px;
}
.vend-forms {
    float: left;
    width: 80%;
    border: 1px solid #ccc;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 50px;
    background-color: #3fad7247;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.id-box {
    float: left;
    width: 18%;
    position: absolute;
    left: 473px;
    top: 1087px;
}
.id-box > img {
    float: left;
    width: 86%;
}
.vendor-btns {
    float: left;
    padding-left: 0;
    right: 0;
    top: 0;
    margin-left: 265px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.vendor-btns > li > button {
    float: left;
    width: 122%;
}
.vendor-btns > li {
    list-style: none;
    float: left;
    margin-right: 30px;
}