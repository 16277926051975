.vendor-section {
  float: left;
  width: 80%;
  max-width: 100%;
  margin-left: 28px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 10px;
  padding-bottom: 30px;
  height: 400px;
  overflow-y: scroll;
}
.vendors {
  margin-left: 55px;
  margin-top: 20px;
  margin-bottom: 25px;
}
.active {
  border-bottom: 3px solid #3fad72;
  width: 8.3%;
}
.left {
  float: left;
}
.vendor-image {
  width: 6%;
  margin-top: 10px;
  margin-left: 55px;
  float: left;
  border-radius: 100px;
}
.vendor-name {
  margin-top: 15px;
  float: left;
  margin-left: 18px;
  margin-right: 0px;
  width: 13%;
}
.vendor-button {
  font-size: 11px;
  width: 12%;
  border: none;

  margin-top: 17px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 15px;
}
.vendor-button4 {
  font-size: 11px;
  width: 12%;
  border: none;
  background-color: blue;
  margin-top: 17px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 15px;
}
.vendor-button2 {
  font-size: 11px;
  width: 12%;
  border: none;
  background-color: red;
  margin-top: 17px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 15px;
}
.vendor-button3 {
  font-size: 11px;
  width: 12%;
  border: none;
  background-color: turquoise;
  margin-top: 17px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 15px;
}
.more {
  font-size: 22px;
  position: relative;
  top: 0px;
  left: 6px;
  color: #ccc;
}
.vendor-marg {
  margin-top: 30px;
}
.vendor-heading {
  float: left;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.vendor-email {
  margin-top: 15px;
  float: left;
  margin-left: 18px;
  margin-right: 5px;
  width: 12%;
}