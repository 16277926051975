.videoTitle .video-image {
  width: 30px;
  height: 30px;
}
.videoCard {
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 3px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin-top: 10px !important;
  margin: 0px;
  position: relative;
}
.videoCard .video-comment-pause-icon {
  align-items: center;
  justify-content: center;
  display: flex;
}
.replayCard {
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 3px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin: 0px !important;
  position: relative;
}
.img {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
.video-main {
  position: relative;
}

.video-text {
  font: normal normal normal Roboto;
  font-size: 14px;
  letter-spacing: 0px;
  color: #020202;
  line-hight: 19px;
  opacity: 1;
  margin-top: 8px;
  margin-right: 14px;
}
.videoTitle p {
  font-size: 15px;
  margin-bottom: 0px;
}
.viewVideoCard {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px !important;
}
.video-background {
  background: #dee2e6;
  padding: 10px;
  position: relative;
}

.video-background .profile-thumb .profile-photo {
  height: 26px;
  width: 26px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}

.video-background .profile-thumb h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
  align-items: center;
  margin-bottom: 0;
  align-self: center;
}

.video-background .profile-thumb {
  position: absolute;
  top: 10px;
  left: 10px;
}

.video-background .video-timer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 97%;
}

.video-background .video-timer p {
  font-size: 14px;
  color: #fff;
}
.icon-like {
  margin-right: 20px;
}
.social {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
.social-main {
  margin-top: 10px;
}
.upload-date {
  color: #b1b1b1;
}
.profile-me {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.replay {
  font-size: 15px;
  margin-bottom: 0;
  color: #04984a;
}

.menuIcon-dropdown .dropdown-toggle:before {
  content: unset !important;
}
.menuIcon-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
.menuIcon-dropdown .dropdown-toggle svg #Path_68 {
  fill: #f8f8f8 !important;
}
.menuIcon-dropdown .dropdown-menu {
  transform: translate(0px, 6px) !important;
  width: fit-content !important;
  box-shadow: 0px 1px 10px #00000029 !important;
  padding: 0 !important;
  border-radius: 2px !important;
}
.menuIcon-dropdown .dropdown-menu .dropdown-item {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  padding: 10px 12px !important;
}
.menuIcon-dropdown .dropdown-menu .product-item svg path {
  fill: #020202 !important;
  stroke: #020202 !important;
}
.menuIcon-dropdown .dropdown-menu .delete-item {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #dc3644;
  opacity: 1;
}
.menuIcon-dropdown .dropdown-menu .product-item {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
}
.menuIcon-dropdown .dropdown-menu .delete-item:hover {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #dc3644;
  opacity: 1;
}
.videoTitle .menuIcon-dropdown-start {
  position: absolute;
  top: 0;
  right: 0;
}
.videoTitle .menuIcon-dropdown-start .dropdown-menu {
  padding: 0;
}
.icon-like .green-fill #Path_123 {
  fill: #04984a !important;
}
.icon-like .green-fill #Path_87 {
  fill: #04984a !important;
}
.childComment {
  padding-left: 29px;
}
