.payment-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
    margin-top: 60px;
}
.payment-card {
    float: left;
}

.visa {
    width: 3%;
    margin-left: 10px
}
.master {
    width: 4%;
    margin-left: 10px;
    border-radius:5px;
}
.card-text-payment {
    float: left;
    margin-right: 50px;
    width: 20%;
}
.expiry-date {
    width: 6%;
    margin-right: 10px;
}

/* Payment Methods */

.payment-access{
    margin-top: 70px;
}
.payment-card{
    background-color: black;
    position: relative;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    overflow: hidden;
}
.chip{
    height: 50px;
    width: auto;
    margin-right: 20px;
}
.payment-card::before {
    content: " ";
    height: 320px;
    width: 227px;
    background-color: #fff;
    opacity: 0.2;
    position: absolute;
    top: -59px;
    right: -55px;
    transform: rotate(336deg);
}