.comment-header {
  border: 1px solid #fff;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
}
.comment-heading {
  color: #3fad72;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  float: left;
}
.comments-gear {
  margin-top: 38px;
  font-size: 22px;
  color: #3fad72;
  margin-left: 30px;
  float: left;
}
.comment-search {
  float: left;
  margin-top: 28px;
  margin-left: 60px;
  width: 50%;
}
.comment-search > svg {
  position: relative;
  left: 26px;
  bottom: 3px;
  font-size: 20px;
}
.comment-search > input {
  width: 95%;
  padding: 5px;
  padding-left: 35px;
}
.comment-refresh {
  color: #3fad72;
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-left: 60px;
}
.comment-main-section {
  width: 45%;
  margin-top: 25px;
  margin-bottom: 20px;
  float: left;
}
.comment-name {
  float: left;
  margin-left: 25px;
  font-weight: 600;
}
.comment-date {
  float: right;
  margin-right: 60px;
  font-weight: 600;
}
.comment-view {
  width: 100%;
  border: 1px solid green;
  float: left;
}
.comment-image {
  float: left;
  width: 10%;
  margin-left: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.comment-image > img {
  width: 100%;
}
.comment-details {
  float: left;
  margin-left: 20px;
  margin-top: 14px;
  width: 55%;
  max-width: 100%;
}
.comment-user {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  width: 300px;

  color: #000;
}
.comment-desc {
  font-size: 12px;
}
.comment-date2 {
  float: right;
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  margin-top: 25px;
}
.comment-view2 {
  width: 100%;
  border: 1px solid green;
  float: left;
  margin-top: 10px;
  margin-bottom: 15px;
}
.comment-detail-section {
  width: 45%;
  margin-top: 85px;
  margin-bottom: 20px;
  float: right;
  border: 1px solid green;
  padding: 10px;
}
.comm-details {
  float: left;
  width: 15%;
}
.details-image {
  width: 100%;
}
.comment-time {
  float: right;
  margin-right: 40px;
  margin-top: 18px;
  font-size: 13px;
}
.comment-title {
  float: left;
  width: 98%;
  margin-top: 25px;
  font-weight: 500;
}
.comment-inner {
  float: left;
  border: 1px solid green;
  background-color: green;
  width: 98%;
  max-width: 100%;
  margin-top: 10px;
}
.comment-inner > p {
  margin: 0;
  padding: 15px;
  color: #fff;
  width: 100%;
}
.comment-view2:hover {
  background-color: green;
  color: #fff;
}
