.progress-home {
    border: 1px solid #eee;
    border-radius: 5px;
    float: right;
    width: 16%;
    margin-right: 11px;
    margin-top: 15px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.progress1 {
    margin-bottom: 10px;
    margin-top: 30px;
    width: 90%;
}
.progress-text {
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 20px;
}
  .progress-box1 {
      border: 1px solid #3fad2733;
      background-color: #3fad2733;
      float: left;
      width: 40%;
      margin-left: 15px;
      margin-bottom: 10px;
  }
  .progress-box2 {
    border: 1px solid pink;
    background-color: #ffc0cb5c;
    float: left;
    width: 40%;
    margin-left: 15px;
    margin-bottom: 10px;
}
  .box-circle {
      border: 1px solid green;
      width: 33px;
      height: 33px;
      background-color: green;
      display: block;
      margin: 0 auto;
      border-radius: 50px;
      margin-top: 10px;
  }
  .box-circle2 {
    border: 1px solid pink;
    width: 33px;
    height: 33px;
    background-color: pink;
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    margin-top: 10px;
}
  .stat-text {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .stat-num {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .home-progress {
      width: 70%;
  }
  .home-progress > .progressbar-progress {
      height: 4px !important;
        }

.num-progress {
    float: right;
    font-size: 14px;
    background-color: #00800033;
    padding: 5px;
    color: green;
    margin-right: 10px;
    margin-top: -19px;
    font-weight: 700;
    }
.home-progress1 {
    width: 70%;
}
.home-progress1 > .progressbar-progress {
    height: 4px !important;
    background-color: blue !important;
}
.num-progress2 {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    background-color: #0000ff38;
    padding: 5px;
    color: blue;
    font-weight: 700;
    margin-top: -19px;
}
.home-progress3 {
    width: 70%;
}
.home-progress3 > .progressbar-progress {
    height: 4px !important;
    background-color: red !important;
}
.num-progress3 {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    background-color: #ff000040;
    padding: 5px;
    color: red;
    font-weight: 700;
    margin-top: -19px;
}
.home-progress4 {
    width: 70%;
}
.home-progress4 > .progressbar-progress {
    height: 4px !important;
    background-color: red !important;
}
.num-progress4 {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    background-color: #a52a2a45;
    padding: 5px;
    color: brown;
    font-weight: 700;
    margin-top: -19px;
}