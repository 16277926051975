.home-section1 {
    float: left;
    border: 1px solid rgb(243, 230, 230);
    width: 51%;
    margin-left: 35px;
    margin-top: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    padding-bottom: 20px;
}
.home-section2 {
    float: left;
    border: 1px solid rgb(243, 230, 230);
    width: 44%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    padding-bottom: 20px;
    margin-top: 30px;
}
.analytics-filter {
    margin-left: 35px;
    margin-top: 30px;
    width: 100%;
}
.home-filter {
    border: 1px solid green;
    background-color: green;
    color: #fff;
    padding: 5px;
    width: 10%;
    border-radius: 5px;
}
.filter-icon-home {
    margin-right: 10px;
}
.calender-filter {
    float: right;
}
.calender-icon {
    font-size: 25px;
    margin-right: 15px;
}
.analytics-duration {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.section2-text {
    margin-top: 15px;
    margin-left: 20px;
}
.section2-headings {
    float: left;
    margin-left: 25px;
    margin-right: 50px;
}
.section2-lorem {
    margin-bottom: 5px;
}
.section2-num {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}
.trend-border1 {
    border-bottom: 2px solid #ccc;
    width: 93%;
    display: block;
    margin: 0 auto;
}
.trend-borders {
    float: left;
    width: 100%;
}
.trend-borders2 {
    width: 100%;
    float: left;
    margin-top: 80px;
}
.dash-user-section {
    float: left;
    border: 1px solid rgb(243, 230, 230);
    width: 83%;
    margin-left: 35px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.analytics-filter2 {
    margin-left: 35px;
    margin-top: 30px;
    width: 97%;
    max-width: 100%;
}
.menu-dots {
    margin-right: 30px;
}
.dash-border {
    float: left;
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.list-dash > p {
    float: left;
    width: 14%;
}