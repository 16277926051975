.common-tab-menu .nav-pills .nav-item .nav-link {
    display: unset !important;
}

.common-tab-menu .nav-pills .nav-item:has(> .active) {
    background: #04984a 0% 0% no-repeat padding-box !important;
    border-radius: 3px 0px 0px 3px !important;
    opacity: 1;
    padding: 10px 0;
}

.common-tab-menu .nav-pills .nav-item {
    transition: 0s !important;
}

.common-tab-menu .nav-pills .nav-item .active {
    background-color: unset !important;
}

.common-tab-menu .nav-pills .nav-item {

    border-radius: 0px 3px 3px 0px;
    /* padding: 10px 31px; */
    opacity: 1;
    align-self: center;
}

.common-tab-menu .nav-pills .nav-item .nav-link {
    font: normal normal normal 15px/20px Roboto;
    background: #dee2e6 0% 0% no-repeat padding-box;
    padding: 13px 62px;
    letter-spacing: 0px;
    color: #020202;
    opacity: 1;
}

.common-tab-menu .nav-pills .item-all-streams .active {
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
}
.common-tab-menu .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: max-content;
}
.common-tab-menu .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: max-content;
}
.nav-tab-main {
    overflow-x: auto;
    overflow-y: hidden;
}
.nav-tab-main::-webkit-scrollbar{
    display: none;
}