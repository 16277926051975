.notification-section {
  float: left;
  width: 40%;
  max-width: 100%;
  margin-left: 25px;
  margin-top: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  padding-bottom: 50px;
}
.notification-section2 {
  float: left;
  width: 77%;
  max-width: 100%;
  margin-left: 25px;
  margin-top: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  padding-bottom: 50px;
}
.not-text {
  margin-bottom: 0;
  margin-left: 15px;
  margin-top: 20px;
  font-weight: 700;
}
.not-border {
  margin-left: 15px;
  border-bottom: 1px solid #ccc;
  float: left;
  width: 88%;
}
.not-card {
  float: left;
  border: 1px solid #eee;
  width: 93%;
  max-width: 100%;
  margin-left: 15px;
  margin-top: 50px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  background-color: rgba(243, 246, 249, 1);
}
.not-image-user2 {
  float: left;
  width: 14%;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: 15px;
  border-radius: 100px;
}
.not-post-details {
  float: left;
  padding-left: 20px;
  margin-top: 28px;
}
.not-post-details > li {
  list-style: none;
}
.not-react {
  font-weight: 500;
}
.copyright {
  font-size: 12px;
  color: rgba(42, 143, 86, 1);
}
.duration {
  font-size: 10px;
  color: rgba(22, 22, 22, 1);
}
.not-phone {
  float: right;
  width: 15%;
  margin-right: 10px;
  margin-top: 8px;
  height: 100px;
}
.not-card2 {
  float: left;
  border: 1px solid #eee;
  width: 93%;
  max-width: 100%;
  margin-left: 15px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  background-color: rgba(243, 246, 249, 1);
}
.posts-section {
  float: left;
  width: 40%;
  max-width: 100%;
  margin-left: 25px;
  margin-top: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  padding-bottom: 50px;
  max-width: 100%;
}
.video-post-border {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 41%;
  padding-bottom: 5px;
  height: 355px;
}
.view-delete2 {
  float: left;
  position: absolute;
  top: 455px;
  right: 320px;
}
.search-user2 {
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  width: 98%;
}
.not-image-user3 {
  float: left;
  width: 19%;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.not-post-details2 {
  float: left;
  padding-left: 0px;
  margin-top: 28px;
  margin-left: 10px;
}
.not-post-details2 > li {
  list-style: none;
}
.not-react2 {
  font-weight: 500;
  font-size: 11px;
}
.copyright2 {
  font-size: 9px;
  color: rgba(42, 143, 86, 1);
}
.duration2 {
  font-size: 7px;
  color: rgba(22, 22, 22, 1);
}
.video-post-border2 {
  float: left;
  border: 1px solid #eee;
  margin-left: 40px;
  margin-top: 20px;
  width: 41%;
  padding-bottom: 5px;
  height: 355px;
}
.video-users {
  float: left;
  width: 3%;
  position: absolute;
  right: 305px;
  top: 186px;
}
.video-users2 {
  float: left;
  width: 3%;
  position: absolute;
  right: 59px;
  top: 186px;
}
.video1-border-not {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 43%;
  padding-bottom: 5px;
  max-width: 100%;
}
.video-desc-not {
  float: left;
  width: 65%;
  margin-left: 5px;
  max-width: 100%;
}
