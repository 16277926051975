#data-menu > .MuiPopover-paper > .MuiList-root > li {
  color: #fff;
  text-align: center;
  border-radius: 1px 1px 1px 1px !important;
  margin-left: 0 !important;
  left: 0 !important;
  width: 100% !important;
}
/* .MuiButtonBase-root {
  display: inherit !important;
} */
/* .MuiListItem-root {
  display: inherit !important;
} */
.MuiMenu-paper {
  left: 1040px !important;
}
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiList-root > .refund-menu {
  background-color: red !important;
}
.MuiList-root > .reorder-menu {
  background-color: gold !important;
}
.MuiList-root > .track-menu {
  background-color: blue !important;
}
.MuiList-root > .inv-menu {
  background-color: orange !important;
}
.user-gear {
  float: left;
  margin-top: 5px;
  color: #fff;
}
.user-menu-text {
  float: left;
  padding-left: 16px;
  color: #fff;
}
#data-menu > .MuiMenu-paper {
  left: 105px !important;
  margin-top: 15px;
  background-color: #3fad72;
}
#data-menu > .MuiMenu-paper > .MuiMenu-list > li {
  background-color: #3fad72 !important;
  font-size: 10px !important;
  height: 30px;
}
