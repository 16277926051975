.map-selected {
  fill: #e3da37;
}

.map-unselected {
  fill: #699eaa;
}

.map-selected:hover,
.map-unselected:hover {
  cursor: pointer;
}
.map-section {
  float: left;
  width: 80%;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-left: 31px;
  margin-top: 30px;
}
.map-percent-section {
  float: left;
  width: 25%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  margin-right: 30px;
}
.map-percent-section > h2 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.percent-seperator {
  float: left;
  border-top: 2px solid #888;
  width: 100%;
  max-width: 100%;
  margin-bottom: 60px;
}
.map-img {
  margin-top: 80px !important;
  width: 750px !important;
  margin-left: 25px !important;
}
.map-percent-section > h4 {
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
}

.worldmap__figure-container {
  margin-left: 40px;
}
