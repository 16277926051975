.product-order-edit {
    width: 50%;
    margin-top: 50px;
    margin-left: 25px;
}
.product-order-edit > img {
    width: 25%;
    float: left;
}
.product-order-edit > p {
    float: left;
    font-weight: 600;
    margin-top: 25px;
}
.edit-order-fields {
    margin-left: 25px;
    width:100%;
}
.payment-field-text {
    float: left;
    width: 20%;
}
.edit-payment-field {
    float: left;
    width: 35%;
    padding-left: 10px;
    color: #888;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.payment-field-edit {
    font-size: 15px;
    font-weight: 700;
    color: blue;
    margin-top: 7px;
    margin-left: 20px;
    float: left;
}