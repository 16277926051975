.user-notes {
    float: left;
    width: 83%;
    margin-left: 25px;
    margin-top: 25px;
}
.notes-box {
    float: left;
    width: 70%;
    margin-left: 25px;
    margin-top: 20px;
    padding: 40px;
    padding-bottom: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.user-textarea {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    padding-left: 5px;
}
.user-note-btn {
    float: right;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    padding: 10px;
    width: 13%;
    border-radius: 5px;
    margin-top: 20px;
}
.user-msg1 {
    float: left;
    border: 1px solid #eee;
    margin-left: 74px;
    width: 63%;
    margin-top: 70px;
    padding: 20px;
    padding-bottom: 8px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.by-admin {
    float: left;
    width: 68%;
    margin-left: 72px;
    margin-top: 8px;
    font-size: 12px;
    color: #888;
}