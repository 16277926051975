.main {
  float: left;
  border: 1px solid #fff;
  width: 55%;
  max-width: 100%;
  margin-left: 30px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);

  background-color: aliceblue;
}
.main2 {
  margin-top: 20px;
  float: left;
  background-color: aliceblue;
  width: 100%;
}
.main2 > li {
  list-style: none;
  float: left;
  /* margin-right: 140
px
; */
  /* margin-left: 51
px
; */
  margin-top: 10px;
  width: 180px;
  font-weight: bold;
  font-size: medium;
}
.mainDetail {
  float: left;
  width: 100%;
}
.mainImage {
  width: 6%;
  margin-top: 25px;
  margin-left: 28px;
  float: left;
  border-radius: 30px;
}
.mainName {
  margin-top: 43px;
  float: left;
  /* margin-left: 18
    px
    ; */
  font-weight: 700;
  font-size: 13px;
  width: 10%;
}
.mainEmail {
  margin-top: 43px;
  float: left;
  margin-left: 50px;
  font-size: 13px;
  font-weight: 700;
}
.mainBorder {
  border-bottom: 40px solid #ffffff;
  float: left;
  width: 100%;
  margin-top: 10px;
}
.back-arrow {
  float: left;
    margin-left: 30px;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 700;
}
.mainNum {
  margin-top: 43px;
    float: left;
    margin-left: 35px;
    width: 15%;
    max-width: 100%;
    font-size: 13px;
    font-weight: 700;
}
