.order-timeline > li {
    right: 92px;
    top: 20px;
    width: 118%;
}
.MuiTimelineDot-outlinedGrey {
    border-color: #3fad72 !important;
}
.MuiTimelineDot-outlinedPrimary {
    border-color: #3fad72 !important;
}
.MuiTimelineDot-outlinedSecondary {
    border-color: #3fad72 !important;
}
.active-status {
    color: #3fad72;
}