.allusers-section {
    width: 85%;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    margin-left: 28px;
    border-radius: 10px;
}
.user-text-full {
    margin-left: 28px;
    float: left;
    margin-bottom: 0;
    margin-top: 50px;
}
.user-button-del-full {
    font-size: 10px;
    width: 6%;
    border: none;
    background-color: #e254a6;
    margin-top: 27px;
    padding: 3px;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    height: 58px;
    margin-left: 105px;
}
.user-button-history-full {
    font-size: 10px;
    width: 6%;
    border: none;
    background-color: #47c7ad;
    margin-top: 27px;
    padding: 3px;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    height: 58px;
    margin-left: 205px;
}
.alluser-header {
    float: left;
    width: 86%;
}