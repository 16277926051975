
/* .MuiButtonBase-root {
    display: inherit !important;
}
.MuiListItem-root {
    display: inherit !important;
} */
.MuiMenu-paper {
    left: 1040px !important;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiList-root > .refund-menu {
    background-color: red !important;
    height: 20px!important;
}
.MuiList-root > .reorder-menu {
    background-color: gold !important;
    height: 20px!important;
}
.MuiList-root > .track-menu {
    background-color: blue !important;
    height: 20px!important;
}   
.MuiList-root > .inv-menu {
    background-color: orange !important;
    height: 20px!important;
}
.MuiList-root > .case-menu {
    background-color: blueviolet !important;
    height: 20px!important;
}
.refund-menu {
    margin-top: 35px;
    margin-left: 65px;
    cursor: pointer;
}
#refund-menu > .MuiMenu-paper {
    z-index: 9999;
    left: 785px !important;
}
#refund-menu > .MuiList-root > li {
    height: 22px;
}