#del-pop > .MuiPopover-paper {
  width: 84%;
  margin-left: 0;
  position: absolute;
  left: 194px !important;
  top: -19px !important;
  margin-bottom: 500px;
}
#del-pop {
  /* height: 2000px; */
}
