.data-table {
    width: 82% !important;
    float: left;
    margin-left: 20px;
    overflow-x: auto;
    margin-top: 60px;
    height: 500px;
}
.MuiTableCell-alignRight {
    text-align: center !important;
}
.MuiTableCell-head {
    background-color: #3fad72 !important;
}
.WithStyles\(ForwardRef\(TableRow\)\)-root-4:nth-of-type(odd) {
    background-color: rgb(63 173 114 / 25%) !important;
}