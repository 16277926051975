.product-reviews {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
    margin-top: 60px;
}
.product-reviews .search-user{
    margin-top: 10px;
}
.review-header {
    float: left;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    /* width: 81%; */
    /* margin-left: 20px; */
    margin-top: 50px;
}
.review-all {
    float: left;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 20px;
}
.review-approved {
    float: left;
    margin-bottom: 0;
    margin-left: 5px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 20px;
    color: cornflowerblue;
}
.review-pending {
    float: left;
    margin-bottom: 0;
    margin-left: 5px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 20px;
    color: cornflowerblue;
}
.review-products {
    float: right;
    border: 1px solid #3fad72;
    width: 18%;
    background-color: #3fad72;
    border-radius: 5px;
    margin-right: 20px;
    margin-top: 11px;
}
.review-icon {
    float: left;
    margin-top: 10px;
    margin-left: 15px;
    color: #fff;
}
.review-text {
    float: left;
    margin-left: 10px;
    margin-top: 4px;
    margin-bottom: 9px;
    color: #fff;
}
.review-section {
    float: left;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    /* width: 81%; */
    /* margin-left: 20px; */
    margin-top: 20px;
}
.review-show {
    float: left;
    margin-left: 15px;
    margin-top: 20px;
}
.review-show > p {
    float: left;
}
.review-show > select {
    margin-left: 10px;
    padding: 5px;
    margin-top: -10px;
    border: 1px solid #ccc;
    width: 51%;
}
.review-entries {
    float: left;
    margin-left: 15px;
    margin-top: 20px;
}
.review-entries > p {
    float: left;
}
.review-entries > select {
    margin-left: 10px;
    padding: 5px;
    margin-top: -10px;
    border: 1px solid #ccc;
    width: 71%;
}
.review-list {
    float: left;
    width: 98%;
    margin-left: 20px;
    margin-top: 30px;
}
.review-minus {
    color: #3fad72;
    float: left;
    margin-top: 6px;
}
.review-userimg {
    font-size: 20px;
    margin-left: 30px;
    color: #3fad72;
    float: left;
    margin-top: 4px;
}
.review-author {
    float: left;
    margin-left: 70px;
    color: #3fad72;
    font-weight: 500;
}
.review-dated {
    float: left;
    margin-left: 95px;
    color: #3fad72;
    font-weight: 500;
}
.review-comment {
    float: left;
    margin-left: 95px;
    color: #3fad72;
    font-weight: 500;
}
.review-tick {
    color: #3fad72;
    float: left;
    font-size: 19px;
    margin-top: 35px;
}
.review-list1 {
    float: left;
    width: 97%;
    margin-left: 16px;
    padding-top: 10px;
    background-color: #eee;
    padding-left: 5px;
}
.review-border {
    border-bottom: 2px solid #ccc;
    float: left;
    width: 97%;
    margin-left: 16px;
}
.review-user-image {
    font-size: 38px;
    margin-left: 18px;
    color: #ccc;
    float: left;
    margin-top: 25px;
}
.review-author2 {
    font-size: 15px;
    float: left;
    text-align: center;
    margin-left: 32px;
    padding-bottom: 10px;
    margin-top: 24px;
}
.review-author2 > p {
    margin-bottom: 0;
}
.review-comment2 {
    float: left;
    font-size: 15px;
    margin-top: 30px;
    margin-left: 45px;
}
.review-rating {
    float: left;
    margin-top: 25px;
    margin-left: 20px;
}
.review-rating > svg {
    color: gold;
    font-size: 18px;
}
.review-store {
    float: left;
    font-size: 15px;
    margin-top: 25px;
    margin-left: 35px;
}
.review-product {
    float: left;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 58px;
}
.review-date {
    float: left;
    text-align: center;
    font-size: 14px;
    margin-left: 45px;
    margin-top: 24px;
}
.review-cross {
    float: left;
    border-bottom: 3px solid #ccc;
    padding: 7px;
    padding-top: 2px;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 25px;
    margin-left: 30px;
}
.review-del {
    float: left;
    border-bottom: 3px solid #ccc;
    padding: 7px;
    padding-top: 2px;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 25px;
    margin-left: 10px;
}
.review-list2 {
    float: left;
    width: 97%;
    margin-left: 16px;
    padding-top: 10px;
    background-color: #fff;
    padding-left: 5px;
}
.review-footer {
    float: left;
    margin-left: 20px;
    margin-top: 30px;
    font-size: 12px;
}
.review-pages {
    float: right;
    width: 13%;
    margin-right: 20px;
    font-size: 13px;
    margin-top: 30px;
}
.review-prev {
    float: left;
    margin-right: 5px;
}
.review-numbers {
    float: left;
    border: 1px solid #eee;
    width: 19%;
    padding-left: 7px;
    background-color: #eee;
    margin-right: 5px;
}
.review-next {
    float: left;
}
.product-pic-review {
    float: left;
    width: 13%;
}
.review-product-dash {
    float: left;
    width: 29%;
    margin-left: 68px;
}