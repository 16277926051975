.edit-name-inventory {
    float: left;
    margin-top: 50px;
    margin-left: 143px;
}
.inventory-check {
    float: left;
    width: 49%;
    margin-left: 50px;
    margin-top: 56px;
    font-size: 12px;
    margin-bottom: 0 !important;
}
.edit-stock {
    float: left;
    margin-top: 50px;
    margin-left: 34px;
    margin-left: 70px;
}
.edit-sold {
    float: left;
    margin-top: 50px;
    margin-left: 25px;
}
.edit-name-stock {
    float: left;
    margin-top: 50px;
    margin-left: 48px;
}
.edit-stock-back {
    float: left;
    margin-top: 50px;
    margin-left: 8px;
}