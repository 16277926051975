.monthly-sales {
    margin-left: 25px;
    float: left;
    margin-top: 40px;
    font-weight: 700;
    width: 83%;
}
.sales-period-box {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    width: 18%;
    margin-left: 25px;
    margin-top: 30px;
    height: 267px;
}
.date-period {
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 57px;
    font-weight: 600;
}
.date-from {
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 10px;
}
.sales-border-bottom {
    border-bottom: 1px solid #ccc;
    float: left;
    width: 93%;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ad-spend-box {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    width: 41%;
    margin-left: 10px;
    margin-top: 30px;
}
.ad-spend-box2 {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    width: 41%;
    margin-left: 10px;
    margin-top: 10px;
}
.coins-ad {
    font-size: 68px;
    margin: 30px;
    color: #3fad72;
    float: left;
}
.amount-ads {
    float: left;
    margin-top: 20px;
}
.total-spending {
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
}
.total-spending-amount {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
}
.conversion-rate {
    margin-bottom: 5px;
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    color: #3fad72;
}
.total-view-amount {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 10px;
}
.conversion-rate-views {
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: 700;
    color: #3fad72;
    margin-left: 10px;
    margin-bottom: 16px;
}
.page-views {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: 600;
}
.sales-period-box2 {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    width: 18%;
    margin-left: 25px;
    margin-top: 30px;
}
.sales-period-box3 {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: left;
    width: 18%;
    margin-left: 25px;
    margin-top: 10px;
}