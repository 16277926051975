.makeStyles-root-1 {
    float: left;
    margin-top: 30px;
    margin-left: 35px;
    width: 80%;
}
.MuiAppBar-colorPrimary {
    background-color: #3FAD72 !important;
}
.id-card {
    border: 1px solid #eee;
    float: left;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #eee;
}
.MuiBox-root-7 {
    padding:0;
}
.history-content > li {
    list-style: none;
    float: left;
    margin-left: 38px;
    margin-right: 38px;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.history-content {
    margin: 0;
    float: left;
    padding: 0;
}
.history-content > li > select {
    border: none;
    background-color: #eee;
}
.history-content >li >p {
    margin-bottom: 0;
}
.history-image {
    width: 4%;
    float: left;
    margin-right: 0px;
    margin-left: 35px;
    margin-top: 5px;
 }
 .id-card > p {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 31px;
    float: left;
    margin-top: 15px;
 }
 .user-name {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 10px;
 }
 .user-cash {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 62px;
 }
 .product-eta {
    margin-left: 104px;
    float: left;
    margin-top: 15px;
 }
 .clock {
    float: left;
 }
 .eta {
    float: left;
    font-size: 12px;
    margin-left: 8px;
 }
 .delivery {
    font-size: 12px;
    margin-left: 100px;
    margin-top: 15px;
    color: red;
    float: left;
 }
 .delivery2 {
    font-size: 12px;
    margin-left: 60px;
    margin-top: 15px;
    color: goldenrod;
    float: left;
 }
 .delivery3 {
    font-size: 12px;
    margin-left: 60px;
    margin-top: 15px;
    float: left;
 }
 .more-options {
    float: left;
    margin-top: 15px;
    margin-left: 75px;
    font-size: 20px;
 }
 .id-card:hover {
     background-color: #fff;
 }
 .makeStyles-root-1 {
     margin-top: 0;
     margin-left: 0;
     width: 100%;
 }
 .profile-name {
    border: 1px solid #ccc;
    padding: 5px;
    width: 48%;
 }
 .profile-email {
    border: 1px solid #ccc;
    padding: 5px;
    width: 48%;
    margin-left: 20px;
 }
 .profile-birth {
    border: 1px solid #ccc;
    padding: 5px;
    width: 31%;
    margin-top: 20px;
 }
 .gender-select {
    padding: 7px;
    width: 30.5%;
    margin-left: 20px;
    border: 1px solid #ccc;
 }
 .profile-phone {
    border: 1px solid #ccc;
    padding: 5px;
    width: 31%;
    margin-top: 20px;
    margin-left: 20px;
 }
 .profile-address {
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    margin-top: 20px;
 }
 .profile-about {
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    margin-top: 20px;
    height: 80px;
 }
 .profile-save {
    color: #fff;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    padding: 10px;
    width: 14%;
    border-radius: 4px;
    margin-right: 10px;
    float: right;
 }
 .profile-reset {
    color: #fff;
    border: 1px solid palevioletred;
    background-color: palevioletred;
    padding: 10px;
    width: 14%;
    border-radius: 4px;
    float: right;
 }