.pic-section-pass {
    float: left;
    width: 60%;
    border: 1px solid red;
}
.pic-section-pass > img {
    width: 100%;
}
.password-section {
    float: left;
    width: 40%;
    border: 1px solid green;
}
.change-pass-btn {
    border: 1px solid green;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    width: 50%;
}
.pass-field {
    border: 1px solid #ccc;
    width: 92%;
    padding-bottom: 5px;
}
.pass-change-button {
    border: 1px solid #3fad72;
    width: 80%;
    max-width: 100%;
    border-radius: 40px;
    background-color: #3fad72;
    font-weight: 600;
    font-size: 16px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}