.alice-carousel__wrapper {
    width: 100%;
    margin-left: 0px;
    float: left;
    margin-top: 50px;
}
.image-container-redeem {
    float: left;
    width: 65%;
    margin-left: 180px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-bottom: 30px;

}
.image-container-redeem > img {
    float: left;
    width: 100%;
    height: 390px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.redeem-name {
    text-align: center;
    margin-top: 10px;
    color: green;
}
.image-container-space {
    float: left;
    margin-top: 20px;
    width: 100%;
}
.points-icon {
    margin-top: 3px;
    font-size: 19px;
    float: left;
    margin-right: 5px;
    color: #3fad72;
}
.points-text {
    float: left;
}
.points-block {
    margin-left: 291px;
    margin-top: 20px;
}
.redeem-btn {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
}
.redeem-btn > button {
    border: 1px solid #3fad72;
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    background-color: #3fad72;
    color: #fff;
}
.redeem-product1 {
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 60%;
    margin-left: 163px;
    margin-top: 85px;
    float: left;
}
.redeem-card {
    float: left;
    width: 30%;
}
.redeem-card > img {
    float: left;
    width: 100%;
    margin: 5px;
    border-radius: 5px;
}
.redeem-name2 {
    float: left;
    margin-left: 25px;
    margin-top: 8px;
}
.points-block2 {
    margin-left: 25px;
    margin-top: 14px;
    width: 50%;
    float: left;
}
.redeem-congrats {
    width: 100%;
    float: left;
    font-weight: 600;
    color: green;
}
.redeem-congrats2 {
    width: 100%;
    float: left;
    font-weight: 600;
    color: #888;
}
.redeem-btn2 {
    float: left;
    width: 35%;
    text-align: right;
    margin-top: 6px;
    margin-bottom: 0;
    margin-left: 80px;
}
.redeem-btn2 > button {
    border: 1px solid #3fad72;
    padding: 4px;
    width: 28%;
    border-radius: 5px;
    background-color: #3fad72;
    color: #fff;
}