.App {
  text-align: center;
}
body{
  font-family: 'Roboto', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.home-new {
  /* height: 745px; */
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}
.home-new > div {
  /* width: 100vw; */
}

.app-width{
  width: 85%;
  margin-left: 15%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* MUI- Override */
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {

}