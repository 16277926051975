.search-icon {
    font-size: 24px;
    color: #DEE2E6;
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
}


.search-area {
    position: relative;
}

.searchBar {
    height: 40px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid #DEE2E6;
    border-radius: 3px;
    width: 100%;
    padding: 0px 44px;
}
input[type=text]:focus-visible {
    border: 1px solid #DEE2E6;
    outline: none;
    box-shadow: none;
}
.searchButton {
    height: 40px;
    padding: 0 10px;
    background: #04984A !important;
    border-radius: 3px !important;
    color: #fff !important;
    position: absolute;
    right: 0;
    border: none;
}

.searchButton:hover {
    color: #fff;
}

.searchSection {
    position: relative;
}