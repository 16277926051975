.makeStyles-root-1 {
  float: left;
  margin-top: 30px;
  margin-left: 35px;
  width: 80%;
}
.MuiTab-textColorInherit {
  opacity: 1 !important;
}
.PrivateTabIndicator-colorSecondary-4 {
  background-color: #fff !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.id-card {
  border: 1px solid #eee;
  float: left;
  width: 90%;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #eee;
}
.MuiBox-root-7 {
  padding: 0;
}
.history-content > li {
  list-style: none;
  float: left;
  margin-left: 34px;
  margin-right: 34px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.history-content {
  margin: 0;
  float: left;
  padding: 0;
}
.history-content > li > select {
  border: none;
  background-color: #eee;
}
.history-content > li > p {
  margin-bottom: 0;
}
.history-image {
  width: 4%;
  float: left;
  margin-right: 0px;
  margin-left: 35px;
  margin-top: 5px;
}
.id-card > p {
  font-size: 12px;
  margin-bottom: 0;
  margin-left: 20px;
  float: left;
  margin-top: 15px;
}
.user-name {
  float: left;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 10px;
}
.user-cash {
  float: left;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 44px;
}
.product-eta {
  margin-left: 80px;
  float: left;
  margin-top: 15px;
}
.clock {
  float: left;
}
.eta {
  float: left;
  font-size: 12px;
  margin-left: 8px;
}
.delivery {
  font-size: 12px;
  margin-left: 100px;
  margin-top: 15px;
  color: red;
  float: left;
}
.delivery2 {
  font-size: 12px;
  margin-left: 60px;
  margin-top: 15px;
  color: goldenrod;
  float: left;
}
.delivery3 {
  font-size: 12px;
  margin-left: 60px;
  margin-top: 15px;
  float: left;
}
.more-options {
  float: left;
  margin-top: 15px;
  margin-left: 75px;
  font-size: 20px;
}
.id-card:hover {
  background-color: #fff;
}
.makeStyles-root-1 {
  margin-top: 0;
  margin-left: 0;
  width: 85%;
}
.profile-name {
  border: 1px solid #ccc;
  padding: 5px;
  width: 48%;
}
.profile-email {
  border: 1px solid #ccc;
  padding: 5px;
  width: 48%;
  margin-left: 20px;
}
.profile-birth {
  border: 1px solid #ccc;
  padding: 5px;
  width: 31%;
  margin-top: 20px;
}
.gender-select {
  padding: 7px;
  width: 30.5%;
  margin-left: 20px;
  border: 1px solid #ccc;
}
.profile-phone {
  border: 1px solid #ccc;
  padding: 5px;
  width: 31%;
  margin-top: 20px;
  margin-left: 20px;
}
.profile-address {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
}
.profile-about {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
  height: 80px;
}
.profile-save {
  color: #fff;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  padding: 10px;
  width: 14%;
  border-radius: 4px;
  margin-right: 10px;
  float: right;
}
.profile-reset {
  color: #fff;
  border: 1px solid palevioletred;
  background-color: palevioletred;
  padding: 10px;
  width: 14%;
  border-radius: 4px;
  float: right;
}
.makeStyles-root-2 {
  float: left;
  width: 85%;
  margin-top: 20px;
}
.user-videos-tab {
  float: left;
  width: 20%;
  max-width: 100%;
  margin-top: 30px;
  margin-left: 25px;
}
/* .MuiButtonBase-root {
  padding: 20px !important;
  background-color: rgba(42, 143, 86, 1) !important;
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
} */
/* .MuiPaper-root {
    border-bottom: 8px solid #2A8F56;
 } */
.video-card {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  float: left;
  width: 25%;
  padding-bottom: 60px;
  margin-left: 20px;
  margin-top: 20px;
}
.video-views {
  float: left;
  font-size: 11px;
  color: #2a8f56;
  margin-top: 15px;
  margin-left: 10px;
}
.video-points {
  float: right;
  font-size: 11px;
  color: #2a8f56;
  margin-top: 15px;
  margin-right: 10px;
}
.star-points {
  font-size: 15px;
}
.video-user {
  float: left;
  width: 45%;
  margin-left: 85px;
  border-radius: 70px;
}
.videouser-info {
  float: left;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.videouser-info > h3 {
  font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    width: 61%;
    margin-left: 58px;
}
.videouser-info > p {
  font-size: 9px;
  color: #3dc162;
  margin-top: 3px;
}
.name-details {
  float: left;
  margin-left: 45px;
  width: 83%;
  margin-top: 5px;
}
.first-name {
  float: left;
  font-size: 11px;
  font-weight: 600;
  color: #838181;
  margin-bottom: 2px;
  width: 24%;
}
.user-name-video {
  float: left;
  font-size: 11px;
  font-weight: 500;
  color: #0f0f0f;
  margin-left: 78px;
  margin-bottom: 2px;
}
.user-name-video2 {
  float: left;
  font-size: 11px;
  font-weight: 500;
  color: #0f0f0f;
  margin-left: 48px;
  margin-bottom: 2px;
}
.user-name-video3 {
  float: left;
  font-size: 11px;
  font-weight: 500;
  color: #0f0f0f;
  margin-left: 92px;
  margin-bottom: 2px;
}
.user-name-video4 {
  float: left;
  font-size: 11px;
  font-weight: 500;
  color: #0f0f0f;
  margin-left: 105px;
  margin-bottom: 2px;
}
.user-name-video5 {
  float: left;
  font-size: 11px;
  font-weight: 500;
  color: #0f0f0f;
  margin-left: 37px;
  margin-bottom: 2px;
}
.user-activities {
  float: left;
  width: 40%;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #48c9ae;
  background-color: #48c9ae;
  color: #fff;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: 700;
}
.user-contact {
  float: left;
  width: 40%;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #4f85fd;
  background-color: #4f85fd;
  color: #fff;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: 700;
}
.user-suspend {
  float: left;
  width: 40%;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #f7bf69;
  background-color: #f7bf69;
  color: #fff;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: 700;
}
.user-suspend:focus {
  background-color: red !important;
}
.user-date {
  float: left;
  width: 40%;
  margin-left: 20px;
  border: 1px solid #f7bf69 !important;
  background-color: #f7bf69 !important;
  color: #fff;
  border-radius: 3px !important;
  margin-top: 20px;
  font-weight: 700;
  padding-bottom: 2px !important;
  font-size: 13px;
  padding-top: 3px !important;
  margin-bottom: 30px;
}
.user-date :focus {
  box-shadow: transparent !important;
}

.date-border {
  border-bottom: 1px solid #fff;
  width: 80%;
  display: block;
  margin: 0 auto;
}
.user-delete {
  width: 87%;
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #e056a4;
  background-color: #e056a4;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
}
.video-border {
  border-bottom: 1px solid #eee;
  float: left;
  width: 100%;
  margin-top: 40px;
}
.user-followers {
  float: left;
}
.followers {
  float: left;
  font-size: 11px;
  font-weight: 600;
  color: #838181;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 0;
}
.followers-num {
  float: left;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 105px;
  color: #0f0f0f;
  margin-bottom: 0;
}
.user-verification {
  width: 87%;
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #4f85fd;
  background-color: #4f85fd;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
}
.user-followers-store {
  float: left;
  margin-bottom: 80px;
}
.video-detail-carder {
  background-color: #fff;
  border-radius: 8px;
  margin-left: 35px;
  float: left;
  width: 17% !important;
  padding-bottom: 20px;
}
.video1-border {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 40%;
  padding-bottom: 5px;
}
.video1-border-mac-new {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 100%;
  padding-bottom: 5px;
}
.video1-border-mac {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 30%;
  padding-bottom: 5px;
}
.video1-image {
  float: left;
  width: 100%;
}
.video-length-mac2 {
  float: left;
  border: 1px solid #888;
  background-color: #000000bf;
  width: 19%;
  border-radius: 4px;
  margin: 4px;
}
.video-length-mac2 > span {
  float: left;
  font-size: 12px;
  font-weight: 400;
  padding-top: 2px;
  padding-left: 7px;
  color: #fff;
}
.video-setting-mac {
  float: left;
  border: 1px solid green;
  border-radius: 50px;
  background-color: green;
  color: #fff;
  font-size: 28px;
  padding: 2px;
  position: relative;
  left: 145px;
  bottom: 36px;
}
.video-setting-mac2 {
  float: left;
  border: 1px solid green;
  border-radius: 50px;
  background-color: green;
  color: #fff;
  font-size: 28px;
  padding: 2px;
}
.setting-gear-mac {
  float: right;
  margin-top: 120px;
  margin-right: 5px;
}
.video-title-mac {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
.video-date-mac {
  font-size: 12px;
  font-weight: 300;
  color: #1616165e;
}
.video-desc-mac {
  float: left;
  width: 60%;
  margin-left: 5px;
}
.video-hearts {
  float: left;
  margin-left: 5px;
  margin-top: -5px;
}
.hearts {
  float: left;
  font-size: 13px;
  color: #f85050;
  font-size: 22px;
}
.num-hearts {
  font-size: 12px;
  font-weight: 600;
  color: #9b9595;
  margin-top: 15px;
}
.dislike {
  float: left;
  font-size: 22px;
  color: #438afe;
}
.video-dislike {
  float: left;
  margin-left: 25px;
  margin-top: -5px;
  cursor: pointer;
}
.chat {
  float: left;
  font-size: 19px;
  color: #2a8f56;
}
.view-btn {
  font-size: 16px;
  font-weight: 400;
  width: 91px;
  height: 33px;
  border: 1px solid #438afe;
  background-color: #438afe;
  color: #fff;
  border-radius: 4px;
}
.view-details-mac {
  margin-right: 10px;
  margin-top: 20px;
}
.view-delete1 {
  float: left;
  position: absolute;
  top: 645px;
  right: 787px;
}
.view-delete4 {
  float: left;
  position: absolute;
  top: 660px;
  right: 436px;
}
.view-delete3 {
  float: left;
  position: absolute;
  top: 660px;
  right: 118px;
}
.delete-btn-mac {
  font-size: 12px;
  font-weight: 400;
  width: 91px;
  max-width: 100%;
  height: 30px;
  border: 1px solid #f85050;
  background-color: #f85050;
  color: #fff;
  border-radius: 4px;
  margin-top: 2px;
}
.view-delete2 {
  float: left;
  position: absolute;
  top: 485px;
  right: 289px;
}
.view-delete-mac {
  margin-right: 10px;
}
.video2-border {
  float: left;
  border: 1px solid #eee;
  margin-left: 10px;
  margin-top: 20px;
  width: 40%;
  padding-bottom: 42px;
}
.video3-border {
  float: left;
  margin-left: 10px;
  margin-top: 20px;
  width: 30%;
  padding-bottom: 42px;
}
.video4-border {
  float: left;
  margin-left: 10px;
  margin-top: 20px;
  width: 30%;
  padding-bottom: 42px;
  margin-top: 30px;
}
.video-setting2 {
  float: left;
  border: 1px solid green;
  border-radius: 50px;
  background-color: green;
  color: #fff;
  font-size: 28px;
  padding: 2px;
  position: relative;
  left: 1px;
  bottom: 34px;
}
.id-verification > h5 {
  font-weight: 700;
  margin-top: 20px;
  margin-left: 15px;
}
.id-verification {
  float: left;
  width: 50%;
  padding-bottom: 200px;
}
.fileContainer {
  width: 70%;
  height: 130px;
  margin-left: 29px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.image-upload {
  float: left;
  width: 100%;
}
.border-right {
  border-right: 1px solid #ccc;
  float: left;
  width: 34%;
  height: 425px;
  position: absolute;
  left: 425px;
  margin-top: 48px;
}
.video-user2 {
  float: left;
  width: 25%;
  margin-left: 85px;
  margin-top: 30px;
}
.selfie {
  width: 4%;
  position: relative;
  top: 113px;
  right: 50px;
}
.camera {
  float: left;
  margin-top: 30px;
  margin-left: 30px;
}
/* .verify-btns {
  float: left;
  padding-left: 0;
  position: relative;
  left: 140px;
  top: 0px;
} */
.verify-btns > li {
  list-style: none;
  float: left;
  margin-right: 30px;
}
.verify-btns > li > button {
  float: left;
  width: 122%;
}
.approve {
  border: 1px solid rgba(42, 143, 86, 1);
  background-color: rgba(42, 143, 86, 1);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.deny {
  border: 1px solid rgba(248, 80, 80, 1);
  background-color: rgba(248, 80, 80, 1);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.need {
  border: 1px solid rgba(67, 138, 254, 1);
  background-color: rgba(67, 138, 254, 1);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.product-listing {
  float: left;
  border: 1px solid red;
  width: 28%;
  margin-left: 25px;
  margin-top: 25px;
  border-radius: 10px;
}
.video-search {
  padding-left: 30px;
  padding-bottom: 4px;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 1);
  padding-top: 5px;
  color: #000;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.vid-duration {
  float: left;
  margin-top: 20px;
  margin-left: 40px;
  width: 20%;
}
.vid-duration > select {
  padding: 6px;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 100%;
}
.page-count {
  float: left;
  width: 80%;
  margin-top: 40px;
  padding-left: 10px;
}
.page-1 {
  font-weight: 700;
}
.page-border {
  float: left;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.videocard-border {
  float: left;
  border: 1px solid #eee;
  width: 48%;
  margin-top: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 3px;
}
.videocard-border2 {
  float: left;
  border: 1px solid #eee;
  width: 48%;
  margin-top: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 3px;
  margin-left: 30px;
}
.auto-coupon2 {
  float: left;
  width: 85%;
  margin-left: 10px;
  margin-top: 15px;
  font-size: 12px;
  margin-bottom: 0 !important;
}
.video-check {
  float: left;
}
.video-card-image {
  width: 230px;
  margin-top: 12px;
  margin-left: 10px;
  float: left;
}
.vid-title {
  float: left;
  font-size: 12px;
  margin-top: 18px;
  margin-left: 10px;
  font-weight: 500;
  color: rgba(42, 143, 86, 1);
}
.vid-views {
  margin-left: 37px;
  padding: 9px;
  width: 45%;
  border: 1px solid rgba(67, 138, 254, 1);
  background-color: rgba(67, 138, 254, 1);
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
}
.view-info {
  float: left;
  width: 55%;
  margin-top: 20px;
}
.category-select {
  margin-left: 17px;
  padding: 9px;
  width: 80%;
  border: 1px solid rgba(42, 143, 86, 1);
  background-color: rgba(42, 143, 86, 1);
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 10px;
}
.video-uploader {
  float: left;
  width: 20%;
  position: relative;
  bottom: 15px;
}
.video-uploader-image {
  float: left;
  width: 66%;
  margin-left: 24px;
}
.uploader-name {
  float: left;
  font-size: 12px;
  font-weight: 500;
  margin-left: 35px;
  margin-top: 2px;
  margin-bottom: 0;
}
.view-btn-mac {
  font-size: 12px;
  font-weight: 400;
  width: 91px;
  max-width: 100%;
  height: 30px;
  border: 1px solid #438afe;
  background-color: #438afe;
  color: #fff;
  border-radius: 4px;
}
.modal-dialog {
  max-width: 720px;
  margin-top: 50px;
}
/* .modal-backdrop.fade {
  opacity: 0.1;
} */
.modal-backdrop{
  background: unset !important;
}
.modal-content > .modal-content {
  width: 935px !important;
  height: 540px;
}
iframe {
  width: 900px;
  height: 465px;
}
.video-uploader-user {
  float: right;
  width: 10%;
  margin: 5px;
  border-radius: 50px;
}
.user-name-input {
  float: left;
    width: 55%;
    margin-left: 46px;
    text-align: center;
    font-size: 11px;
    color: #0f0f0f;
}
.video-comments {
  float: left;
    margin-left: 25px;
    margin-top: -33px;
}