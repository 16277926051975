.App {
  font-family: 'Roboto', sans-serif;
    text-align: center;
  }
  
  header {
    margin-bottom: 30px;
  }
  
  .col-md-4 {
    margin-bottom: 30px;
  }
  
  .card {
    float: left;
    margin-top: 30px;
    padding: 25px;
    border: solid 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    margin-left: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);

  }
  .card:hover {
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.08);
  }
  .card-title {
    margin-top: 20px;
    text-align: center;
    font-size: 21px;
    width: 100%;
  }
  .card-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-bottom: 0;
  }
  .card-text .fa {
    font-size: 26px;
  }
  .avatar {
    width: 140px;
    margin: auto;
  }
  .avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
  }
  .card:hover .avatar img {
    border-color: rgb(99, 204, 131);
  }
  
  .btn {
    border-radius: 25px;
  }
  
  footer p {
    color: rgba(0, 0, 0, 0.6);
  }
  
  footer p a,
  footer p a:hover {
    color: rgb(99, 204, 131);
  }
  .product-setting {
    font-size: 26px;
    float: right;
    margin-top: 20px;
    color: #3FAD72;
    position: relative;
    left: 19px;
  }
  .search-area-product {
    float: left;
    position: relative;
    top: -41px;
    left: 68px;
    width: 55%;
  }
  .search-icon-product {
    font-size: 20px;
    color: green;
    position: relative;
    top: 48px;
    right: 58px;
  }
  .search-bar-product {
    width: 194%;
    padding: 5px;
    padding-left: 55px;
    border-radius: 6px;
    margin-top: 20px;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    float: right;
  }
  .listing-btn {
    border: 1px solid rgba(79, 133, 253, 1);
    background-color: rgba(79, 133, 253, 1);
    color: #fff;
    padding: 7px;
    border-radius: 3px;
    margin-top: 25px;
  }
  
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1040px;
    }
  }
  
  