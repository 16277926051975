.count-btns {
    float: left;
    width: 110%;
    position: relative;
    bottom: 10px;
}
.increment-btn {
    margin-right: 27px;
    border: 1px solid #eee;
    width: 22%;
    margin-left: 10px;
}
.decrement-btn {
    border: 1px solid #eee;
    width: 22%;
    margin-left: 27px;
}