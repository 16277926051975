.refund-heading {
  float: left;
  margin-left: 25px;
  margin-top: 40px;
  width: 83%;
}
.refund-heading > h5 {
  font-weight: 700;
}
.refund-border {
  float: left;
  border-bottom: 1px solid #000;
  width: 82%;
}
.product-refund-section {
  float: left;
  width: 61%;
}
.refund-section {
  float: left;
  border: 1px solid #eee;
  width: 94%;
  margin-left: 25px;
  margin-top: 91px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.refund-options {
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
}
.refund-options > li {
  float: left;
  list-style: none;
  font-weight: 700;
  margin-right: 105px;
}
.refund-details {
  float: left;
  border: 1px solid #eee;
  width: 96%;
  margin-left: 25px;
  margin-top: 60px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.refund-details2 {
  float: left;
  border: 1px solid #eee;
  width: 96%;
  margin-left: 25px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.refund-image {
  width: 7%;
  margin: 20px;
  margin-left: 25px;
  float: left;
}
.refund-product-details2 {
  float: left;
  width: 97%;
}
.refund-denim {
  float: left;
  margin-top: 20px;
}
.refund-denim > p {
  margin-bottom: 0;
  font-size: 11px;
}
.refund-amount {
  float: left;
  font-size: 14px !important;
  font-weight: 700;
  margin-left: 55px;
  margin-top: 35px;
}
.refund-status {
  float: left;
  font-size: 14px;
  color: red;
  margin-left: 135px;
  margin-top: 35px;
}
.refund-status2 {
  float: left;
  font-size: 14px;
  color: green;
  margin-left: 135px;
  margin-top: 35px;
}
.refund-action {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin-left: 95px;
  margin-top: 35px;
}
.refund-track-btn {
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  padding: 12px;
  width: 10%;
  border-radius: 5px;
  font-size: 14px;
  float: right;
  margin-right: 10px;
  margin-top: 20px;
}
.refund-order-btn {
  border: 1px solid #3fad72;
  background-color: #fff;
  color: #3fad72;
  padding: 12px;
  width: 10%;
  border-radius: 5px;
  font-size: 14px;
  float: right;
  margin-right: 10px;
  margin-top: 20px;
}
.refund-btns {
  float: right;
}
.refund-section2 {
  float: left;
  width: 23%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  margin-top: 25px;
}
.reason {
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  margin-top: 20px;
}
.refund-text {
  margin-left: 20px;
  width: 87%;
  height: 80px;
  border: 1px solid #ccc;
  margin-top: 30px;
}
.refund-reason {
  font-size: 10px;
  color: #888;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 40px;
}
.refund-subtotal {
  float: left;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
}
.refund-subtotal-amount {
  float: right;
  font-size: 12px;
  font-weight: 800;
  margin-right: 20px;
  margin-top: 10px;
}
.refund-subtotal-tax {
  float: left;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
  width: 70%;
}
.refund-total-amount {
  float: left;
  font-size: 12px;
  margin-left: 20px;
  font-weight: 800;
  margin-top: 30px;
  width: 100%;
}
.payment-method {
  float: left;
  font-size: 12px;
  margin-left: 20px;
  width: 100%;
  margin-bottom: 0;
}
.refund-available {
  float: left;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 15px;
  color: #ccc;
  width: 100%;
}
.refund-subtotal-amount2 {
  float: left;
  font-size: 12px;
  font-weight: 800;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.refund-btn2 {
  display: block;
  margin: 0 auto;
  width: 85%;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  padding: 12px;
  border-radius: 5px;
}
.refund-condition {
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
  color: #ccc;
}
.refund-btn3 {
  display: block;
  margin: 0 auto;
  width: 85%;
  border: 1px solid blue;
  background-color: blue;
  color: #fff;
  padding: 12px;
  border-radius: 5px;
}
.credit-input {
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 85%;
  margin-top: 20px;
  padding: 10px;
  border-right: none;
  border-left: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.refund-visible {
  float: left;
  width: 85%;
  margin-left: 45px;
  margin-top: 38px;
  font-size: 12px;
  margin-bottom: 30 !important;
}
.refund-address {
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 0;
}
.refund-text-address {
  margin-left: 20px;
  width: 87%;
  height: 80px;
  border: 1px solid #ccc;
  margin-top: 15px;
  margin-bottom: 30px;
}
.order-checkout-section-refund {
  float: left;
  width: 92%;
  background-color: #eee;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 11px;
}
.not-details2 {
  float: left;
  margin-top: 20px;
  margin-left: 838px;
}

/* ***************************************** new css */
.refundItems
  .row
  .common-table
  .MuiDataGrid-main
  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:has(> .menuIcon-dropdown-start) {
  overflow: unset !important;
}
.refundItems .row .reasonForRefund {
  border: 1px solid #dee2e6;
  border-radius: 4px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  padding: 8px 10px 12px 10px;
  font: normal normal normal 15px/20px Roboto !important;
  letter-spacing: 0px !important;
  color: #666666 !important;
}
.refundItems .row .reasonForRefund .refund-label {
  text-align: left !important;
  font: normal normal 18px Roboto !important;
  letter-spacing: 0px !important;
  color: #020202 !important;
}
.refundItems .row .reasonForRefund .refund-textarea {
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 3px !important;
}
.form-control::-webkit-input-placeholder {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
}

.refundItems .row .reasonFrom {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  overflow: auto;
  height: calc(100vh - 310px);
}
.refundItems .row .reasonForRefund .reasonSummary {
  text-align: left;
  font: normal normal Roboto;
  font-size: 18px;
  letter-spacing: 0px;
  color: #020202;
}
.Summary-text {
  font: normal normal normal Roboto;
  font-size: 15px;
  letter-spacing: 0px;
  color: #020202;
}
.searchSection .row .col-6 .searchButton {
  width: -webkit-fill-available;
}
.margin-bottom__reason {
  margin-bottom: 18px;
}
.margin-top__reason {
  margin-top: 18px;
}
.formControl-input {
  margin-bottom: 33px;
}
