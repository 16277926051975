.dash-chart {
    float: left;
    border: 1px solid #3fad72;
    padding: 5px;
    padding-bottom: 7px;
    width: 9%;
    margin-left: 25px;
    margin-top: 30px;
    border-radius: 5px;
    padding-left: 9px;
    background-color: #3fad72;
    color: #fff;
}
.dash-border-bottom {
    float: left;
    border-bottom: 1px solid #ccc;
    width: 85%;
    margin-top: 20px;
}
.dash-chart1 {
    float: left;
    border: 1px solid #ccc;
    width: 15%;
    margin-left: 58px;
    margin-top: 15px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 8px;
}
.dash-tasks {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
}
.dash-filter {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: 12px;
    color: blue;
}
.dash-number-tasks {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 2px;
}
.dash-count {
    font-size: 10px;
    margin-left: 10px;
    color: #888;
}
.dash-chart2 {
    float: left;
    border: 1px solid #ccc;
    width: 15%;
    margin-left: 20px;
    margin-top: 15px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 8px;
}
.dash-number-tasks2 {
    margin-left: 10px;
    margin-top: 35px;
    margin-bottom: 2px;
}
.barchart-section {
    float: left;
    border: 1px solid #ccc;
    width: 35%;
    margin-left: 25px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
}
.piechart-section {
    float: left;
    border: 1px solid #ccc;
    width: 37%;
    margin-left: 20px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
    margin-bottom: 20px;
    padding-bottom: 30px;
}
.dash-filter2 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: 12px;
    color: blue;
    margin-bottom: 30px;
}
.dashboard-att-new {
    float: left;
    width: 40% !important;
    margin-left: 23px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    height: 50px;
}
.dash-amount2 {
    float: left;
    margin-top: 4px;
    margin-left: 70px;
}
.sale-section-new {
    float: left;
    border: 1px solid #ccc;
    width: 18%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-left: 32px;
    margin-top: 20px;
}
.profit-section-new {
    float: left;
    border: 1px solid #ccc;
    width: 13%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-left: 32px;
    margin-top: 20px;
}
.piechart-result-section-new {
    float: left;
    border: 1px solid #ccc;
    width: 35%;
    margin-left: 25px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.cross-section-new {
    float: left;
    border: 1px solid #ccc;
    width: 37%;
    margin-left: 0px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-left: 20px;
}
.pie-chart-section-new {
    float: left;
    width: 60%;
    padding-bottom: 52px;
    margin-top: 5px;
}
.gross-text2-dash {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 700;
    margin-left: 20px;
    color: blue;
}
.mark-text-new {
    margin-left: 15px;
    margin-top: 50px;
    color: green;
    font-weight: 700;
    margin-bottom: 0;
}
.mark-text2-new {
    margin-left: 15px;
    margin-top: 30px;
    color: blue;
    font-weight: 700;
    margin-bottom: 0;
}
.cross-revenue-dash {
    float: left;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: blue;
    margin-top: 15px;
    width: 80%;
}
.cross-amount3-dash {
    float: right;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 700;
    color: blue;
    margin-top: 15px;
}