.activity-header {
    float: left;
    margin-top: 25px;
    margin-left: 20px;
    width: 83%;
}
.activity-show {
    font-size: 15px;
    color: #888;
    float: left;
    margin-top: 23px;
}
.activity-type {
    float: left;
    margin-left: 50px;
}
.activity-type > p {
    margin-bottom: 0;
    font-size: 15px;
    color: #888;
}
.activity-type > input {
    font-size: 15px;
    border: 1px solid #ccc;
    padding: 3px;
}
.activity-log {
    float: left;
    margin-left: 10px;
    width: 10%;
}
.activity-log > p {
    margin-bottom: 0;
    font-size: 15px;
    color: #888;
}
.activity-log > select {
    font-size: 15px;
    border: 1px solid #ccc;
    width: 100%;
    padding: 3px;
}
.activity-date {
    border: 1px solid #fff;
}
.activity-date2 {
    border: 1px solid #fff;
    margin-left: 51px;
}
.activity-log2 {
    float: left;
    margin-left: 10px;
    width: 15%;
}
.activity-log2 > p {
    margin-bottom: 0;
    font-size: 15px;
    color: #888;
}    
.activity-button {
    float: left;
    margin-left: 10px;
    margin-top: 22px;
    width: 8%;
    border: 1px solid green;
    background-color: green;
    color: #fff;
    padding: 4px;
    font-size: 13px;
    border-radius: 5px;
}
.activity-detail-section {
    float: left;
    margin-left: 20px;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    width: 80%;
    margin-top: 30px;
}
.activity-id {
    float: left;
    margin-bottom: 0;
    color: #fff;
}
.activity-headings {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.activity-user-date {
    float: left;
    color: #fff;
    margin-left: 15px;
    margin-bottom: 0;
}
.activity-user-log {
    float: left;
    color: #fff;
    margin-left: 260px;
    margin-bottom: 5px;
}
.activity-user-done {
    float: left;
    color: #fff;
    margin-left: 100px;
    margin-bottom: 0;
}
.activity-user-action {
    float: left;
    color: #fff;
    margin-left: 324px;
    margin-bottom: 0;
}
.activity-details-inner {
    float: left;
    margin-left: 20px;
    border: 1px solid #ccc;
    width: 80%;
}
.activity-inner {
    float: left;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
.activity-id2 {
    float: left;
    margin-bottom: 0;
    color: #000;
    font-size: 14px;
    margin-top: 25px;
}
.activity-user-date2 {
    float: left;
    color: #000;
    margin-left: 15px;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 25px;
}
.activity-image {
    width: 6%;
    margin-left: 82px;
    float: left;
}
.activity-done-by {
    float: left;
    margin-top: 18px;
    margin-left: 105px;
}
.activity-username {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}
.activity-email {
    margin-bottom: 0;
    font-size: 14px;
    color: #888;
}
.activity-show-button {
    float: left;
    margin-left: 285px;
    margin-top: 25px;
    width: 8%;
    border: 1px solid blue;
    background-color: blue;
    color: #fff;
    padding: 4px;
    font-size: 13px;
    border-radius: 5px;
}
.activity-del-btn {
    float: right;
    margin-right: 44px;
    margin-top: 25px;
    width: 10%;
    padding-bottom: 3px;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    border-radius: 5px;
}
.activity-del-text {
    float: right;
    margin-top: 29px;
    margin-right: 15px;
    color: #888;
    font-weight: 400;
    font-size: 15px;
}