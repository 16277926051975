.shipping-section {
    margin-left: 30px;
    margin-top: 50px;
    width: 100%;
}
.shipping-card {
    border: 1px solid #eee;
    width: 99%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-top: 40px;
}
.shipping-list > li {
    float: left;
    list-style: none;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}
.shipping-list {
    padding-left: 0;
    margin-left: 15px;
}
.pos {
    margin-left: 12px;
    width: 58px;
}
.list-name {
    width: 180px;
}
.deli-time {
    margin-left: 10px;
    width: 110px;
}
.lbs {
    margin-left: 10px;
    width: 180px;
}
.ship-status {
    margin-left: 60px;
    width: 120px;
}
.pos-field {
    width: 38px;
}
.deliver-input {
    width: 110px;
}
.weight-input {
    width: 60px;
}
.ship-status > select {
    border: none;
}
.shipping-list2 > li {
    float: left;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.shipping-list2 {
    padding-left: 15px;
    border: 1px solid #eee;
    background-color: #eee;
    float: left;
    padding-right: 16px;
    margin-bottom: 0;
    width: 100%;
}
.shipping-list3 > li {
    float: left;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.shipping-list3 {
    padding-left: 15px;
    border: 1px solid #eee;
    float: left;
    padding-right: 16px;
    margin-bottom: 0;
    width: 100%;
}
.list-name2 {
    width: 200px;
    margin-left: 25px;
}
.shipping-btn {
    float: right;
    border: 1px solid green;
    padding: 6px;
    width: 8%;
    margin-right: 100px;
    background-color: green;
    color: #fff;
    border-radius: 5px;
}
.shipping-section > h3 {
    float: left;
}