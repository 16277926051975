.userprofile-section {
    float: left;
    border: 1px solid #eee;
    width: 85%;
    margin-top: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.profile-image-user {
    float: left;
    width: 7%;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 100px;
}
.profile-user-details > li {
    list-style: none;
}
.welcome-dash {
    font-size: 14px;
    color: #3FAD72;
    font-weight: 700;
}
.user-dash {
    font-size: 12px;
    font-weight: 500;
}
.profile-user-details {
    float: left;
    padding-left: 20px;
    margin-top: 28px;
}
.dash-icon {
    float: left;
    border: 1px solid red;
    width: 30%;
    background-color: red;
    height: 50px;
}
.dash-icon2 {
    float: left;
    border: 1px solid blue;
    width: 30%;
    background-color: blue;
    height: 50px;
}
.dash-icon3 {
    float: left;
    border: 1px solid gold;
    width: 30%;
    background-color: gold;
    height: 50px;
}
.dash-icon4 {
    float: left;
    border: 1px solid green;
    width: 30%;
    background-color: green;
    height: 50px;
}
.dollar-icon {
    display: block;
    margin: 0 auto;
    padding: 5px;
    font-size: 40px;
    color: #fff;
    margin-top: 4px;
}
.dash-amount {
    text-align: center;
    /* float: left; */
    margin-top: 4px;
}
.active-product {
    margin-left: 23px;
    padding: 10px;
    width: 19%;
    margin-top: 25px;
    background-color: #3FAD72;
    border: 1px solid #3FAD72;
    color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.product-card-user {
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 80%;
    float: left;
    margin-left: 28px;
    border-radius: 10px;
    margin-top: 30px;
}
.money-bar {
    margin-bottom: 0;
    font-size: 12px;
    color: green;
    font-weight: 700;
    margin-left: 35px !important;
    margin-top: 4px;
}
.money-detail-bar {
    margin-bottom: 0;
    font-size: 9px;
    margin-left: 11px !important;
    color: #000;
}
.dashboard-att-bar {
    float: left;
    width: 13.5%;
    margin-left: 23px;
    margin-top: 50px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    height: 50px;
}