.admin-chat-header {
    float: left;
    width: 85%;
}
.search-chat {
    float: left;
    width: 48%;
    margin-left: 20px;
    margin-top: 25px;
    margin-right: 15px;
}
.chat-searchbar {
    float: left;
    width: 80%;
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 6px;
    padding-left: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.chat-searchbar::placeholder {
    color: #888;
}
.chatsearch-icon {
    position: relative;
    right: 412px;
    top: 7px;
    color: #888;
}
.add-chat {
    border: 1px solid #3fad72;
    float: left;
    padding: 7px;
    width: 9%;
    padding-left: 13px;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 8px;
    margin-left: 20px;
}
.allmsg-section {
    float: left;
    border: 1px solid #eee;
    width: 100%;
    margin-top: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.allmsg-section > h6 {
    font-weight: 700;
    margin-top: 15px;
    margin-left: 15px;
    float: left;
}
.all-msgs-icon {
    margin-top: 18px;
    margin-left: 8px;
    color: #ccc;
    margin-bottom: 15px;
}
.more-icon {
    float: right;
    margin-top: 15px;
    margin-right: 20px;
}
.allmsg-section2 {
    float: left;
    border: 1px solid #eee;
    width: 100%;
    background-color: #eee;
}
.allmsg-section2 > h6 {
    font-weight: 700;
    margin-top: 15px;
    margin-left: 15px;
    float: left;
}
.chat-msg1 {
    float: left;
    width: 10%;
    margin-left: 15px;
    margin-top: 22px;
    margin-bottom: 15px;
}
.msgs-details {
    float: left;
    margin-top: 11px;
    margin-left: 15px;
}
.sender-msg {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 700;
}
.msg-content {
    margin-bottom: 0;
    font-size: 12px;
    color: #888;
}
.msg-status {
    font-size: 11px;
    margin-top: 5px;
    color: red;
}
.msg-time {
    margin-bottom: 0;
    font-size: 12px;
    color: #888;
    float: right;
    margin-right: 30px;
    margin-top: 25px;
}
.msg-not-num {
    float: left;
    border: 1px solid #ccc;
    background-color: #ccc;
    color: #fff;
    border-radius: 50px;
    padding-left: 5px;
    width: 18px;
    height: 18px;
    position: relative;
    left: 140px;
    top: 46px;
    font-size: 10px;
}
.msg-not-dot {
    float: left;
    position: absolute;
    top: 200px;
    left: 256px;
    color: red;
}
.allmsg-section3 {
    float: left;
    border: 1px solid #eee;
    width: 100%;
}
.allmsg-section3 > h6 {
    font-weight: 700;
    margin-top: 15px;
    margin-left: 15px;
    float: left;
}
.msg-status2 {
    font-size: 11px;
    margin-top: 5px;
    color: green;
}
.msg-not-dot2 {
    float: left;
    position: absolute;
    top: 290px;
    left: 256px;
    color: green;
}
.msg-status3 {
    font-size: 11px;
    margin-top: 5px;
    color: gold;
}
.msg-not-dot3 {
    float: left;
    position: absolute;
    top: 380px;
    left: 256px;
    color: gold;
}
.admin-messenger-header {
    float: left;
    width: 48%;
}
.messenger-user {
    float: left;
    width: 11%;
    margin-top: 21px;
    margin-left: 25px;
}
.messenger-user-dot {
    float: left;
    position: relative;
    top: 56px;
    right: 14px;
    color: green;
}
.messenger-user-text {
    font-size: 15px;
    font-weight: 700;
    margin-top: 25px;
    margin-left: 9px;
    float: left;
}
.messenger-search {
    float: left;
    border: 1px solid #3fad72;
    width: 10%;
    background-color: #3fad72;
    color: #fff;
    font-size: 16px;
    padding: 9px;
    padding-left: 14px;
    padding-bottom: 10px;
    padding-top: 7px;
    border-radius: 5px;
    margin-top: 22px;
    margin-left: 155px;
}
.messenger-bell {
    float: left;
    border: 1px solid #3fad72;
    width: 10%;
    background-color: #fff;
    color: #3fad72;
    font-size: 16px;
    padding: 9px;
    padding-left: 14px;
    padding-bottom: 10px;
    padding-top: 7px;
    border-radius: 5px;
    margin-top: 22px;
    margin-left: 10px;
}
.messenger-more {
    margin-top: 35px;
    font-size: 18px;
    margin-left: 10px;
}
.messenger-product {
    float: left;
    width: 100%;
    border: 1px solid #eee;
    margin-top: 40px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    background-color: #eee;
}
.chat-product {
    float: left;
    width: 10%;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.messenger-listing {
    float: left;
    margin-top: 18px;
    margin-left: 15px;
}
.market-text {
    margin-bottom: 0;
    font-size: 10px;
    color: #888;
}
.market-price {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}
.messenger-chat1 {
    float: right;
    margin-right: 20px;
}
.messenger-chat1 > img {
    float: right;
    width: 10%;
}
.messenger1 {
    float: right;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    width: 52%;
    margin-top: 25px;
    border-radius: 5px;
}
.messenger1 > p {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
    margin-left: 10px;
    color: #fff;
}
.messenger-chat2 {
    float: left;
    margin-left: 20px;
}
.messenger-chat2 > img {
    float: left;
    width: 10%;
}
.messenger2 {
    float: left;
    border: 1px solid #fff;
    background-color: #fff;
    width: 52%;
    margin-top: 25px;
    border-radius: 5px;
    margin-bottom: 40px;
}
.messenger2 > p {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
    margin-left: 10px;
    color: #000;
}
.chat-send {
    display: block;
    margin: 0 auto;
    width: 90%;
    padding: 8px;
    border: 1px solid #eee;
    border-radius: 20px;
    padding-left: 35px;
    color: #888;
    font-size: 12px;
}
.chat-send-msg {
    float: right;
    position: relative;
    bottom: 26px;
    right: 38px;
    color: #888;
}
.attach-msg {
    float: left;
    position: relative;
    left: 40px;
    top: 10px;
    color: #888;
}