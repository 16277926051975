/* .live-schedule .nav-pills .nav-item .nav-link {
  display: unset !important;
}

.live-schedule .nav-pills .nav-item:has(> .active) {
  background: #04984a 0% 0% no-repeat padding-box !important;
  border-radius: 3px 0px 0px 3px !important;
  opacity: 1;
  padding: 10px 0;
}
.live-schedule .nav-pills .nav-item{
  transition: 0s !important;
}
.live-schedule .nav-pills .nav-item .active {
  background-color: unset !important;
}
.live-schedule .nav-pills .nav-item {

  border-radius: 0px 3px 3px 0px;
  opacity: 1;
  align-self: center;
}
.live-schedule .nav-pills .nav-item .nav-link {
  font: normal normal normal 15px/20px Roboto;
  background: #dee2e6 0% 0% no-repeat padding-box;
  padding: 13px 62px;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
}
.live-schedule .nav-pills .item-all-streams .active {
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
} */

.live-schedule .video-filter {
  width: 397px;
}

.live-schedule .searchSection {
  width: 238px;
}

.live-schedule .custom-dropdown {
  width: 149px !important;
}

.live-schedule .custom-dropdown .dropdown-btn {
  padding: 7px;
}

.live-schedule .profile-thumb .profile-photo img {
  height: 26px;
  width: 26px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}

.live-schedule .profile-thumb h6 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  align-items: center;
  margin-bottom: 0;
  align-self: center;
}

.live-schedule .profile-thumb {
  position: absolute;
  top: 10px;
  left: 10px;
}

.live-schedule .video-timer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.live-schedule .video-timer p {
  font-size: 14px;
  color: #fff;
}
