/* .logo-image {
  border-radius: 9px;
} */
.nav-area2 {
  width: 15%;
  background-color: #3fad72;
  float: left;
  height: 100vh;
  /* height: 745px; */
  /* overflow: hidden;
   */
  /* z-index: 99999; */
  /* margin-right: 10; */
  box-shadow: 0px 1px 10px #00000029;
}

.logo img {
  width: auto;
  display: block;
  margin: 0 auto;
  height: 60px;
}

.icon-1 {
  float: left;
  margin-top: -2px;
  padding-right: 15px;
  font-size: 32px;
  color: #fff;
}

.home {
  float: left;
  color: #fff;
}

.nav1 {
  float: left;
  margin-left: 50px;
  margin-top: 30px;
}

.nav2 {
  float: left;
  margin-left: 50px;
}

/* .side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: transparent !important;
  color: white;
} */
.side-navigation-panel ul:hover {
  background-color: transparent !important;
}

.side-navigation-panel-select-option-text,
.side-navigation-panel-select-inner-option-text {
  color: #FFFFFF;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  color: white;
}

.side-navigation-panel ul {
  color: #fff;
}

.side-navigation-panel>ul>li {
  width: 100%;
}

/* .side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap
  .side-navigation-panel-select-option-text {
  color: white;
}
.side-navigation-panel-select > li {
  color: #fff !important;
}
side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  color: #fff;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  svg {
  color: #fff;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option
  .side-navigation-panel-select-inner-option-wrap
  .side-navigation-panel-select-inner-option-text {
  color: white;
} */
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
  padding: 1rem 2.1em !important;
}

.side-navigation-panel-select-option-selected,
.side-navigation-panel-select-inner-option-selected {
  background-color: #FFFFFF !important;
  width: 100%;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-roboto) !important;
}

.side-navigation-panel-select-inner-option-selected:hover .side-navigation-panel-select-inner-option-wrap {
  color: red !important;
}

.side-navigation-panel-select-option-selected .side-navigation-panel-select-inner-option-selected>.side-navigation-panel-select-inner-option-text {
  color: #020202 !important;
}

/* .side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap */
.side-navigation-panel-select-option-selected:hover {
  width: 100%;
  color: #020202 !important;
}

.side-navigation-panel-select-option-selected .side-navigation-panel-select-option-text:hover {
  color: #020202 !important;
}
.navIcon{
  fill: #fff !important;
}
.side-navigation-panel-select-option:hover .navIcon {
  fill: red;
}

.side-navigation-panel-select-inner-option-selected:hover {
  background-color: #FFFFFF !important;
  width: 100%;
}

.side-navigation-panel-select-inner-option-selected span {
  color: #020202 !important;
}

/* * {
  transition: 0.5s;
} */

.side-navigation-panel-select-inner-option:hover span {
  color: #020202 !important;
}
.side-navigation-panel-select-inner-option:hover svg {
  color: #020202 !important;
}
.side-navigation-panel-select-wrap .side-navigation-panel-select-option svg {
  color: #FFFFFF;
}

.side-navigation-panel-select-option-selected span {
  color: #020202 !important;
}
.side-navigation-panel-select-option-selected svg {
  color: #020202 !important;
}

.side-navigation-panel-select-option:hover span {
  color: #020202 !important;
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #FFFFFF !important;
}

.side-navigation-panel-select-wrap {

  width: fit-content;
}

.logo {
  background-color: #fff;
}
.side-navigation-panel-select-option-selected span svg path {
  fill: #020202 !important;
  stroke: #020202 !important;
}
.side-navigation-panel-select-option:hover span svg path {
  fill: #020202 !important;
  stroke: #020202 !important;
}