.custom-dropdown {
  margin: 0 auto;
  height: 40px;
  position: relative;
}
/* .custom-dropdown,
.custom-dropdown * {
  z-index: 999;
} */
.custom-dropdown .dropdown-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  color: #020202;
}
.custom-dropdown .dropdown-content {
  position: absolute;
  /* top: 110%; */
  max-height: 500px;
  border-radius: 0px 3px 3px 0px;
  left: 0;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffffff;
  z-index: 99;
  box-shadow: 0px 5px 12px 3px rgb(0 0 0 / 7%);
}
.custom-dropdown .dropdown-content .item {
  display: flex;
  align-items: center;
  padding: 10px;
  font: normal normal normal 15px/20px Roboto;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  color: #020202;
  cursor: pointer;
}
.custom-dropdown .dropdown-content .item_active {
  display: flex;
  font: normal normal normal 15px/20px Roboto;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  color: #04984a;
}

.custom-dropdown .dropdown-content .item:hover {
  color: #04984a;
}

.custom-dropdown .dropdown-btn svg {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
}

.custom-dropdown .dropdown-btn .arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
}

.custom-dropdown .dropdown-btn .full_arrow {
  width: 16px;
  height: 16px;
  fill: #666666;
  position: absolute;
  right: 14px;
}

.custom-dropdown .dropdown-btn {
  background: #f8f8f8;
}
.custom-placeholder{
  padding: 0 !important;
  margin: 0 !important;
}
.custom-dropdown .dropdown-content #myInput {
  display: flex;
  align-items: center;
  padding: 10px;
  font: normal normal normal 15px/20px Roboto;
  background: #ffffff;
  color: #020202;
  border: 1px solid rgb(222, 226, 230);
  cursor: pointer;
  width: inherit;
}
input::placeholder, .custom-placeholder {
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  font-weight: "Roboto";
  opacity: 1;
  color: #B1B1B1;
}