#dynamic-modal {
    max-width: 30%;
}
.warning-modal {
    font-size: 100px;
    display: block;
    margin: 0 auto;
    color: red;
    margin-top: 30px;
}
#dynamic-modal > .modal-content > .modal-footer {
    justify-content: center;
}
.danger-btn {
    width: 40%;
}
#dynamic-modal > .modal-content > .modal-header > .btn-close {
    border: 1px solid red;
    border-radius: 100px;
    background-color: red;
    opacity: 1;
}
#dynamic-modal > .modal-content > .modal-header {
    border-bottom: none;
}
#dynamic-modal > .modal-content > .modal-footer {
    border-top: none;
}