.event-calendar {
  width: 75%;
  max-width: 100%;
  margin-left: 55px;
  float: left;
  margin-top: 65px;
}
.events-heading {
  float: left;
  margin-left: 50px;
  margin-top: 50px;
  width: 70%;
  max-width: 100%;
}
.events-user {
  float: left;
  margin-left: 50px;
  max-width: 100%;
}
.event-select-user {
  float: left;
  margin-top: 50px;
  width: 33%;
  max-width: 100%;
}
.event-select-user > select {
  margin-left: 20px;
  padding: 5px;
  width: 35%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.fazzy {
  float: left;
  margin-left: 60px;
  margin-top: 40px;
  width: 73%;
}
.fazzy > h5 {
  float: left;
}
.fazzy > input {
  margin-left: 20px;
  padding: 5px;
  width: 47%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
