.headingss-list {
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.headingss-list > li {
  list-style: none;
  float: left;
  color: #fff;
  /* margin-right: 210px; */
  width: 185px;
}

.users-headings {
  float: left;
  width: 95%;
  border: 1px solid #ccc;
  margin-left: 25px;
  margin-top: 40px;
  background-color: #3fad72;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.new-users-sectionUsers {
  float: left;
  width: 85%;
  /* height: 800px;
  overflow-y: scroll; */
  /* height: 1000px;
  overflow-y: scroll;
  overflow-x: clip; */
}

.user-section1 {
  float: left;
  width: 95%;
  border: 1px solid #eee;
  margin-left: 25px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  background-color: #3fad7247;
}

/* .user-section1 > img {
  float: left;
  width: 5%;
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: 15px;
} */
.eusername {
  float: left;
  margin-top: 26px;

  font-size: 14px;
  font-weight: 600;
  width: 10%;
}

.efullname {
  float: left;
  margin-top: 26px;
  margin-left: 16 !important;
  font-size: 14px;
  font-weight: 600;
  width: 15%;
}

.eemail {
  float: left;
  margin-top: 26px;

  font-size: 14px;
  font-weight: 600;
  width: 24%;
}

.eadmin {
  float: left;
  margin-top: 26px;
  width: 6%;
  font-size: 14px;
  font-weight: 600;
  margin-left: -18px;
}

.user-section2 {
  float: left;
  width: 95%;
  border: 1px solid #eee;
  margin-left: 25px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  background-color: #eee;
}

/* .user-section2 > img {
  float: left;
  width: 5%;
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: 15px;
} */
.eusernewimage {
  float: left;
  width: 5%;
  margin-right: 100px;
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 30px;
}

.edot {
  float: left;
  margin-top: 26px;
  width: 15%;
  font-size: 14px;
  font-weight: 600;
}

.action-btns {
  float: right;
  width: 33%;
  margin-top: 25px;
}

.new-menu {
  float: left;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
  font-size: 11px;
}

.new-menu-date {
  float: left;
  margin-right: 10px;
  padding: 8px !important;
  background-color: yellow;
  border-radius: 5px;
  color: #888;
  font-size: 11px;
  width: 30% !important;
}

.new-menu-hist {
  float: left;
  padding: 4px;
  background-color: red;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
}

.new-menu-pass {
  float: left;
  padding: 4px;
  background-color: blueviolet;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  margin-right: 10px;
}

.admin-access {
  float: left;
  padding: 4px;
  background-color: green;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  margin-left: 10px;
}

/* users */
.users .user,
.admin, .customers, .user-profile-pic {
  background: transparent;
  border-radius: 3px;
  opacity: 1;
  margin-right: 10px;
}
