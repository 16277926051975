.bread-crumb {
  margin-bottom: 24px;
  align-items: baseline;
}
.bread-crumb .sub-title {
  color: #b1b1b1;
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto;
  margin-top: 2px;
}
.add-category-btn {
  background-color: #04984a;
  color: #ffffff;
  border-radius: unset !important;
  border-color: #04984a;
}

.page-title {
  font: normal normal medium 20px/24px Roboto;
  letter-spacing: 0px;
  color: #020202;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  /* margin-left: 10px; */
}
.bread-crumb .breadcrumb-item {
  font-size: 14px;
  color: #666666;
  line-height: 16px;
}
.path-root .breadcrumb .active {
  border: unset;
  color: #04984a;
  font-weight: 500;
}

.path-root .breadcrumb {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}
