.edit-section {
    float: left;
    width: 116%;
    position: absolute;
    z-index: 99999;
    background-color: #fff;
    top: 10px;
}
.attention {
    float: left;
    margin-left: 22px;
}
.attn-heading {
    margin-bottom: 0;
    color: maroon;
}
.warning {
    font-size: 10px;
    margin: 0;
}
.edit-heading > h3 {
    margin-bottom: 0;
    margin-left: 20px;
}
.add-image-section {
    border: 1px solid #eee;
    float: left;
    width: 113%;
    margin-left: 20px;
    margin-top: 20px;
    background-color: #eee;
    border-radius: 3px;
    padding-bottom: 20px;
}
.add-image {
    font-size: 16px;
    color: #888;
    font-weight: 600;
    margin-left: 15px;
    margin-top: 15px;
}
.add-name {
    font-size: 16px;
    width: 80%;
    color: #888;
    font-weight: 600;
    margin-left: 16px;
    margin-top: 20px;
    float: left;
    border-radius: 3px;
}
.name-field {
    float: left;
    width: 85%;
    margin-left: 14px;
    padding: 6px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 3px;
}
.name-field2 {
    height: 100px;
    float: left;
    width: 85%;
    margin-left: 14px;
    padding: 6px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 3px;

}
.price-heading {
    margin-left: 15px;
    margin-bottom: 0;
    margin-top: 15px;
}
.selling-field {
    float: left;
    width: 72%;
    margin-left: 14px;
    padding: 6px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 3px;
}
.compare-prices {
    width: 50%;
    float: left;
}
.save-change-btn {
    float: right;
    margin-top: 20px;
    margin-right: -59px;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    border-radius: 5px;
    color: #fff;
    padding: 9px;
    width: 42%;
    margin-bottom: 50px;
}
.file-container > img {
    width: 220px !important;
    height: 160px;
}