.user-text {
  margin-left: 28px;
  float: left;
  margin-bottom: 0;
}
.users-section {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 80%;
  max-width: 100%;
  float: left;
  margin-left: 28px;
  border-radius: 10px;
}
.customized {
  border: none;
  font-size: 16px;
  padding: 3px;
  width: 25%;
  background-color: #3fad72;
  color: #fff;
  float: right;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;
}
.border-bottom {
  border-bottom: 1px solid #eee;
  margin-left: 10px;
  float: left;
  width: 97%;
  margin-top: 10px;
}
.user-list-home > li {
  list-style: none;
  float: left;
  margin-right: 140px;
  margin-left: 51px;
  margin-top: 10px;
}
.user-list-home {
  float: left;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
}
.circle-name {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: grey;
  float: left;
  margin-top: 50px;
}
.user-details {
  float: left;
  width: 100%;
  margin-left: 40px;
}
.user-image {
  width: 6%;
  margin-top: 25px;
  margin-left: 28px;
  float: left;
  border-radius: 30px;
}
.user-name-homeStyle {
  margin-top: 43px;
  float: left;
  margin-left: 18px;
  width: 15%;
}
.user-emailAddress {
  margin-top: 43px;
  float: left;
  width: 20%;
  font-size: 14px;
  /* margin-left: 58px; */
}
.user-button {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #3fad72;
  margin-top: 30px;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
  margin-left: 0px;
}
.user-button2 {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #4265af;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
  margin-left: 0px;
}
.user-suspension {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #f6c262;
  margin-top: 10px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}
.user-suspension:focus {
  background-color: red !important;
}
.user-suspension-done {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: red;
  margin-top: 10px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}
.user-button4 {
  font-size: 9px;
  width: 12%;
  border: none;
  background-color: #f6c262;
  padding: 6px;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}
.user-button-bigg {
  font-size: 10px;
  width: 6%;

  border: none;
  background-color: #4a85fc;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 5px;
  position: absolute;
  height: 58px;
}
.user-button-bigg2 {
  font-size: 10px;
  width: 6%;
  max-width: 100%;
  border: none;
  background-color: #e254a6;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 54px;
  position: absolute;
  height: 58px;
}
.user-button-bigg3 {
  font-size: 10px;
  width: 6%;
  max-width: 100%;
  border: none;
  background-color: #47c7ad;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 123px;
  position: absolute;
  height: 58px;
}
.user-button-bigg3-new {
  font-size: 10px;
    width: 6%;
    max-width: 100%;
    border: none;
    background-color: red;
    margin-top: 27px;
    padding: 3px;
    color: #fff;
    border-radius: 4px;
    margin-left: 207px;
    position: absolute;
    height: 58px;
}
.notes {
  color: #fff;
  padding-right: 5px;
}
.search-user-account {
  float: right;
  margin-top: 20px;
  margin-right: 13px;
}
.date-modal-from {
  float: left;
  margin-right: 90px;
  margin-left: 150px;
  margin-top: 15px;
}
.date-modal-to {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
/* .date-modal > .modal-content {
  height: 345px;
} */