.MuiList-root > li {
    color: #fff;
    text-align: center;
    height: 57px;
    border-radius: 1px 1px 1px 1px !important;
    margin-left: 0 !important;
}
/* .MuiButtonBase-root {
    display: inherit !important;
} */
.MuiListItem-root {
    display: inherit !important;
}
.MuiMenu-paper {
    left: 1040px !important;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiList-root > .refund-menu {
    background-color: red !important;
}
.MuiList-root > .reorder-menu {
    background-color: gold !important;
}
.MuiList-root > .track-menu {
    background-color: blue !important;
}   
.MuiList-root > .inv-menu {
    background-color: orange !important;
}
.MuiList-root > .case-menu {
    background-color: blueviolet !important;
}
.product-setting-chat {
    font-size: 26px;
    float: right;
    margin-top: 20px;
    color: #3fad72;
    position: relative;
    left: 19px;
    bottom: 294px;
}