.refund-screen-list>li {
    float: left;
    list-style: none;
    font-weight: 600;
    width: 140px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.refund-screen-list {
    border: 1px solid #eee;
    background-color: #eee;
    border-bottom: 2px solid #888;
    width: 95%;
    margin-left: 30px;
    margin-top: 50px;

}

.refund-screen-list2>li {
    float: left;
    list-style: none;
    width: 140px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: left;
}

.refund-screen-list2 {
    border-bottom: 1px solid #eee;
    width: 95%;
    margin-left: 30px;
}

.refund-list-name {
    margin-left: 50px;
}

.refund-list-image {
    width: 100% !important;
}

.refund-img-list {
    width: 51px !important;
    margin-top: 0 !important;
}

.refund-price {
    width: 54px !important;
}

.refund-screen-input {
    width: 30px !important;
}

.refund-screen-input>input {
    width: 100%;
}

.refund-screen-btn {
    width: 58px !important;
}

.refund-screen-btn>button {
    width: 100%;
    margin-left: 20px;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
    padding: 3px;
}

.e-dialog .e-img {
    border: 1px solid #DEE2E6;
    background: #F8F8F8;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.e-dialog h6 {
    font-size: 15px;
    color: #000;
    font-weight: 700;
}
.e-dialog .modal-dialog .modal-content{
border-radius: 20px;
border: none;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.e-button{
    width: unset !important;
    margin-left: 10px !important;
}
.e-dialog p {
    font-size: 15px;
    color: #000;
}
.e-dialog .MuiButtonBase-root {
    background-color: #04984A;
    padding: 10px;
    color: #fff;
}
.e-dialog .MuiButtonBase-root:hover {
    background-color: #04984A;
    padding: 10px;
    color: #fff;
    opacity: 0.7;
}

.e-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 870px;
}
.e-dialog .footer-button {
    text-align: end;
}
.e-dialog .footer-button .MuiButtonBase-root{
    margin-right: 10px;
}
.e-dialog .footer-button .MuiButtonBase-root:last-child{
    margin-right: 0px;
}
.e-dialog .modal-header{
    border-bottom: none;
    padding: 1rem 1rem 0 1rem;
    font-weight: 700;
    font-size: 15px;
}

.e-img-flex {
    display: flex;
    flex-wrap: wrap;
}

.e-img-flex img {
    margin-right: 10px;
}