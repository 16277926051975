.progress-text {
  float: left;
  margin-bottom: 0;
}
.progress-text > li {
  list-style: none;
  font-size: 16px;
}
.progress-text-type {
  float: left;
}
.progress-searches {
  position: absolute;
  right: 240px;
  color: #000;
}
.progress {
  height: 3rem;
  background-color: #3fad725e;
  margin-top: 20px;
}
.progress-bar {
  background-color: #3fad72;
}
