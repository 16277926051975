.mt-5 {
    margin-top: 3rem!important;
    float: left;
    margin-left: 25px;
    width: 85%;
}
.dash-filter-expense {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: 26px;
    color: blue;
    float: left;
}