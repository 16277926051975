.tabs-bottom-videos {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 85%;
  position: relative;
  top: 95px;
}

/* .vid-player { */
/* width: 690px !important;
  height: 510px !important; */
/* } */

.tabvideo {
  padding: 15px !important;
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 30px;
  width: 108%;
}

.tabvideo > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.modal-video > .modal-dialog > .modal-content {
  height: auto;
}

.video-uploader-usernew {
  float: left;
  width: 20%;
  margin-right: 10px;
  margin-left: 10px;
}

.fazzy22 {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 78%;
  margin-left: 20px;
  position: relative;
  bottom: 6px;
}

.add-category-section .row .video-cards {
  /* width: 238px;
  height: 264px; */
  position: relative;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.add-category-section .row .generalMedia {
  padding: 8px 10px;
}

.add-category-section .row .generalMedia .upload-date {
  color: #b1b1b1;
  letter-spacing: 0px;
  font: normal normal normal Roboto;
  font-size: 14px;
}

/* .add-category-section .row .generalMedia .social-icon {
  width: 186px;
  height: 44px;
  padding: 4px 16px;
} */
.add-category-section .row .generalMedia .social-icon .icon-like {
  width: 24px;
  height: 44px;
}

.social-icon {
  margin-bottom: 12px;
}

.PauseIcon {
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; */
  height: 50px;
  width: 50px;
}

.video-main {
  position: relative;
}

.bread-crumb {
  margin-bottom: 0;
}

.short-videos .video-filter {
  width: 397px;
}

.short-videos .searchSection {
  width: 238px;
}

.short-videos .custom-dropdown {
  width: 149px !important;
}

.short-videos .custom-dropdown .dropdown-btn {
  padding: 7px;
}

.short-videos .profile-thumb .profile-photo img {
  height: 26px;
  width: 26px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}

.short-videos .profile-thumb h6 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  align-items: center;
  margin-bottom: 0;
  align-self: center;
}

.short-videos .profile-thumb {
  position: absolute;
  top: 10px;
  left: 10px;
}

.short-videos .video-timer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.short-videos .video-timer p {
  font-size: 14px;
  color: #fff;
}
.menuIcon-dropdown-start .dropdown-toggle:before {
  content: unset !important;
}
.menuIcon-dropdown-start .dropdown-menu {
  transform: translate(0px, 0px) !important;
  width: fit-content !important;
  box-shadow: 0px 1px 10px #00000029 !important;
  border-radius: 2px !important;
}
.menuIcon-dropdown-start .dropdown-menu .dropdown-item {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  padding: 10px 12px !important;
}
.menuIcon-dropdown-start .dropdown-menu .product-item svg path {
  fill: #020202 !important;
  stroke: #020202 !important;
}
.menuIcon-dropdown-start .dropdown-menu .delete-item {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #dc3644;
  opacity: 1;
}
.menuIcon-dropdown-start .dropdown-menu .product-item {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
}
.menuIcon-dropdown-start .dropdown-menu .delete-item:hover {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #dc3644;
  opacity: 1;
}

.profile-pic {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  border: 1px solid #fff;
  background-color: #f5f5f5;
}
.profile-pic img {
  height: 40px;
  width: 40px;
  border-radius: 3px;
}
.model-user-info p {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  margin-left: 12px;
}
.model-user-info {
  position: absolute;
  top: 20px;
  left: 20px;
}
.modal-video .modal-body {
  padding: 0;
  background-color: #000;
}
.video-modal-header {
  position: absolute;
  z-index: 99;
  color: #fff !important;
  right: 30px;
  top: 20px;
  padding: 0;
  border-bottom: unset;
}
.video-modal-header .btn-close {
  margin: 0;
  padding: 0;
  position: relative;
  background: transparent;
}
.video-modal-header .btn-close::before {
  content: " ";
  height: 25px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(45deg);
}
.video-modal-header .btn-close::after {
  content: " ";
  height: 25px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(135deg);
}
