.allvendor-section {
    float: left;
    width: 85%;
    margin-left: 28px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 10px;
    padding-bottom: 30px;
    height: 400px;
    overflow-y: scroll;
}