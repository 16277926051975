.edit-upload-btn {
    padding: 45px;
    width: 171px;
    margin-left: 73px;
    border: 2px solid #3fad72;
    background-color: #fff;
    color: #3fad72;
    font-size: 25px;
    position: relative;
  }
  .upload__image-wrapper {
    float: left;
    height: 180px;
  }
  .image-item {
    position: relative;
    bottom: 156px;
    right: -5px;
  }
  .edit-image-uploaded {
    width: 170px !important;
    height: 131px !important;
    margin-left: 59px;
    margin-top: 24px;
  }
  .edit-image-remove {
    border: 1px solid red;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 65px;
  }
  
  .fere {
    font-size: 25px;
    color: #3fad72;
    cursor: pointer;
    position: relative;
  }
  
  .fere2 {
    font-size: 25px;
    color: "transparent";
    cursor: pointer;
    position: relative;
  }
  .App {
    float: left;
    width: 79%;
  }
  