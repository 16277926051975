@media only screen and (max-width: 3840px) and (min-width: 2160px) {

    .app-width {
      max-width: 3840px !important;
    }
    .search-text {
      width: 27% !important;
      left: -30px !important;
    }
    .dash-icon {
      height: 90px !important;
    }
    .dollar-icon {
      font-size: 72px !important;
    }
    .dashboard-att-bar {
      height: 90px !important;
    }
    .money-bar {
      font-size: 35px !important;
      margin-left: 90px !important;
    }
    .money-detail-bar {
      font-size: 24px !important;
    margin-left: 33px !important;
    }
    .dash-icon2 {
      height: 90px !important;
    }
    .dash-icon3 {
      height: 90px !important;
    }
    .dash-icon4 {
      height: 90px !important;
    }
    .not-dot2 {
      font-size: 129px !important;
      left: 109px !important;
    bottom: 25px !important;
    }
    .message-icon {
      font-size: 55px !important;
    }
    .welcome-user-text {
      font-size: 45px !important;
    }
    .user-image {
      border-radius: 100px !important;
    }
    .user-list-home > li {
      margin-right: 385px !important;
      margin-left: 67px !important;
      font-size: 30px !important;
    }
    .user-emailAddress {
      font-size: 22px !important;
    }
    .notes {
      font-size: 28px !important;
    }
    .user-button {
      font-size: 18px !important;
      padding: 9px !important;
    }
    .user-button2 {
      font-size: 18px !important;
      padding: 9px !important;
      margin-top: 5px !important;
    }
    .user-suspension {
      font-size: 18px !important;
      padding: 9px !important;
    }
    .user-button4 {
      font-size: 18px !important;
      padding: 9px !important;
      margin-top: 5px !important;
    }
    .user-button-bigg {
      font-size: 20px !important; 
      height: 101px !important;
    }
    .user-button-bigg2 {
      font-size: 20px !important; 
      height: 101px !important;
      margin-left: 186px !important;
    }
    .user-button-bigg3 {
      font-size: 20px !important; 
      height: 101px !important;
      margin-left: 367px !important;
    }
    .user-button-bigg3-new {
      font-size: 20px !important; 
      height: 101px !important;
      margin-left: 548px !important;
    }
    .user-home {
      width: 75% !important;
    }
    .message-section {
      width: 7% !important;
      margin-right: 65px !important;
    }
    .users-section {
      width: 75% !important;
    }
    .cart-section {
      width: 8% !important;
      margin-right: 38px !important;
    }
    .download-section {
      width: 8% !important;
      margin-right: 38px !important;
    }
    .banner-section2 {
      width: 8% !important;
      margin-right: 38px !important;
    }
    .banner-section {
      width: 8% !important;
      margin-right: 38px !important;
    }
    .side-navigation-panel-select > li {
      font-size: 32px !important;
      width: 255% !important;
  }
  .na-area-responsive {
    width: 14% !important;
  }
  .user-plus {
    width: 75% !important;
  }
  .percentages-section {
    width: 8% !important;
    margin-right: 30px !important;
  }
  .vendor-heading {
    width: 75% !important;
  }
  .vendor-section {
    width: 75% !important;
  }
  .vendor-name {
    margin-top: 70px !important;
    font-size: 28px !important;
  }
  .vendor-email {
    margin-top: 70px !important;
    font-size: 28px !important;
  }
  .vendor-button4 {
    margin-top: 70px !important;
    font-size: 24px !important;
    padding: 10px !important;
  }
  .vendor-button {
    font-size: 24px !important;
    padding: 10px !important;
  }
  .vendor-button3 {
    font-size: 24px !important;
    padding: 10px !important;
  }
  .vendor-button2 {
    font-size: 24px !important;
    padding: 10px !important;
  }
  .request-button {
    width: 8% !important;
    margin-right: 30px !important;
  }
  .refund-button {
    width: 8% !important;
    margin-right: 30px !important;
  }
  .coupon-section {
    width: 75% !important;
  }
  .progress-home {
    width: 8% !important;
    margin-right: 30px !important;
  }
  .num-progress {
    right: 55px !important;
    width: 1% !important;
  }
  .num-progress2 {
    right: 55px !important;
    width: 1% !important;
  }
  .num-progress3 {
    right: 55px !important;
    width: 1% !important;
  }
  .num-progress4 {
    right: 55px !important;
    width: 1% !important;
  }
  .calender-section {
    width: 75% !important;
  }
  .calender-event-btn {
    right: 605px !important;
    width: 9% !important;
  }
  .nav-area2 {
    height: 7770px !important;
  }
  .user-chat-image {
    border-radius: 100px !important;
  }
  .active-user1 {
    border-radius: 100px !important;
  }
  .call-phone {
    font-size: 65px !important;
  }
  .call-numbers > p {
    font-size: 35px !important;
  }
  .msgCall {
    width: 85% !important;
    margin-left: 55px !important;
  }
  .register-card {
    height: auto !important;
  }
}
@media only screen and (min-width:2400) {
  .order-timeline > li {
    right: 250px !important;
  }
  .g-map {
    width: 100% !important;
  }
  .g-map > div {
    height: 45vh !important;
    width: 100% !important;
  }
  .count-num {
    margin-left: 38px !important;
  }
  .cat-edit {
    width: 18% !important;
  }
  .cat-edit {
    width: 260px !important;
  }
  .cat-header22 > li {
    width: 232px !important;
  }
  .calling-section {
    right: 545px !important;
    top: 68px !important;
  }
  .user-button-bigg3-new {
    margin-left: 244px !important;
  }
  .video-detail-carder {
    width: 13% !important;
  }
  .progress-searches {
    right: 1015px !important;
  }
  .view-btn-mac {
    width: 108px;
  }
  .delete-btn-mac {
    width: 108px !important;
  }
  .nav1 {
    margin-left: 62px;
    width: 60%;
  }
  .icon-1 {
    font-size: 43px !important;
  }
  .home {
    font-size: 28px;
  }
  .nav2 {
    width: 132% !important;
    max-width: 150% !important;
  }
  .event-user {
    width: 55% !important;
  }
  .event-month {
    width: 83% !important;
  }
  .day1-event {
    width: 8% !important;
    margin-left: 46px !important;
    margin-right: 73px !important;
  }
  .allusers-section {
    width: 82% !important;
  }
  .alluser-header {
    width: 65% !important;
    max-width: 100% !important;
  }
  .not-details {
    margin-left: 820px !important;
  }
  .plus-text {
    width: 50% !important;
    max-width: 100% !important;
  }
  .view-all {
    margin-right: 41px !important;
  }
  .banner-section {
    width: 16% !important;
    margin-top: -28px !important;
  }
  .banner-section2 {
    width: 16% !important;
    margin-top: -28px !important;
  }
  .order-actions {
    width: 83% !important;
    margin-left: 20px !important;
    max-width: 100% !important;
  }
  .order-list > li {
    padding-right: 192px !important;
  }
  .order-list {
    width: 100%;
    max-width: 100%;
  }
  .product-close {
    left: 255px !important;
  }
  .product-list {
    float: left !important;
    width: 83% !important;
    max-width: 100% !important;
    margin-left: 20px !important;
  }
  .border-list1 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .user-order {
    width: 7% !important;
    margin-left: 125px !important;
  }
  .order-status {
    margin-left: 145px !important;
    width: 7% !important;
    max-width: 7% !important;
  }
  .user-order > p {
    width: 70% !important;
    max-width: 100% !important;
  }
  .product-phone {
    width: 100% !important;
    max-width: 100% !important;
  }
  .order-quantity {
    width: 4% !important;
    margin-left: 203px !important;
    max-width: 4% !important;
  }
  .order-users {
    width: 3% !important;
    max-width: 3% !important;
    margin-left: 232px !important;
  }
  .orderlist-btns {
    margin-left: 105px !important;
    width: 17% !important;
    max-width: 17% !important;
  }
  .main2 > li {
    width: 200px !important;
    max-width: 100%;
  }
  .main2 {
    padding-left: 60px;
  }
  .mainName {
    margin-left: 15px;
  }
  .mainEmail {
    margin-left: 35px !important;
    width: 15% !important;
  }
  .mainNum {
    margin-left: 84px !important;
  }
  .plus-btn {
    width: 18% !important;
  }
  .user-button-history-full {
    margin-left: 205px !important;
  }
  .user-list-home > li {
    margin-right: 250px !important;
  }
  .user-button-bigg2 {
    margin-left: 85px !important;
  }
  .user-button-bigg3 {
    margin-left: 165px !important;
  }
  .efullname {
    margin-left: 97px;
    width: 15% !important;
  }
  .headingss-list > li {
    width: 285px !important;
  }
  .headingss-list > li {
    width: 274px !important;
  }
  .efullname {
    margin-left: 100px !important;
  }
  .eemail {
    width: 21% !important;
  }
  .edot {
    width: 9% !important;
  }
  .percentages-section {
    width: 16% !important;
  }
  .att-details > li {
    margin-right: 105px !important;
  }
  .coupon-select-password {
    margin-left: 178px !important;
  }
  .coupon-select-password > h5 {
    width: 15%;
  }
  .reg-vendor {
    margin-left: 0 !important;
    margin-top: 90px;
  }
  .check-responsive {
    margin-left: 254px;
  }
  .reg-vendor {
    margin-top: 110px !important;
  }
  .headingss-list > li {
    width: 270px !important;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1600px) {
  .not-details2 {
    margin-left: 950px !important;
  } 
  .card-chat {
    margin-top: 0 !important;
    width: 385% !important;
    padding: 0 !important;
  }
  .newUi {
    height: 500px !important;
  }
  .react-audio-player {
    right:70px !important;
    top: -63px !important;
  }
  .order-timeline > li {
    right: 184px !important;
  }
  .g-map {
    width: 100% !important;
  }
  .g-map > div {
    height: 45vh !important;
    width: 100% !important;
  }
  .count-num {
    margin-left: 58px !important;
  }
  .cat-header22 > li {
    width: 362px !important;
    text-align: left !important;
  }
  .calling-section {
    right: 60px !important;
    top: 76px !important;
  }
  .card-call {
    margin-left: 53px !important;
    padding: 0 !important;
  }
  .request-button {
    width: 14% !important;
  }
  .refund-button {
    width: 14% !important;
  }
  .search-area {
    margin-left: 535px !important;
  }
  .search-icon2 {
    left: 593px;
  }
  .count-category {
    margin-left: 100px;
  }
  .nav-area {
    width: 12% !important;
  }
  .progress-searches {
    right: 428px !important;
  }
  .search-area {
    float: left !important;
    margin-left: 46px !important;
    width: 51% !important;
  }
  .user-button-bigg2 {
    margin-left: 76px !important;
  }
  .user-button-bigg3 {
    margin-left: 148px !important;
  }
  .user-button-bigg3-new {
    margin-left: 220px !important;
  }
  .message-section {
    width: 14% !important;
  }
  .cart-section {
    width: 14% !important;
  }
  .download-section {
    width: 14% !important;
  }
  .users-section {
    width: 68% !important;
  }
  .vendor-button4 {
    margin-left: 83px !important;
  }
  .vendor-name {
    margin-top: 25px !important;
  }
  .coupon-content {
    margin-left: 470px !important;
  }
  .chat {
    margin-top: 30px !important;
  }
  .search-area-product {
    left: -38px !important;
  }
  .search-icon-chat {
    right: 65px !important;
  }
  .card-body {
    padding: 0em 4em !important;
  }
  .search-bar-product {
    position: relative !important;
    left: 108px !important;
  }
  .search-icon-product {
    right: -45px !important;
    z-index: 9999 !important;
  }
  .product-setting {
    left: 72px !important;
    margin-top: -35px !important; 
  }
  .MuiMenu-paper {
    left: 1580px !important;
  }
  .id-card {
    width: 85% !important;
    margin-left: 20px !important;
  }
  .history-content > li {
    margin-left: 50px !important;
    margin-right: 49px !important;
    font-size: 18px !important;
  }
  .not-image-text {
    font-size: 16px !important;
    margin-top: 28px !important;
    margin-left: 98px !important;
  }
  .not-details {
    margin-left: 825px !important;
  }
  .money-detail-bar {
    font-size: 13px !important;
  }
  .money-bar {
    font-size: 15px !important;
  }
  .id-card > p {
    margin-left: 53px !important;
  }
  .history-image {
    margin-left: 92px !important;
  }
  .user-cash {
    margin-left: 118px !important;
  }
  .product-eta {
    margin-left: 180px !important;
  }
  .delivery {
    margin-left: 190px !important;
  }
  .delivery2 {
    margin-left: 145px !important;
  }
  .delivery3 {
    margin-left: 133px !important;
  }
  .user-dots {
    margin-left: 150px !important;
  }
  .video-card2 {
    width: 100% !important;
  }
  .user-videos-tab {
    /* width: 16% !important; */
  }
  .search-header {
    float: left !important;
    width: 85% !important;
  }
  .video-detail-carder {
    width: 15% !important;
  }
  .video-responsive {
    position: relative;
    bottom: 28px;
  }
  .calender-event-btn {
    width: 6% !important;
    right: 615px !important;
  }
  .product-images-boxes {
    width: 60% !important;
  }
  .emoji {
    right: 148px !important;
  }
  
  .order-actions {
    width: 86% !important;
    margin-left: 20px !important;
    max-width: 100% !important;
  }
  .order-list > li {
    padding-right: 195px !important;
  }
  .product-close {
    left: 255px !important;
  }
  .product-list {
    float: left !important;
    width: 86% !important;
    max-width: 100% !important;
    margin-left: 20px !important;
  }
  .border-list1 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .user-order {
    width: 7% !important;
    margin-left: 125px !important;
  }
  .order-status {
    margin-left: 145px !important;
    width: 7% !important;
    max-width: 7% !important;
  }
  .user-order > p {
    width: 70% !important;
    max-width: 100% !important;
  }
  .product-phone {
    width: 100% !important;
    max-width: 100% !important;
  }
  .order-quantity {
    width: 4% !important;
    margin-left: 203px !important;
    max-width: 4% !important;
  }
  .order-users {
    width: 3% !important;
    max-width: 3% !important;
    margin-left: 232px !important;
  }
  .orderlist-btns {
    margin-left: 105px !important;
    width: 17% !important;
    max-width: 17% !important;
  }
  .main2 > li {
    width: 200px !important;
    max-width: 100%;
  }
  .main2 {
    padding-left: 60px;
  }
  .mainName {
    margin-left: 15px;
  }
  .mainEmail {
    margin-left: 35px !important;
    width: 15% !important;
  }
  .mainNum {
    margin-left: 84px !important;
  }
  .plus-btn {
    width: 18% !important;
  }
  .user-list-home > li {
    margin-right: 250px !important;
  }
  .headingss-list > li {
    width: 274px !important;
  }
  .efullname {
    margin-left: 100px !important;
  }
  .eemail {
    width: 21% !important;
  }
  .edot {
    width: 9% !important;
  }
  .percentages-section {
    width: 14% !important;
    max-width: 100% !important;
  }
  .vendor-section {
    width: 68% !important;
  }
  .coupon-section {
    width: 66% !important;
  }
  .calender-section {
    width: 66% !important;
  }
  .circular-image {
    width: 14% !important;
  }
  .mainNum {
    width: 14% !important;
  }
  .coupon-select-password {
    margin-left: 178px !important;
    width: 74% !important;
  }
  .coupon-select-password > h5 {
    width: 14%;
  }
  .check-responsive {
    margin-left: 250px;
  }
  .reg-vendor {
    margin-left: 0 !important;
    margin-top: 100px !important;
  }

  /* .additional-images {
    right: 259px !important;
  }
  .subtract-images {
    right: 250px !important;
  } */
}

@media screen and (max-width: 1900px) {
  .call-product-setting {
    left: 55px !important;
    bottom: 310px !important;
  }
  .messenger-product {
    height: 610px !important;
  }
  .cat-header22 > li {
    width: 360px !important;
    text-align: left !important;
}
  .not-details2 {
    margin-left: 935px !important;
  }  
  .check-responsive {
    margin-left: 253px !important;
  }
  .mobile1 {
    margin: 61px !important;
  }
}

@media only screen and (max-width: 1707px) {
  .categories-border3 {
    width: 82% !important;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 600px) {
  .cat-header22 > li {
    width: 316px !important;
    text-align: left !important;
}
  .not-details2 {
    margin-left: 790px !important;
  } 
  .order-timeline > li {
    right: 150px !important;
}
.count-num {
  margin-left: 35px !important;
}
  .calling-section {
    right: 50px !important;
  }
  .card-call {
    margin-left: 29px !important;
  }
  .mobile1 {
    margin: 65px !important;
  }
  .user-button-bigg2 {
    margin-left: 78px !important;
  }
  .user-button-bigg3 {
    margin-left: 151px !important;
  }
  .user-button-bigg3-new {
    margin-left: 224px !important;
  }
  .history-content > li {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  .order-status {
    width: 8% !important;
    max-width: 10% !important;
  }
  .orderlist-btns {
    margin-left: 55px !important;
  }
  .mainEmail {
    margin-left: 30px !important;
  }
  .main2 > li {
    width: 178px !important;
  }
  .headingss-list > li {
    width: 243px !important;
  }
  .efullname {
    margin-left: 80px !important;
  }
  .not-details {
    margin-left: 745px !important;
  }
  .att-details4 > li {
    margin-right: 118px !important;
  }
  .att-details3 > li {
    width: 23% !important;
  }
  .count-att {
    margin-left: 12px !important;
  }
  .size-att2 {
    margin-left: 194px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
}
@media only screen and (max-width: 1660px) {
  .call-product-setting {
    left: 52px !important;
    bottom: 355px !important;
  }
  .not-details2 {
    margin-left: 765px !important;
  }
  .newUi {
    height: 830px !important;
}
  .cat-header22 > li {
    width: 308px !important;
    text-align: left !important;
}
  .calling-section {
    right: 46px !important;
    top: 79px !important;
}
.card-call {
  padding: 0 !important;
  margin-left: 45px !important;
}
  .mobile1 {
    margin: 58px !important;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1100px) {
  .newUi {
    height: 525px !important;
  }
  .order-timeline > li {
    right: 138px !important;
}
  .cat-header22 > li {
    width: 192px !important;
}
  .calling-section {
    right: 43px !important;
    top: 79px !important;
}
.card-call {
  margin-left: 18px !important;
  padding: 0px !important;
}
  .user-details {
    width: 95% !important;
  }
  .user-button-bigg2 {
    margin-left: 73px !important;
  }
  .user-button-bigg3 {
    margin-left: 142px !important;
  }
  .user-button-bigg3-new {
    margin-left: 211px !important;
  }
  .id-card {
    width: 80% !important;
    margin-left: 25px !important;
  }
  .dash-amount {
    float: none !important;
    margin-top: 10px !important;
    text-align: center !important;
  }
  .additional-images {
    right: 130px !important;
  }
  .subtract-images {
    right: 270px !important;
  }
  .product-images-boxes {
    width: 55% !important;
  }
  .user-videos-tab {
    /* width: 19% !important; */
  }
  .video-detail-carder {
    width: 19% !important;
  }
  .calender-event-btn {
    right: 540px !important;
  }
  .product-images-boxes {
    width: 60% !important;
  }
  .not-details {
    margin-left: 685px !important;
  }
  .day1-event {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .event-boxes-calender2 {
    width: 10.5% !important;
  }
  .event-boxes-calender4 {
    width: 10.5% !important;
  }
  .banner-text {
    margin-left: 25px !important;
  }
  .banner-text2 {
    margin-left: 25px !important;
    margin-right: 10px;
  }
  .mobile1 {
    margin: 40px !important;
  }
  .categories-border {
    width: 91% !important;
  }
  .categories-border2 {
    width: 91% !important;
  }
  .categories-border3 {
    width: 91% !important;
  }
  .order-list > li {
    padding-right: 155px !important;
  }
  .product-close {
    left: 221px !important;
  }
  .user-order {
    width: 7% !important;
    margin-left: 95px !important;
  }
  .order-status {
    margin-left: 114px !important;
    width: 8% !important;
    max-width: 9% !important;
  }
  .order-quantity {
    margin-left: 170px !important;
  }
  .order-users {
    margin-left: 195px !important;
  }
  .mainNum {
    margin-left: 70px !important;
  }
  .main2 > li {
    width: 172px !important;
  }
  .eadmin {
    margin-left: 28px;
    width: 16%;
  }
  .user-list-home > li {
    margin-right: 235px !important;
  }
  .att-details4 > li {
    margin-right: 118px !important;
  }
  .att-details3 > li {
    width: 23% !important;
  }
  .count-att {
    margin-left: 12px !important;
  }
  .size-att2 {
    margin-left: 194px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1100px) {
  .card-call {
    width: 73% !important;
  }
  .call-product-setting {
    bottom: 325px !important;
  }
  .messenger-product {
    height: 625px !important;
  }
  .not-details2 {
    margin-left: 615px !important;
  } 
  .order-timeline > li {
    right: 115px !important;
}
.newUi {
  height: 830px !important;
}
  .g-map {
    width: 100% !important;
  }
  .g-map > div {
    height: 45vh !important;
    width: 100% !important;
  }
  .cat-header22 > li {
    width: 277px !important;
    text-align: left !important;
}
  .calling-section {
    right: 40px !important;
    top: 82px !important;
  }
  .product-setting {
    margin-top: -18px !important;
  }
  .card-call {
    padding: 0 !important;
    margin-left: 40px !important;
  }
  .user-button-bigg2 {
    margin-left: 63px !important;
  }
  .user-button-bigg3 {
    margin-left: 122px !important;
  }
  .user-button-bigg3-new {
    margin-left: 181px !important;
  }
  .users-section {
    width: 66%;
  }

  .count-category {
    width: 35px !important;
  }
  .subtract-images {
    right: 217px !important;
  }
  .progress-searches {
    right: 245px !important;
  }
  .additional-images {
    right: 75px !important;
  }
  .user-videos-tab {
    /* width: 21% !important; */
  }
  .video-detail-carder {
    width: 19% !important;
  }
  .calender-event-btn {
    right: 458px !important;
  }
  .product-images-boxes {
    width: 60% !important;
  }
  .not-details {
    margin-left: 590px !important;
  }
  .event-user {
    width: 85% !important;
  }
  .event-month {
    width: 83% !important;
  }
  .date-events {
    width: 72% !important;
  }
  .alluser-header {
    width: 85% !important;
  }
  .allusers-section {
    width: 81% !important;
  }
  .users-section-allverify {
    width: 81% !important;
  }
  .allvendor-section {
    width: 81% !important;
  }
  .day1-event {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .event-boxes-calender2 {
    width: 9.5% !important;
  }
  .event-boxes-calender4 {
    width: 9.5% !important;
  }
  .mapreport > figure > svg {
    width: 1100px !important;
    height: 800px !important;
  }
  .map-modal > figure > svg {
    width: 1200px !important;
    height: 800px !important;
  }
  .world-map-modal > .modal-dialog {
    max-width: 1080px !important;
  }
  .world-map-modal > .modal-dialog > .modal-content {
    width: 100% !important;
    max-width: 100%;
    left: 17px;
  }
  .map-full {
    width: 32% !important;
  }
  .edit-name2 {
    width: 18% !important;
  }
  .edit-name4 {
    width: 15%;
  }
  .edit-name5 {
    width: 15% !important;
  }
  .edit-name6 {
    width: 15% !important;
  }
  .edit-name7 {
    width: 15% !important;
  }
  .edit-name8 {
    width: 15% !important;
  }
  .edit-name9 {
    width: 15% !important;
  }
  .inv-btn {
    margin-left: 232px !important;
  }
  .mapreport > .worldmap__figure-container > svg {
    max-width: inherit !important;
  }
  .worldmap__figure-container {
    margin-left: 0 !important;
  }
  .world-map-modal > .modal-dialog {
    width: 946px !important;
    max-width: 946px !important;
  }
  .banner-section {
    width: 16% !important;
  }
  .banner-section2 {
    width: 16% !important;
  }
  .mobile1 {
    width: 25% !important;
    max-width: 100% !important;
    margin: 22px !important;
  }
  .cat-header > li {
    width: 170px !important;
  }
  .cat-header2 > li {
    width: 170px;
  }
  .order-actions {
    width: 83% !important;
    max-width: 100% !important;
    margin-left: 15px !important;
  }
  .order-list > li {
    padding-right: 127px !important;
  }
  .user-order {
    margin-left: 79px !important;
  }
  .order-status {
    margin-left: 90px !important;
  }
  .order-quantity {
    margin-left: 135px !important;
  }
  .border-list1 {
    width: 82% !important;
  }
  .mainNum {
    margin-left: 38px !important;
    width: 12% !important;
  }
  .mainName {
    margin-left: 15px !important;
  }
  .mainEmail {
    margin-left: 22px !important;
    width: 15% !important;
  }
  .main2 > li {
    width: 162px !important;
  }
  .user-list-home > li {
    margin-right: 190px !important;
  }
  .user-button-del-full {
    margin-left: 78px !important;
  }
  .user-button-history-full {
    margin-left: 152px !important;
  }
  .main2 > li {
    width: 157px !important;
  }
  .headingss-list > li {
    width: 203px !important;
  }
  .efullname {
    margin-left: 62px !important;
  }
  .eemail {
    width: 21% !important;
  }
  .percentages-section {
    width: 16% !important;
  }
  .vendor-section {
    width: 65% !important;
  }
  .coupon-section {
    width: 65% !important;
  }
  .calender-section {
    width: 65% !important;
  }
  .circular-image {
    width: 16% !important;
  }
  .att-details4 > li {
    margin-right: 106px !important;
  }
  .att-details3 > li {
    width: 23% !important;
  }
  .count-att {
    margin-left: 12px !important;
  }
  .size-att2 {
    margin-left: 194px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .nav1 {
    margin-left: 23px !important;
    font-size: 18px !important;
  }
  .nav2 {
    margin-left: 23px !important;
    font-size: 18px !important;
  }
  .react-audio-player {
    top: -62px !important;
    right: 42px !important;
  }
  .coupon-select-password {
    margin-left: 180px;
    width: 58%;
  }
  .coupon-select-password > select {
    width: 45%;
  }
}

@media only screen and (max-width: 1400px) {
  .newUi {
    height: 500px !important;
  }
  .product-setting {
    margin-top: -32px !important;
}
  .cat-header22 > li {
    width: 170px !important;
}
  .card-call {
    padding: 0 !important;
    margin-left: 0px !important;
  }
  .card-body {
    padding: 0rem 1rem !important;
  }
  .cat-edit {
    margin-left: 20px !important;
  }
  .user-list-home > li {
    margin-right: 120px !important;
  }
  .user-button-bigg {
    width: 5% !important;
  }
  .user-button-bigg2 {
    width: 5% !important;
    margin-left: 54px !important;
  }
  .user-button-bigg3 {
    width: 5% !important;
    margin-left: 103px !important;
  }
  .user-button-bigg3-new {
    width: 5% !important;
    margin-left: 152px !important;
  }
}

@media only screen and (max-width: 1367px) {
  .mainEmail {
    margin-left: 22px !important;
  }
  .main2 > li {
    width: 150px !important;
  }
  .headingss-list > li {
    width: 212px !important;
  }
  .not-details {
    margin-left: 545px !important;
  }
  .att-details4 > li {
    margin-right: 106px !important;
  }
  .att-details3 > li {
    width: 24% !important;
  }
  .count-att {
    margin-left: -5px !important;
  }
  .size-att2 {
    margin-left: 185px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .cat-header > li {
    width: 232px !important;
  }
  .cat-edit {
    margin-left: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .call-product-setting {
    left: 6px !important;
  }
  .not-details2 {
    margin-left: 560px !important;
  }
  .newUi {
    height: 833px !important;
} 
  .order-timeline > li {
    right: 105px !important;
}
  .cat-header22 > li {
    width: 202px !important;
    text-align: center !important;
}
  .cat-header > li {
    width: 231px !important;
  }
  .cat-edit {
    margin-left: 10px !important;
  }

  .mainEmail {
    margin-left: 25px !important;
  }
  .main2 > li {
    width: 152px !important;
  }
  .headingss-list > li {
    width: 195px !important;
  }
  .att-details4 > li {
    margin-right: 106px !important;
  }
  .att-details3 > li {
    width: 24% !important;
  }
  .count-att {
    margin-left: -5px !important;
  }
  .size-att2 {
    margin-left: 185px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .card-call {
    width: 72% !important;
    margin-left: 38px !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 480px) {
  .call-product-setting {
    left: -5px !important;
    bottom: 310px !important;
  }
  .not-details2 {
    margin-left: 495px !important;
  }
  .newUi {
    height: 830px !important;
  }
  .product-setting {
    margin-top: -30px !important;
    left: -3px !important;
  }
  .react-audio-player {
    width:530px !important;
  }
  .pin-card {
    width: 23% !important;
  }
  .pinn-btn1 {
    margin-right: 10px !important; 
  }
  .order-timeline > li {
    right: 92px !important;
}
  .cat-header22 > li {
    width: 192px !important;
    text-align: center !important;
}
  .card-call {
    padding: 0px !important;
    margin-left: 27px !important;
    width:79% !important;
  }
  .card-body {
    padding: 0 !important;
  }
  .calling-section {
    right: 34px !important;
    top: 84px !important;
  }
  .request-button {
    width: 17% !important;
  }
  .refund-button {
    width: 17% !important;
  }
  .cat-header > li {
    width: 208px !important;
  }
  .cat-edit {
    margin-left: 10px !important;
    width: 16% !important;
  }
  .count-category {
    margin-left: 62px !important;
  }
  .mobile1 {
    margin: 21px !important;
  }
  .count-category {
    margin-left: 15px;
  }
  .order-status {
    width: 9% !important;
    max-width: 9% !important;
    margin-left: 75px !important;
  }
  .order-quantity {
    margin-left: 95px !important;
  }
  .order-list > li {
    padding-right: 115px !important;
  }
  .orderlist-btns {
    margin-left: 40px !important;
  }
  .user-emailAddress {
    position: relative !important;
    right: 25px !important;
  }
  .user-button-bigg3 {
    margin-left: 104px !important;
  }
  .not-details {
    margin-left: 750px !important;
  }
  .user-button-del-full {
    margin-left: 70px !important;
  }
  .user-button-history-full {
    margin-left: 136px !important;
  }
  .user-list-home > li {
    margin-right: 140px !important;
  }
  .mainEmail {
    margin-left: 22px !important;
  }
  .main2 > li {
    width: 122px !important;
  }
  .headingss-list > li {
    width: 187px !important;
  }
  .att-details4 > li {
    margin-right: 85px !important;
  }
  .att-details3 > li {
    width: 26% !important;
  }
  .count-att {
    margin-left: -5px !important;
  }
  .size-att2 {
    margin-left: 137px !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .list-name2 {
    width: 190px;
    margin-left: 0px;
}
  .call-product-setting {
    bottom: 360px !important;
  }
  .messenger-product {
    height: 645px !important;
    overflow-y: scroll !important;
  }
  .categories-border33 {
    width: 92% !important;
    text-align: center !important;
  }
  .not-details2 {
    margin-left: 425px !important;
  }
  .not-details {
    margin-left: 680px !important;
  }
  .register-card {
    height: 765px !important;
  }
  .dark-overlay {
    height: 765px !important;
  }
  .history-content2 > li {
    font-size: 10px !important;
  }
  .newUi {
    height: 802px !important;
  }
  .product-setting {
    margin-top: -15px !important;
    left: 24px !important;
}
  .react-audio-player {
    width:480px !important;
  }
  .dash-subnav > li {
    width: 10% !important;
    margin-right: 9px !important;
  }
  .pinn-btn1 {
    margin-left: 15px !important;
  }
  .card-call {
    padding: 25px !important;
    margin-left: 26px !important;
}
  .history-section > ul > li {
    margin-right: 89px !important;
  }
  .history-num {
    margin-left: 133px !important;
  }
  .history-total {
    margin-left: 148px !important;
  }
  .history-detail {
    margin-left: 45px !important;
  }
  .order-timeline > li {
    right: 80px !important;
}
  .cat-header22 > li {
    width: 192px !important;
}
  .cat-header > li {
    width: 155px !important;
  }
  .cat-edit {
    margin-left: 10px !important;
    width: 18% !important;
  }
  .cat-header2 {
    padding-left: 1rem !important;
  }
  .count-category {
    margin-left: 62px !important;
  }
  .pending-btn {
    font-size: 17px;
  }
  .check-allproducts {
    margin-right: -20px !important;
  }
  .headingss-list > li {
    width: 180px !important;
  }
  .action-btns {
    width: 34% !important;
  }
  .mobile1 {
    margin: 20px !important;
  }
  .categories-border {
    width: 92% !important;
  }
  .categories-border3 {
    width: 92% !important;
  }
  .cat-header2 > li {
    width: 108px !important;
  }
  .count-category {
    width: 20px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 300px) {
  
  .react-audio-player {
    width: 390px !important;
}
  .users-headings {
    width: 98% !important;
    margin-left: 6px !important;
  }
  .new-menu-pass {
    margin-right: 3px !important;
  }
  .new-menu-date {
    margin-left: 3px !important;
    margin-right: 0 !important;
  }
  .new-menu {
    margin-right: 0 !important;
  }
  .user-section1 {
    margin-left: 6px !important;
    width: 98% !important;
  }
  .efullname {
    margin-left: 18px !important;
}
  .headingss-list > li {
    width: 155px !important;
}
.eusernewimage {
  width: 5% !important;
}
  .name-margin {
    width: 40px !important;
  }
  .slug-margin {
    margin-right: 106px !important;
  }
  .order-margin {
    margin-right: 0px !important;
  }
  .terms-margin {
    margin-left: 52px !important;
    margin-right: 0 !important;
  }
  .att-details4 > li {
    margin-right: 57px !important;
  }
  .att-details3 > li {
    width: 24% !important;
  }
  .count-att {
    margin-left: -4px !important;
  }
  .size-att2 {
    margin-left: 138px !important;
  }
  .toggle-product {
    margin-left: 4px !important;
  }
  .enable-product {
    margin-left: 10px !important;
  }
  .enable-product2 {
    margin-left: 10px !important;
  }
  .video-dislike {
    margin-left: 13px !important;
  }
  .num-hearts {
    margin-top: 25px !important;
  }
  .not-details {
    margin-left: 325px !important;
  }
  .user-notes {
    width: 80% !important;
  }
  .user-list-home > li {
    margin-right: 60px !important;
  }
  .user-emailAddress {
    position: relative !important;
    right: 65px !important;
  }
  .user-button2 {
    font-size: 9px !important;
  }
  .user-button4 {
    font-size: 7px !important;
  }
  .tab1-border {
    width: 80% !important;
  }
  .history-content1 > li {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
}
