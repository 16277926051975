.CircularProgressbar {
    width: 48%;
    display: block;
    margin: 0 auto;
}
.progress1 .CircularProgressbar-path {
    stroke: purple;
}
.CircularProgressbar {
    width: 50% !important;
}
.progress2 .CircularProgressbar-path {
    stroke: goldenrod;
}