.dashboard{
  margin-top: 60px;
}
.pic-section {
  float: left;
  width: 20%;
  border-radius: 40px;
}
.pic-section > img {
  width: 100%;
  border-radius: 80px;
}
.cam-icon {
  margin-top: -61px;
  margin-left: 55px;
  font-size: 29px;
  color: #fff;
}
.pic-section > p {
  text-align: center;
  font-weight: 600;
}
.details-section {
  float: left;
  width: 70%;
  border-left: 2px solid blueviolet;
}
.profile-fields {
  margin-left: 50px;
}
.email-field {
  width: 95%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  padding-left: 10px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.request-button {
  border: 1px solid orange;
  background-color: orange;
  border-radius: 40px;
  margin-top: 40px;
  width: 16%;
  margin-right: 10px;
  float: right;
}
.request-text {
  float: left;
  font-size: 18px;
  margin-top: 13px;
  margin-left: 10px;
  color: #fff;
  font-weight: 700;
}
.request-num {
  float: right;
  margin-right: 16px;
  margin-top: 14px;
  color: #fff;
  font-weight: 700;
}
.refund-button {
  border: 1px solid orangered;
  background-color: orangered;
  border-radius: 40px;
  margin-top: 20px;
  width: 16%;
  margin-right: 10px;
  float: right;
}
