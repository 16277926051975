.banner-text {
  text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 45px;
    font-size: 22px;
}
.banner-section {
  float: right;
  width: 14%;
  max-width: 100%;
  border: 1px solid purple;
  background-color: purple;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: -24px;
  border-radius: 50px;
}
.banner-border {
  border: 1px solid palevioletred;
  background-color: palevioletred;
  float: left;
  width: 99.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 10px;
  max-width: 100%;
}
