.welcome-card {
  border: 1px solid rgb(216, 214, 214);
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  float: right;
  width: 20%;
  margin-top: 20px;
  margin-right: 20px;
}
.welcome-user {
  display: block;
  margin: 0 auto;
  width: 20%;
  margin-top: 12px;
  border-radius:50px;
}
.welcome-user-text {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
