.search-area {
  float: right;
}
.marketplace-section {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 65%;
  float: left;
  margin-left: 28px;
  border-radius: 10px;
}
.products-heading {
  margin-left: 28px;
  float: left;
  margin-top: 20px;
}
.products-heading > h3 {
  margin-bottom: 0;
}
.products-heading > p {
  margin: 0;
  color: #888;
  font-size: 12px;
}
.search-bar2 {
  width: 28%;
  padding: 15px;
  padding-left: 55px;
  border-radius: 6px;
  margin-top: 20px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  float: right;
  margin-right: 20px;
}
.search-icon2 {
  font-size: 24px;
  color: green;
  position: relative;
  top: 34px;
  left: 445px;
}
.product-border {
  border-bottom: 1px solid #eee;
  float: left;
  width: 96%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.check-allproducts {
  float: left;
  width: 18%;
  margin-left: 28px;
  margin-top: 10px;
  margin-right: 20px;
}
.form-check-label {
  padding-left: 5px;
  font-weight: 700;
}
.category {
  float: left;
  width: 13%;
  padding: 10px;
  border: 1px solid #eee;
  margin-right: 10px;
}
.category2 {
  float: left;
  width: 16%;
  padding: 10px;
  border: 1px solid #eee;
  margin-right: 10px;
}
.filter-btn {
  float: left;
  margin-left: 10px;
  padding: 9px;
  border: 1px solid lightgrey;
}
.filter-icon {
  padding-right: 3px;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.product-card {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 93%;
  float: left;
  margin-left: 28px;
  border-radius: 10px;
  margin-top: 30px;
}
.check-allproducts2 {
  float: left;
  margin-left: 15px;
  margin-top: 25px;
    margin-bottom: 15px !important;
}

.product-image {
  width: 4%;
  margin-top: 8px;
  margin-left: 15px;
  float: left;
  margin-bottom: 8px;
}
.name {
  margin: 0;
  font-size: 9px;
  color: #888;
  font-weight: 600;
}
.product-name {
  margin: 0;
  width: 90%;
  margin-top: 3px;
  font-size: 14px;
}
.product-desc2 {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
  width: 10%;
}
.product-desc3 {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
  width: 10%;
  margin-right: 25px;
}
.productby-desc {
  float: left;
  margin-top: 15px;
  margin-left: 0px;
  width: 10%;
}
.product-card2 {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 93%;
  float: left;
  margin-left: 28px;
  border-radius: 10px;
  margin-top: 10px;
}
.review-btn {
  font-size: 13px;
  font-weight: 500;
  width: 87%;
  margin-top: 0px;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  padding: 1px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.quick-btn {
  float: left;
  position: relative;
  top: 20px;
  right: 4px;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
  width: 9%;
  margin-bottom: 00px;
  font-size: 12px;
}
.pending-btn {
  float: left;
    width: 15%;
    margin-right: 15px;
    padding: 9px;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
}