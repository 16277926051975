.app-header {
  position: relative;
  margin-bottom: 30px;
}
.mainHeader {
  box-shadow: 0px 1px 10px #00000029;
  padding: 10px 20px;
  width: 85%;
  z-index: 999;
  top: 0;
  position: fixed;
  background: #fff;
}
.bell g path {
  fill: #020202 !important;
  stroke: #020202 !important;
}

.iconSide {
  justify-content: space-around;
}

.bell {
  font-size: 30px;
}

.header-dropdown {
  width: 201px;
  position: fixed;
  top: 56px;
  z-index: 999;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #00000029;
  right: 19px;
  border-radius: 0px 3px 3px 0px;
  padding: 0 14px;
}
.dropdownIcon {
  height: 24px;
  width: 24px;
  font-size: 24px;
  margin-right: 9px;
  display: flex;
}
.header-dropdown > a {
  color: #020202;
  display: flex;
  text-align: left;
  list-style: none;
  font: normal normal normal 15px Roboto;
  text-decoration: none;
  align-items: center;
  padding: 10.5px 0px;
}
.header-dropdown > a:last-child {
  text-align: center !important;
}
