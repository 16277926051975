.dash-bell {
    float: left;
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    width: 5%;
    padding: 17px;
    margin-left: 25px;
    margin-top: 50px;
    border-radius: 8px;
}
.dash-bell-icon {
    font-size: 25px;
}
.dash-alerts {
    margin-top: 60px;
    margin-left: 20px;
    font-weight: 700;
    margin-bottom: 0;
}
.alerts-option {
    font-size: 12px;
    margin-left: 20px;
    color: #888;
}
.due-alerts {
    float: right;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    background-color: #fff;
    width: 10%;
    padding: 5px;
    font-size: 14px;
    margin-right: 50px;
    margin-left: 3px;
    margin-top: 55px;
}
.due-calendar {
    margin-right: 10px;
    font-size: 14px !important;
    margin-bottom: 3px;
}
.due-alerts2 {
    float: right;
    border: 1px solid #ccc;
    width: 10%;
    padding: 5px;
    font-size: 14px;
    margin-left: 3px;
    margin-top: 55px;
}
.due-chart {
    margin-right: 10px;
    font-size: 14px !important;
    margin-bottom: 3px;
    color: blue;
}
.dash-subnav {
    float: left;
    width: 82%;
    max-width: 100%;
    margin-top: 40px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -1px;
    margin-left: 0px;
    padding-left: 0;
    margin-left: 20px;
}
.dash-subnav > li {
    float: left;
    list-style: none;
    margin-right: 16px;
    color: #fff;
    width: 9%;
    background-color: orange;
    border-radius: 40px;
    text-align: center;
    padding: 8px;
    font-size: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.dash-subnav > li >a {
    color: #fff;
    text-decoration: none;
}
.dash-subnav-bottom {
    float: left;
    border-bottom: 2px solid #eee;
    width: 79%;
    margin-left: 30px;
}
.dash-active {
    border-bottom: 2px solid green;
    float: left;
    width: 10%;
    position: relative;
}
.dash-banner {
    float: left;
    margin-left: 30px;
    width: 55%;
    margin-top: 30px;
    height: 140px;
}
.background-banner {
    float: left;
    height: 130px;
    position: absolute;
    width: 55%;
    z-index: -999999;
    border-radius: 10px;
}
.enterprise {
    color: #fff;
    font-weight: 500;
    margin-top: 55px;
    margin-left: 25px;
    float: left;
}
.prev-report {
    padding: 7px;
    font-size: 14px;
    width: 17%;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 50px;
    float: right;
    margin-top: 50px;
    margin-right: 30px;
}
.gen-report {
    padding: 7px;
    font-size: 14px;
    width: 17%;
    border: 1px solid blue;
    background-color: blue;
    color: #fff;
    border-radius: 50px;
    float: right;
    margin-top: 50px;
    margin-right: 30px;
}
.dash-operations {
    float: left;
    border: 1px solid #fff;
    width: 12%;
    margin-left: 30px;
    margin-top: 85px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
}
.operation-text {
    font-weight: 700;
    text-align: center;
    margin-top: 12px;
    font-size: 12px;
}
.operation-num {
    text-align: center;
    margin-top: 20px;
    font-size: 28px;
}
.dash-up {
    font-size: 8px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 14px;
}
.dash-app-card {
    float: left;
    border: 1px solid red;
    background-color: red;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 100%;
}
.danger {
    font-size: 30px;
    margin-left: 20px;
    margin-top: 15px;
    color: #fff;
    float: left ;
}
.due-details {
    float: left;
    margin-top: 13px;
    margin-left: 15px;
}
.live-alerts {
    margin-bottom: 0;
    color: #fff;
    font-size: 13px;
}
.alert-due {
    margin-bottom: 0;
    color: #fff;
    font-size: 10px;
}
.view-alerts-btn {
    color: #fff;
    font-size: 12px;
    border: 1px solid pink;
    background-color: pink;
    padding: 5px;
    width: 27%;
    font-weight: 500;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}
.app-card-inner {
    float: left;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 25%;
    margin-left: 30px;
    margin-top: 30px;
}
.fb-logo {
    font-size: 60px;
    margin-left: 35px;
    margin-top: 20px;
    color: blue;
    float: left;
}
.alerts-by {
    float: left;
    margin-top: 20px;
    margin-left: 25px;
}
.dash-company {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}
.dash-website {
    margin-bottom: 0;
    font-size: 12px;
    color: blue;
    font-weight: 500;
}
.dash-support {
    font-size: 10px;
    margin-top: 5px;
    font-weight: 500;
    color: #888;
}
.fb-logo2 {
    font-size: 60px;
    margin-left: 35px;
    margin-top: 20px;
    color: red;
    float: left;
}
.fb-logo3 {
    font-size: 60px;
    margin-left: 35px;
    margin-top: 20px;
    color: pink;
    float: left;
}
.dash-operations2 {
    float: left;
    border: 1px solid #fff;
    width: 54%;
    margin-left: 30px;
    margin-top: 85px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
    height: 425px;
}
.operation-text2 {
    font-weight: 700;
    text-align: center;
    margin-top: 12px;
    font-size: 22px;
    margin-top: 120px;
}
.operation-num2 {
    text-align: center;
    margin-top: 20px;
    font-size: 50px;
}
.dash-up2 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 14px;
}
.request-button2 {
    border: 1px solid orange;
    background-color: orange;
    border-radius: 40px;
    margin-top: 40px;
    width: 13%;
    margin-right: 10px;
    float: left;
    margin-left: 10px;
}
.request-button3 {
    border: 1px solid purple;
    background-color: purple;
    border-radius: 40px;
    margin-top: 40px;
    width: 13%;
    float: left;
    margin-left: 10px;
}
.request-button4 {
    border: 1px solid blue;
    background-color: blue;
    border-radius: 40px;
    margin-top: 40px;
    width: 13%;
    float: left;
    margin-left: 10px;
}
.request-button5 {
    border: 1px solid red;
    background-color: red;
    border-radius: 40px;
    margin-top: 40px;
    width: 13%;
    float: left;
    margin-left: 10px;
}
.dash-amount-inner {
    float: left;
    margin-top: 10px;
    text-align: center;
}
.dash-icon2-inner {
    float: right;
    border: 1px solid orange;
    width: 30%;
    background-color: orange;
    height: 50px;
}
.money-analytics-inner {
    margin-bottom: 0;
    font-size: 15px;
    color: green;
    font-weight: 700;
    margin-left: 15px;
}
.money-analytics-detail-inner {
    margin-bottom: 0;
    font-size: 11px;
    margin-left: 18px !important;
}
.dash-figure {
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-top: 12px;
}
.dash-icon3-inner {
    float: right;
    border: 1px solid brown;
    width: 30%;
    background-color: brown;
    height: 50px;
}
.dash-icon4-inner {
    float: right;
    border: 1px solid palevioletred;
    width: 30%;
    background-color: palevioletred;
    height: 50px;
}
.dashboard-att-inner {
    float: left;
    width: 19% !important;
    margin-left: 23px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    height: 50px;
    margin-right: 23px;
}
.graph-section {
    margin-left: 23px;
    width: 45%;
}
.graph-section-inner {
    float: left;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 45%;
    margin-top: 40px;
    margin-left: 23px;
}
.calender-section-inner {
    float: left;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 45%;
    margin-top: 40px;
    margin-right: 23px;
}
.calender-inner {
    border: none;
    width: 100%;
    height: 420px ;
}