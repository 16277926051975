.video-card2 {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  float: left;
  width: 100%;
  padding-bottom: 60px;
  margin-left: 0px;
}
.video-detail-card {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin-left: 20px;
  float: left;
  width: 55% !important;
  padding-bottom: 60px;
  margin-top: 20px;
}
.tab-nav {
  float: left;
  margin-left: 20px;
  width: 11%;
  max-width: 100%;
  cursor: pointer;
}
.tab1 {
  /* border: 1px solid #3FAD72;
background-color: #3FAD72;
float: left;
border-radius: 21px;
padding: 7px;
width: 100%;
text-align: center;
color: #fff;
margin-top: 30px; */
  padding: 15px !important;
  background-color: rgba(42, 143, 86, 1) !important;
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 30px;
  width: 108%;
}
.tab1 > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.active-tab {
  background-color: #fff !important;
  border: 1px solid rgba(42, 143, 86, 1);
}
.active-tab > p {
  color: rgba(42, 143, 86, 1);
}
.tab1-border {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 81.5%;
  margin-left: 20px;
  position: relative;
  bottom: 6px;
}
.nav-payment {
  font-size: 15px;
  padding-bottom: 2px;
}
.edit-btn {
  float: right;
  font-size: 22px;
  color: rgba(42, 143, 86, 1);
  margin-right: 20px;
  margin-top: 6px;
}
