.add-expense{
    margin-top: 60px;
}
.data-form {
    float: left;
    width: 85%;
}
.data-fields {
    float: left;
    width: 40%;
    margin-left: 25px;
    margin-top: 50px;
}
.data-fields > p {
    margin-bottom: 5px;
    font-weight: 500;
}
.data-fields > input {
    width: 100%;
    border: 1px solid #eee;
    padding: 3px;
    padding-left: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.data-fields2 {
    float: left;
    width: 40%;
    margin-left: 50px;
    margin-top: 50px;
}
.data-fields2 > p {
    margin-bottom: 5px;
    font-weight: 500;
}
.data-fields2 > input {
    width: 100%;
    border: 1px solid #eee;
    padding: 3px;
    padding-left: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.data-save-btn {
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    padding: 10px;
    width: 10%;
    border-radius: 5px;
    float: right;
    margin-right: 100px;
    margin-top: 270px;
}