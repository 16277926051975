.invoice-main {
  margin-top: 80px;
}
.invoice-box {
  border: 5px solid #000;
}
.invoice-logo {
  padding: 25px;
  text-align: center;
  border-bottom: 5px solid #000;
}
.invoice-info1 {
  margin-top: 10px;
}
.info-box h1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.add-info1 {
  margin-left: 5px;
  margin-right: 5px;
}

.info-box p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}
.info-box {
  border-bottom: 2px solid #000;
  padding: 9px;
}
.box1 {
  width: 40%;
  border-right: 2px solid #000;
}
.box2 {
  width: 60%;
}
.invoice-info-code {
  padding: 15px;
  text-align: center;
  border-bottom: 2px solid #000;
}
.invoice-info-code .qr-number h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
  align-self: center;
}
.invoice-info-code .qr-number span {
  font-size: 25px;
  line-height: 33px;
  font-weight: 400;
}
.qr-number {
  display: flex;
  width: fit-content;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.invoice-info-code img {
  width: 70%;
}
.box-flex {
  width: 50%;
}
.box-flex p {
  margin-bottom: 0;
}
.invoice-info-code-new {
  border-border: unset;
}
.info-box-last {
  text-align: left;
  margin-top: 12px;
  padding: 0;
  border-bottom: unset;
}
