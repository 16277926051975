#track-pop > .MuiPopover-paper {
  width: 84%;
  position: absolute;
  top: -20px !important;
  left: 94px !important;
  z-index: 9999;
}
.track-order {
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
  margin-top: 5px;
}
.track-code {
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
  margin-top: 30px;
}
.order-item {
  float: left;
  border-radius: 5px;
  background-color: #3fad721c;
  padding: 7px;
  width: 47%;
  margin-top: 20px;
  color: #59c78d;
  margin-left: 26px;
}
.item-count {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
}
.order-item2 {
  float: left;
  border-radius: 5px;
  background-color: #3fad721c;
  padding: 7px;
  width: 47%;
  margin-top: 20px;
  color: #59c78d;
  margin-left: 15px;
}
.del-status {
  border-radius: 5px;
  width: 100%;
  display: block;
  margin: 0 auto;
  border: 1px solid white;
  background-color: white;
  color: #000;
}
.del-cancel {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: block;
  margin: 0 auto;
  border: 1px solid red;
  background-color: red;
  color: #fff;
  margin-top: 10px;
}
.del-help {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: block;
  margin: 0 auto;
  border: 1px solid #59c78d;
  background-color: #59c78d;
  color: #fff;
  margin-top: 10px;
}
.order-timeline {
  display: inline !important;
}
#track-pop > .MuiPopover-paper > .makeStyles-typography-1 {
  margin-bottom: 50px;
}
.g-map {
  width: 50%;
    display: block;
    margin: 0 auto;
}
.g-map > div {
  height: 55vh;
}
.close-pop {
  float: right;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
}