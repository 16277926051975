.makeStyles-root-1 {
    float: left;
    margin-top: 30px;
    margin-left: 35px;
    width: 80%;
}
.PrivateTabIndicator-colorSecondary-4 {
    background-color: #fff !important;
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}
.MuiAppBar-colorPrimary {
    background-color: #fff !important;
}
.id-card {
    border: 1px solid #eee;
    float: left;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #eee;
}
.MuiBox-root-7 {
    padding:0;
}
.history-content > li {
    list-style: none;
    float: left;
    margin-left: 38px;
    margin-right: 38px;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.history-content {
    margin: 0;
    float: left;
    padding: 0;
}
.history-content > li > select {
    border: none;
    background-color: #eee;
}
.history-content >li >p {
    margin-bottom: 0;
}
.history-image {
    width: 4%;
    float: left;
    margin-right: 0px;
    margin-left: 35px;
    margin-top: 5px;
 }
 .id-card > p {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 31px;
    float: left;
    margin-top: 15px;
 }
 .user-name {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 10px;
 }
 .user-cash {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 62px;
 }
 .product-eta {
    margin-left: 104px;
    float: left;
    margin-top: 15px;
 }
 .clock {
    float: left;
 }
 .eta {
    float: left;
    font-size: 12px;
    margin-left: 8px;
 }
 .edit-btn-verify {
    float: right;
    font-size: 22px;
    position: relative;
    right: 68px;
    top: 3px;
    color: rgba(42, 143, 86, 1);
 }
 .delivery {
    font-size: 12px;
    margin-left: 100px;
    margin-top: 15px;
    color: red;
    float: left;
 }
 .delivery2 {
    font-size: 12px;
    margin-left: 60px;
    margin-top: 15px;
    color: goldenrod;
    float: left;
 }
 .delivery3 {
    font-size: 12px;
    margin-left: 60px;
    margin-top: 15px;
    float: left;
 }
 .more-options {
    float: left;
    margin-top: 15px;
    margin-left: 75px;
    font-size: 20px;
 }
 .id-card:hover {
     background-color: #fff;
 }
 .makeStyles-root-1 {
     margin-top: 0;
     margin-left: 0;
     width: 100%;
 }
 .profile-name {
    border: 1px solid #ccc;
    padding: 5px;
    width: 48%;
 }
 .profile-email {
    border: 1px solid #ccc;
    padding: 5px;
    width: 48%;
    margin-left: 20px;
 }
 .profile-birth {
    border: 1px solid #ccc;
    padding: 5px;
    width: 31%;
    margin-top: 20px;
 }
 .gender-select {
    padding: 7px;
    width: 30.5%;
    margin-left: 20px;
    border: 1px solid #ccc;
 }
 .profile-phone {
    border: 1px solid #ccc;
    padding: 5px;
    width: 31%;
    margin-top: 20px;
    margin-left: 20px;
 }
 .profile-address {
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    margin-top: 20px;
 }
 .profile-about {
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    margin-top: 20px;
    height: 80px;
 }
 .profile-save {
    color: #fff;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    padding: 10px;
    width: 14%;
    border-radius: 4px;
    margin-right: 10px;
    float: right;
 }
 .profile-reset {
    color: #fff;
    border: 1px solid palevioletred;
    background-color: palevioletred;
    padding: 10px;
    width: 14%;
    border-radius: 4px;
    float: right;
 }
 .makeStyles-root-2 {
    float: left;
    width: 85%;
    margin-top: 20px;
 }
 .user-videos-tab {
    float: left;
    width: 85%;
    margin-top: 30px;
 }
 /* .MuiButtonBase-root { */
     /* padding: 0 !important;
     background-color: #2A8F56 !important;
     margin-left: 10px !important;
     border-radius: 20px !important;
 } */
 /* .MuiPaper-root {
    border-bottom: 8px solid #2A8F56;
 } */
 .video-card {
     background-color: #fff;
     box-shadow: 0 0 10px rgb(0 0 0 / 15%);
     border-radius: 8px;
     float: left;
     width: 30%;
     padding-bottom: 60px;
 }
 .video-views {
    float: left;
    font-size: 11px;
    color: #2A8F56;
    margin-top: 15px;
    margin-left: 10px;
 }
 .video-points {
    float: right;
    font-size: 11px;
    color: #2A8F56;
    margin-top: 15px;
    margin-right: 10px;
 }
 .star-points {
     font-size: 15px;
 }
 .video-user {
    float: left;
    width: 45%;
    margin-left: 85px;
 }
 .videouser-info {
    float: left;
    text-align: center;
    width: 100%;
    margin-top: 10px;
 }
 .videouser-info > h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
 }
 .videouser-info > p {
    font-size: 9px;
    color: #3DC162;
    margin-top: 3px;
 }
 .name-details {
    float: left;
    margin-left: 45px;
 }
 .first-name {
    float: left;
    font-size: 11px;
    font-weight: 600;
    color: #838181;
    margin-bottom: 2px;
 }
 .user-name-video {
    float: left;
    font-size: 11px;
    font-weight: 500;
    color: #0F0F0F;
    margin-left: 78px;
    margin-bottom: 2px;
 }
 .user-name-verify {
    float: left;
    font-size: 11px;
    font-weight: 500;
    color: #0F0F0F;
    margin-left: 80px;
    margin-bottom: 2px;
 }
 .user-name-video3 {
    float: left;
    font-size: 11px;
    font-weight: 500;
    color: #0F0F0F;
    margin-left: 92px;
    margin-bottom: 2px;
 }
 .user-name-video4 {
    float: left;
    font-size: 11px;
    font-weight: 500;
    color: #0F0F0F;
    margin-left: 105px;
    margin-bottom: 2px;
 }
 .user-name-video5 {
    float: left;
    font-size: 11px;
    font-weight: 500;
    color: #0F0F0F;
    margin-left: 37px;
    margin-bottom: 2px;
 }
 .user-activities {
    float: left;
    width: 40%;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #48C9AE;
    background-color: #48C9AE;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: 700;
 }
 .user-contact {
    float: left;
    width: 40%;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #4F85FD;
    background-color: #4F85FD;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: 700;
 }
 .user-suspend {
    float: left;
    width: 40%;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #F7BF69;
    background-color: #F7BF69;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: 700;
 }
 .user-date {
    float: left;
    width: 40%;
    margin-left: 20px;
    border: 1px solid #F7BF69;
    background-color: #F7BF69;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: 700;
    padding-bottom: 2px !important;
    font-size: 13px;
    padding-top: 3px !important;
    margin-bottom: 30px;
 }
 
.date-border {
    border-bottom: 1px solid #fff;
    width: 80%;
    display: block;
    margin: 0 auto;
}
.user-delete {
    width: 87%;
    display: block;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #E056A4;
    background-color: #E056A4;
    color: #fff;
    font-weight: 700;
    border-radius: 3px;
}
.video-border {
    border-bottom: 1px solid #eee;
    float: left;
    width: 100%;
    margin-top: 40px;
}
.user-followers {
    float: left;
}
.followers {
    float: left;
    font-size: 11px;
    font-weight: 600;
    color: #838181;
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 0;
}
.followers-num {
    float: left;
    font-size: 11px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 80px !important;
    color: #0F0F0F;
    margin-bottom: 0;
}
.user-verification {
    width: 87%;
    display: block;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #4F85FD;
    background-color: #4F85FD;
    color: #fff;
    font-weight: 700;
    border-radius: 3px;
}
.user-followers-store {
    float: left;
    margin-bottom: 80px;
}
.video-detail-card {
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
    margin-left: 20px;
    float: left;
    width: 68%;
    padding-bottom: 60px;
}
.video1-border {
    float: left;
    border: 1px solid #eee;
    margin-left: 10px;
    margin-top: 20px;
    width: 30%;
    padding-bottom: 5px;
}
.video1-image {
    float: left;
    width: 100%;
}
.video-length-verify {
    float: left;
    border: 1px solid #888;
    background-color: #000000bf;
    width: 16%;
    border-radius: 4px;
    position: relative;
    bottom: 178px;
    left: 6px;
}
.video-length-verify > span {
    float: left;
    font-size: 12px;
    font-weight: 400;
    padding-top: 2px;
    padding-left: 7px;
    color: #fff;
}
.video-setting-verify{
    float: left;
    border: 1px solid green;
    border-radius: 50px;
    background-color: green;
    color: #fff;
    font-size: 28px;
    padding: 2px;
    position: relative;
    left: 145px;
    bottom: 34px;
}
.setting-gear {
    float: left;
}
.video-title-verify {
    float: left;
    font-size: 16px;
    font-weight: 700;
    margin-left: -36px;
    margin-bottom: 0;
}
.video-date {
    float: left;
    margin-left: -108px;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 300;
    color: #1616165e;
}
.video-desc-verify {
    float: left;
    width: 46% !important;
}
.video-hearts {
    float: left;
    margin-left: 5px;
    margin-top: -5px;
}
.hearts {
    float: left;
    font-size: 13px;
    color: #F85050;
}
.num-hearts {
    font-size: 7px;
    font-weight: 300;
    color: #9B9595;
    margin-top: 15px;
}
.dislike {
    float: left;
    font-size: 13px;
    color: #438AFE;
}
.video-dislike {
    float: left;
    margin-left: 25px;
    margin-top: -5px;
}
.chat {
    float: left;
    font-size: 13px;
    color: #2A8F56;
}
.view-btn {
    font-size: 16px;
    font-weight: 400;
    width: 83px;
    height: 33px;
    border: 1px solid #438AFE;
    background-color: #438AFE;
    color: #fff;
    border-radius: 4px;
}
.view-details {
    float: left;
    position: relative;
    bottom: 34px;
    left: 20px;
}
.view-delete {
    float: left;
    position: absolute;
    top: 455px;
    right: 508px;
}
.delete-btn {
    font-size: 16px;
    font-weight: 400;
    width: 83px;
    height: 33px;
    border: 1px solid #F85050;
    background-color: #F85050;
    color: #fff;
    border-radius: 4px;
}
.view-delete2 {
    float: left;
    position: absolute;
    top: 455px;
    right: 289px;
}
.video2-border {
    float: left;
    border: 1px solid #eee;
    margin-left: 10px;
    margin-top: 20px;
    width: 30%;
    padding-bottom: 42px;

}
.video3-border {
    float: left;
    margin-left: 10px;
    margin-top: 20px;
    width: 30%;
    padding-bottom: 42px;

}
.video4-border {
    float: left;
    margin-left: 10px;
    margin-top: 20px;
    width: 30%;
    padding-bottom: 42px;
    margin-top: 30px;
}
.video-setting2 {
    float: left;
    border: 1px solid green;
    border-radius: 50px;
    background-color: green;
    color: #fff;
    font-size: 28px;
    padding: 2px;
    position: relative;
    left: 173px;
    bottom: 34px;
}
.id-verification > h5 {
    font-weight: 700;
    margin-top: 20px;
    margin-left: 15px;
    
}
.id-verification {
    float: left;
    width: 50%;
    padding-bottom: 200px;
}
.fileContainer {
    width: 70%;
    height: 130px;
    margin-left: 29px;
    border: 1px solid #eee;
    border-radius: 10px;
}
.image-upload {
    float: left;
    width: 100%;
}
.border-right {
    border-right: 1px solid #ccc;
    float: left;
    width: 34%;
    height: 425px;
    position: absolute;
    left: 433px;
    margin-top: 48px;
}
.video-user2 {
    float: left;
    width: 25%;
    margin-left: 85px;
    margin-top: 30px;
}
.selfie {
    width: 4%;
    position: relative;
    top: 115px !important;
    right: 48px !important;
}
.camera {
    float: left;
    margin-top: 30px;
    margin-left: 30px;
}
.verify-btns {
    float: left;
    padding-left: 0;
    position: relative;
    right: 161px;
    top: 95px;
}
.verify-btns > li {
    list-style: none;
    float: left;
    margin-right: 30px;
}
.verify-btns > li > button {
    float: left;
    width: 122%;
}
.approve {
    border: 1px solid rgba(42, 143, 86, 1);
    background-color: rgba(42, 143, 86, 1);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.deny {
    border: 1px solid rgba(248, 80, 80, 1);
    background-color: rgba(248, 80, 80, 1);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.need {
    border: 1px solid rgba(67, 138, 254, 1);
    background-color: rgba(67, 138, 254, 1);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.full-name {
    float: left;
    margin-left: 98px;
    margin-top: 20px;
}