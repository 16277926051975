.product-upload-btn {
  padding: 45px;
  width: 107%;
  border: 2px solid #3fad72;
  background-color: #fff;
  color: #3fad72;
  font-size: 25px;
  position: relative;
}

.product-upload-btn2 {
  padding: 45px;
  width: 50%;
  border: 2px solid #3fad72;
  background-color: #fff;
  color: #3fad72;
  font-size: 25px;
  position: relative;
}
.upload__image-wrapper {
  float: left;
  height: 180px;
}
.image-item {
  position: relative;
  bottom: 156px;
  right: -5px;
}
.product-image-uploaded {
  width: 120px;
  height: 131px;
  padding: 2px;
}
.product-image-remove {
  border: 1px solid red;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
}

.fere {
  font-size: 25px;
  color: #3fad72;
  cursor: pointer;
  position: relative;
}

.fere2 {
  font-size: 25px;
  color: "transparent";
  cursor: pointer;
  position: relative;
}
