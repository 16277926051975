.searchList{
    margin-top: 60px;
}
.order-show {
    float: left;
    margin-top: 20px;
    margin-left: 20px;
}
.order-show > p {
    float: left;
    margin-right: 5px;
}
.order-show > select {
    padding-left: 2px;
    float: left;
    width: 47px;
    border: 1px solid rgba(243, 243, 243, 1);
    background-color: rgba(243, 243, 243, 1);
}
.order-filter {
    float: left;
    margin-top: 20px;
    margin-left: 65px;
    padding-left: 2px;
    float: left;
    width: 85px;
    border: 1px solid rgba(243, 243, 243, 1);
    background-color: rgba(243, 243, 243, 1);
}
.order-user {
    float: right;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 20px;
}
.order-list {
    float: left;
    padding-top: 12px;
}
.order-list > li {
    list-style: none;
    float: left;
    padding-right: 92px !important;
    color: #fff;
}
.order-actions {
    float: left;
    background-color: rgba(42, 143, 86, 1);
    margin-top: 30px;
    
}
.product {
    float: left;
    margin-left: 36px;
    margin-top: 22px;
}
.product-close {
    float: left;
    font-size: 10px;
    font-weight: 700;
    color: rgba(255, 62, 62, 1);
    position: relative;
    top: 60px;
    left: 0px !important;
}
.user-order {
    float: left;
    width: 6% !important;
    margin-top: 22px;
    margin-left: 45px !important;
}
.user-order > img {
    float: left;
    width: 100%;
}
.user-order > p {
    margin-bottom: 0;
    margin-top: 10px;
    float: left;
    margin-left: 0px;
    font-size: 12px;
    width: 100%;
    text-align: center;
}
.order-status {
    float: left;
    margin-left: 80px !important;
    font-size: 14px;
    font-weight: 700;
    margin-top: 53px;
    width: 10% !important;
    max-width: 10% !important;
}
.order-quantity {
    float: left;
    margin-left: 130px;
    font-size: 14px;
    font-weight: 700;
    margin-top: 53px;
}
.order-users {
    float: left;
    margin-left: 180px !important;
    font-size: 14px;
    font-weight: 700;
    margin-top: 53px;
}
.orderlist-btns {
    float: left;
    margin-left: 75px !important;
    margin-top: 45px;
}
.order1-btn {
    border: 2px solid rgba(42, 143, 86, 1);
    background: transparent;
    color: rgba(42, 143, 86, 1);
    padding: 6px;
    padding-bottom: 8px;
}
.order1-btn2 {
    margin-left: 15px;
    border: 2px solid rgba(67, 138, 254, 1);
    background: transparent;
    color: rgba(67, 138, 254, 1);
    padding: 6px;
    width: 74px;
    padding-bottom: 8px;
}
.border-list1 {
    border-bottom: 1px solid #ccc;
    float: left;
    width: 96% !important;
    margin-left: 21px;
}
.prev-count {
    border: 1px solid rgba(243, 243, 243, 1);
    float: left;
    width: 18%;
    padding-left: 9px;
    background-color: rgba(243, 243, 243, 1);
    margin-left: 10px;
    margin-right: 10px;
}
.prev-nxt {
    float: right;
    margin-left: 23px;
    width: 13%;
    margin-top: 180px;
}
.prev-nxt > p {
    float: left;
}
.product-close2 {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: rgba(255, 62, 62, 1);
    position: absolute;
    top: 494px;
    left: 199px;
}
.product-close3 {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: rgba(255, 62, 62, 1);
    position: absolute;
    top: 705px;
    left: 199px;
}
.product-list {
    float: left;
    width: 85%;
    max-width: 100%;
}
.phone-image {
    width: 50%;
}
.product-phone {
    font-size: 12px;
    margin-top: 5px;
}