#password-modal {
  max-width: 60%;
}

#password-modal>.modal-content>.modal-header {
  border-bottom: none;
}

#password-modal>.modal-content>.modal-body {
  padding: 0;
}

.pass-change-button2 {
  border: 1px solid #3fad72;
  width: 52%;
  max-width: 100%;
  border-radius: 40px;
  background-color: #3fad72;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.register-card2 {
  background-color: #ffffff;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
  height: 380px !important;
  width: 95%;
}

.register-field2 {
  border: 3px solid #3fad72;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 5px;
}

.login .wrapper {
  background-color: white;
  width: 310px;
  height: 440px;
  border-radius: 15px;
  box-shadow: 0px 2px 0px lightgray;
  position: absolute;
  right: 15%;
  top: 110px;
  z-index: 50;
  animation: form 2s ease-in-out;
}

.login .wrapper img {
  width: 100%;
  position: absolute;
}

.login .wrapper .error {
  color: #c83737;
  position: absolute;
  top: 13px;
}

.login .wrapper form {
  margin-top: -116px;
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
}

@keyframes form {
  0% {
    top: 0;
    opacity: 0;
  }

  100% {
    top: 110px;
    opacity: 1;
  }
}

.login .wrapper h2 {
  margin-top: 230px;
  color: #3fad72;
  font-weight: bold;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 25px;
}

.login .wrapper label {
  color: #3fad72;
  position: absolute;
}

.login .wrapper input {
  width: 91%;
  padding: 5px 10px;
  margin-top: 7px;
  margin-bottom: 10px;
  border: 1px solid #bed7fc;
  font-size: 16px;
  background-color: #eaf4ff;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 3px 3px lightgray;
  transition: 0.5s;
  position: absolute;
}

.login .wrapper input:focus {
  transform: scale(1.1);
  background-color: white;
}

.login .wrapper #username {
  top: 70px;
}

.login .wrapper #password {
  top: 140px;
}

.login .wrapper button {
  width: 260px;
  font-size: 16px;
  background-image: linear-gradient(#5daafd, #3fad72);
  border: none;
  color: white;
  padding: 7px;
  border-radius: 3px;
  box-shadow: 0px 3px 20px lightgrey;
  transition: 0.5s;
  position: absolute;
  top: 200px;
  animation: button 1.5s ease-in;
}

@keyframes button {
  0% {
    top: 0;
    opacity: 0;
  }

  50% {
    top: 220px;
  }

  100% {
    opacity: 1;
    top: 200px;
  }
}

.login .wrapper button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.login .left .background {
  position: fixed;
  width: auto;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.login .left .person {
  z-index: 5;
  position: fixed;
  height: 40%;
  top: 45%;
  left: 15%;
}

.login .left .screen1 {
  top: 15%;
  left: 23%;
  z-index: 2;
  height: 30%;
  position: fixed;
  animation: screen1 2s ease-in-out infinite;
}

@keyframes screen1 {
  0% {
    top: 15%;
  }

  50% {
    top: 10%;
  }

  100% {
    top: 15%;
  }
}

.login .left .screen2 {
  top: 20%;
  left: 13%;
  z-index: 2;
  height: 15%;
  position: fixed;
  animation: screen2 1.5s ease-in-out infinite;
}

@keyframes screen2 {
  0% {
    top: 25%;
  }

  50% {
    top: 30%;
  }

  100% {
    top: 25%;
  }
}

.login .left .screen3 {
  top: 40%;
  left: 25%;
  z-index: 2;
  height: 25%;
  position: fixed;
  animation: screen3 2s ease-in-out infinite;
}

@keyframes screen3 {
  0% {
    top: 43%;
  }

  50% {
    top: 48%;
  }

  100% {
    top: 43%;
  }
}

.login .left .icons {
  position: fixed;
  z-index: 2;
  height: 70%;
  top: 8%;
  left: 6%;
  animation: icons 2s ease-in-out;
}

@keyframes icons {
  0% {
    top: -50%;
    opacity: 0;
  }

  75% {
    top: 15%;
  }

  100% {
    top: 8%;
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .login {
    display: flex;
    justify-content: center;
  }

  .login .wrapper {
    position: relative;
    left: auto;
    right: auto;
  }
}

/* updated design  */
.admin-login {
  width: 100%;
  height: 100vh;
  background: var(--unnamed-color-04984a) 0% 0% no-repeat padding-box;
  background: #04984A 0% 0% no-repeat padding-box;
  opacity: 1;
}

.admin-login .login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 980px;
  height: 555px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.admin-login .login-container .login-layout {
  height: inherit;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 80px #00000040;
  border: 1px solid #B1B1B1;
  border-radius: 10px;
}

.admin-login .login-container .login-canvas {
  border-right: 1px solid #B1B1B1;
  text-align: center;
}

.admin-login .login-container .login-canvas>.d-flex {
  margin: 29px 0 33px 0;
}

.admin-login .login-container .title {
  font-size: 25px;
  line-height: 33px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
  margin: 0px 0 27px 0;
}

.admin-login .login-canvas>.form-label {
  color: #020202 !important;
}

.admin-login .login-layout .login-btn {
  margin: 40px 0 28px 0;
}

.forgetPassword {
  text-align: center;
  color: #04984A;
  cursor: pointer;
}

.pass-field-new {
  position: relative;
}
.eyeIcon {
  position: absolute;
  right: 10px;
  top: 37px;
  cursor: pointer;
}

.forgetPass-img {
  margin-top: 28%;
  transform: translateY(-28%);
}

.admin-login .login-layout .complete-profile {
  height: 531px;
  overflow-y: auto;
}