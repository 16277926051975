.order-history {
  float: left;
  margin-left: 20px;
  margin-top: 30px;
}
.history-section {
  float: left;
  margin-left: 20px;
  border: 1px solid #ccc;
  width: 82%;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.history-section > ul {
  padding: 0;
  margin-bottom: 0;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.history-section > ul > li {
  float: left;
  list-style: none;
  margin-right: 105px;
  margin-left: 60px;
}
.history-bottom {
  float: left;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.iphone-history {
  float: left;
  width: 100%;
}
.history-product {
  width: 8%;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
}
.history-details {
  padding: 0;
  margin-bottom: 0;
  float: left;
  margin-top: 38px;
  margin-left: 20px;
}
.history-details > li {
  float: left;
  list-style: none;
  font-size: 14px;
}
.history-name {
  float: left;
  width: 106px;
  font-size: 13px;
  margin-top: 32px;
  margin-left: 15px;
}
.history-date {
  float: left;
  font-size: 13px;
  margin-top: 33px;
  margin-left: 0px;
  width: 125px;
}
.history-num {
  float: left;
  font-size: 13px;
  margin-top: 32px;
  margin-left: 148px;
  width: 38px;
}
.history-total {
  width: 75px;
  float: left;
  font-size: 13px;
  margin-top: 32px;
  margin-left: 175px;
}
.history-detail {
  float: left;
  width: 160px;
  font-size: 13px;
  margin-top: 32px;
  margin-left: 62px;
  text-align: center;
}
