.chat-intro2 {
    border: 1px solid lightgrey;
    background-color: #f5f9fc;
    float: left;
    border-radius: 10px;
    width: 74%;
    margin-left: 70px;
    margin-top: 35px;
}
.user-chat-image2 {
    width: 15%;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
}
.chat-intro2 > h6 {
    text-align: center;
    margin-bottom: 0;
}
.chat-intro2 > p {
    text-align: center;
    font-size: 10px;
    margin-top: 3px;
    color: #888;
    margin-bottom: 0;
}