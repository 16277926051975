.sale-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}
.sale-card > p {
  font-size: 12px;
  margin-bottom: 0;

  margin-top: 15px;
}

.history-contentSale {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;

  margin-right: 10px;
}

.history-contentSale > li {
  list-style: none;

  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.history-imageSale {
  width: 30%;

  margin-right: 10px;
  /* margin-right: 0px; */
  /* margin-left: 35px; */
  margin-top: 5px;
}
.user-nameSale {
  font-size: 12px;
  margin-top: 15px;
}

.deliverySale {
  font-size: 12px;
  width: 120px;
  margin-top: 15px;
  color: red;
  margin-bottom: 0px;
}
.delivery2Sale {
  font-size: 12px;
  width: 100px;
  margin-bottom: 0px;
  margin-top: 15px;
  color: goldenrod;
}
.delivery3Sale {
  font-size: 12px;
  width: 50px;
  margin-top: 15px;
}
