.payment-heading {
  margin-left: 50px;
  margin-top: 50px;
}
.connect-sect {
  float: left;
  margin-right: 50px;
  margin-top: 50px;
}
.connect-sect > svg {
  float: left;
  font-size: 25px;
  margin-right: 5px;
  margin-top: 3px;
}
.connect-sect > h4 {
  float: left;
}
.card1-details {
  width: 100%;
  margin-left: 50px;
}
.card1-details > img {
  width: 10%;
  float: left;
  border-radius: 10px;
}
.card-num-detail {
  float: left;
  margin-left: 12px;
  margin-top: 10px;
  width: 100px;
}
.card-num-detail > p {
  margin-bottom: 0;
}
.pay-select {
  font-size: 32px;
  margin-top: 13px;
  color: green;
  margin-left: 180px;
  float: left;
}
.pay-select2 {
  font-size: 32px;
  margin-top: 13px;
  color: green;
  width: 100px;
  float: left;
}
.pay-edit {
  float: left;
  font-size: 20px;
  margin-top: 12px;
  font-weight: 600;
  margin-left: 50px;
  color: green;
}
.pay-bb {
  border-bottom: 1px solid #ccc;
  width: 50%;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}
.default-toggle {
  float: left;
  margin-top: 13px;
  margin-left: 70px;
}
.default-toggle > p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.process-payment {
  border: 1px solid green;
    background-color: green;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}