.tabs-bottom-usersection {
    float: left;
    border-bottom: 10px solid rgba(42, 143, 86, 1);
    width: 85%;
    position: relative;
    top: 94px;
}
.usersearch-icon {
    position: relative;
    left: 25px;
    bottom: 1px;
}
.input-search {
    padding-left: 32px;
    padding-bottom: 4px;
    border: 1px solid #eee;
    background-color: #eee;
}
.search-user {
    float: left;
    /* margin-top: 20px;
    margin-left: 10px; */
}
.not-dot {
    color: #2A8F56;
    font-size: 29px;
    position: relative;
    left: 30px;
    bottom: 5px;
}
.message-icon {
    color: #AAAAAA;
}
.not-dot2 {
    color: #2A8F56;
    font-size: 29px;
    position: relative;
    left: 27px;
    bottom: 5px;
}
.not-panel > li {
    list-style: none;
    float: right;
}
.not-panel {
    float: right;
}
.not-details {
    float: left;
    margin-top: 20px;
    margin-left: 838px;
}
.not-image {
    float: left;
    width: 15%;
}
.not-image-user {
    float: left;
    width: 23%;
    margin-top: 11px;
    margin-left: 24px;
    border-radius:50px;
}
.not-image-comments {
    float: left;
    width: 100%;
}
.not-image-user-comments {
    float: left;
    width: 10%;
    margin-top: 11px;
    margin-left: 24px;
}
.not-image-text {
    font-size: 13px;
    margin-top: 24px;
    margin-left: 80px;
}
.hamburger {
    color: #AAAAAA;
    margin-top: 26px;
    margin-left: 12px;
}