.search-bar3 {
  width: 65%;
  padding: 13px;
  padding-left: 55px;
  border-radius: 6px;
  margin-top: 20px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.search-icon3 {
  font-size: 24px;
  color: green;
  position: relative;
  top: 0px;
  left: 40px;
}

.history-search {
  float: left;
}

.history-section {
  float: left;
  width: 80%;
}

.history-section2 {
  float: left;
  margin-left: 20px;
  border: 1px solid #ccc;
  width: 60%;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.history-section > h4 {
  float: left;
  margin-left: 35px;
  margin-top: 28px;
  font-weight: 700;
}

.history-date2 {
  float: left;
  font-size: 13px;
  margin-left: 28px;
}

.history-content1 {
  margin: 0;
  float: left;
  padding: 0;
}

.history-content1 > li > select {
  border: none;
  background-color: #eee;
}

.tabs-bottom-history {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 83%;
  position: absolute;
  top: 201px;
  z-index: 9999;
  left: 195px;
}

.order-date {
  margin-top: 28px;
  margin-left: 16px;
  float: left;
}

.order-to {
  float: left;
  position: relative;
  top: 32px;
  left: 14px;
}

.order-date2 {
  margin-top: 28px;
  margin-left: 30px;
  margin-bottom: 28px;
}

.tabhistory {
  padding: 15px !important;
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 30px;
  width: 108%;
}

.tabhistory2 {
  padding: 5px !important;
  /* margin-left: 10px !important; */
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 10px;
  width: 108%;
}

.tabhistory > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.tabhistory2 > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.history-card {
  border: 1px solid #eee;
  float: left;
  width: 82%;
  margin-left: 15px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #eee;
  max-width: 100%;
}

.history-content2 {
  margin: 0;
  display: flex;
  justify-content: space-between;

  padding: 0;
  margin-bottom: 5px;
}

.history-content1 > li {
  list-style: none;

  margin-left: 34px;
  margin-right: 34px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.history-content2 > li {
  list-style: none;
  float: left;
  margin-left: 8px;
  width: 10%;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.history-content2 > li > p {
  margin-left: 0 !important;
}

.history-card2 {
  border: 1px solid #eee;
  float: left;
  width: 60%;
  margin-left: 15px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #eee;
  max-width: 100%;
}

.ordertabborder {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 80.5%;
  margin-left: 20px;
  position: relative;
  bottom: 6px;
}

.tab-navFF {
  float: left;
  margin-left: 20px !important;
  width: 10%;
  cursor: pointer;
}

.newUi {
  float: left;
  margin-left: 20px;
  border: 1px solid #ccc;
  width: 20%;
  height: 830px;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 50px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.calling-section {
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
  width: 22%;
  position: absolute;
  right: 50px;
  top: 85px;
}

.rec {
  width: 25%;
  display: block;
  margin: 0 auto;
}

.caller-user {
  float: left;
  width: 92%;
  margin: 5px;
}

.caller-user > img {
  width: 20%;
  float: left;
}

.history-section2 > h5 {
  margin-bottom: 20;
}

.caller-user > h5 {
  float: left;
  margin-left: 5;
  margin-top: -1px;
  font-size: 13px;
  margin-bottom: 0;
}

.call-icons {
  float: left;
  width: 78%;
}

.call-icon2 {
  font-size: 55px;
  color: #fff;
  margin-top: 0px;
  margin-left: 19px;
  width: 16%;
  float: left;
}

.reject-icon {
  width: 27%;
  margin-left: 0px;
  margin-top: 11px;
  margin-bottom: 13px;
}

.not-call {
  font-size: 22px;
  margin-left: 0px;
  margin-right: 12px;
  width: 15%;
}

.not-msg {
  font-size: 20px;
  width: 15%;
  margin-right: 12px;
}

.accept-icon {
  width: 16%;

  margin-top: 14px;
  margin-bottom: 15px;
}

.call-section {
  float: right;
  width: 96%;
  border: 1px solid gold;
  background-color: gold;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 15px;
}

.call-icon {
  font-size: 55px;
  color: #fff;
  margin-top: 0px;
  margin-left: 19px;
  margin-bottom: 3px;
  float: left;
}

.call-slider {
  border-top: 2px solid red;
  float: left;
  width: 40%;
}

.incoming {
  color: #fff;
  font-size: 10px;
  margin-left: 8px;
  margin-top: 5px;
  float: left;
}

.incoming-text {
  float: left;
  color: #fff;

  margin-top: 3px;
  margin-left: 5px;
  margin-right: 3px;
}

.chat-call {
  font-size: 35px;
  float: right;
  margin-right: 130px;
  margin-top: 10px;
  color: #3fad72;
}

.react-audio-player {
  position: absolute;
  right: 40px;
  top: -85px;
}

.newUi > a {
  color: #000;
  text-decoration: none;
}

.ftr {
  width: 220px;
  padding: 5px;
  padding-left: 5px;
  border-radius: 6px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.fftr {
  border: 1px solid #ffffff;
  width: 147px;
}

.ftricon {
  font-size: 20px;
  color: green;
}

.nav-tab-main .common-tab-menu .nav-pills .nav-item .nav-link {
  padding: 13px 81px;
  margin-left: 1px;
  margin-right: 1px;
}

/* Refactor css start */
.orders-list .orders-date-input {
  margin-right: 10px;
  height: 40px;
}

/* Refactor css End */

.searchSection
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: #f8f8f8;
  border: 1px solid #dee2e6;
}

.orders-list
  div
  .common-table
  .MuiDataGrid-main
  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:has(> .menuIcon-dropdown-start) {
  overflow: unset !important;
}
