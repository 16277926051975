.profile-img {
  width: 122px;
  height: 122px;
  border-radius: 3px;
  background: #000;
}
.profile-name-text {
  font: normal normal normal Roboto;
  margin-top: 6px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
}
