.register-card {
  /* border: 1px solid #3fad72; */
  background-color: #ffffff;
  opacity: 0.9;

  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;

  /* box-shadow: 0 0 10px rgb(0 0 0 / 15%); */
}
.register-heading {
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 100%;
  margin-top: 50px;
}
.register-name {
  padding: 10px;
  width: 30%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  margin-right: 20px;
  border-radius: 5px;
}
.register-lastname {
  padding: 10px;
  width: 30%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
}
.register-email {
  padding: 10px;
  width: 62%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
}
.register-password {
  padding: 10px;
  width: 62%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
}
.register-confirm-password {
  padding: 10px;
  width: 62%;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
}
.register-check {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.register-label {
  font-size: 20px;
  margin-left: 15px;
  color: #000;
}
.register-button {
  border: 1px solid #3fad72;
  /* padding: 12px; */
  width: 30%;
  max-width: 100%;
  border-radius: 40px;
  background-color: #3fad72;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  /* margin-bottom: 50px; */
}
.dark-overlay {
  background-color: #0499f2;
  background-image: linear-gradient(315deg, #0499f2 0%, #26f596 74%);  
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  /* height: 100%; */
}
.register-field {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ccc;
  width: 92%;
  padding-bottom: 5px;
}
.register-logo > img {
  width: 40%;
    display: block;
    margin: 0 auto;
}

/* Update Admin page design */
.admin-login .profilePicUpload .is-invalid{
  border: 1px solid #dc3545;
}