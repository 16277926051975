.active-members-box {
    float: left;
    width: 18%;
    margin-left: 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.revenue {
    margin-left: 25px;
    float: left;
    width: 83%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.num-members {
    float: left;
    margin-left: 28px;
    margin-top: 20px;
    width: 85%;
    margin-bottom: 0;
    color: #3fad72;
}
.mem-text {
    margin-left: 28px;
    font-size: 10px;
    float: left;
    margin-bottom: 20px;
}