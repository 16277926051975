body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loader {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  margin-left: 50%;
}
.loaderback {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.menuIcon-dropdown-start .dropdown-menu {
  right: 150px;
  top: 70px;
}

.notFound {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: auto;
  left: 38%;
}
