.analytics-section {
    float: left;
    width: 83%;
    margin-left: 25px;
    margin-top: 40px;
}
.sale-section {
    float: left;
    border: 1px solid #ccc;
    width: 22.5%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.gross-text {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
}
.gross-text2 {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 700;
    margin-left: 20px;
    color: #3fad72;
}
.growth-sale {
    float: left;
    border: 1px solid #eee;
    background-color: #eee;
    width: 34%;
    margin-left: 20px;
    padding: 5px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.growth-sale > p {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 500;
    float: right;
    color: green;
}
.growth-sale > p > svg {
    font-size: 20px;
}
.growth-reduce {
    float: left;
    border: 1px solid #eee;
    background-color: #eee;
    width: 34%;
    margin-left: 20px;
    padding: 5px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.growth-reduce > p {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 500;
    float: right;
    color: red;
}
.growth-reduce > p > svg {
    font-size: 20px;
}
.profit-section {
    float: left;
    border: 1px solid #ccc;
    width: 22.5%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-left: 25px;
}
.gross-text3 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 20px;
}
.piechart-result-section {
    float: left;
    border: 1px solid #ccc;
    width: 45%;
    margin-left: 0px;
    margin-top: 50px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.cost-break {
    float: left;
    width: 40%;
}
.cost-heading {
    margin-bottom: 0;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
}
.mark-text {
    margin-left: 15px;
    margin-top: 50px;
    color: blue;
    font-weight: 700;
    margin-bottom: 0;
}
.mark-numbers {
    margin-left: 15px;
    font-weight: 700;
}
.mark-text2 {
    margin-left: 15px;
    margin-top: 30px;
    color: green;
    font-weight: 700;
    margin-bottom: 0;
}
.pie-chart-section {
    float: left;
    width: 60%;
    padding-bottom: 40px;
    margin-top: 5px;
}
.piechart-result {
    width: 70%;
    margin-left: 40px;
    margin-top: 55px;
}
.cross-section {
    float: left;
    border: 1px solid #ccc;
    width: 45%;
    margin-left: 0px;
    margin-top: 50px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin-left: 52px;
}
.cross-revenue {
    float: left;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: green;
    margin-top: 15px;
    width: 80%;
}
.cross-amount {
    float: right;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 700;
    margin-top: 15px;
}
.cross-amount2 {
    float: right;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 700;
}
.cross-border {
    float: left;
    border-bottom: 1px solid #ccc;
    width: 94%;
    margin-left: 15px;
}
.cost-heading2 {
    margin-bottom: 0;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
    float: left;
    width: 100%;
}
.cross-revenue2 {
    float: left;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
    width: 80%;
}
.cross-amount3 {
    float: right;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 700;
    color: green;
    margin-top: 15px;
}
.graph-section {
    float: left;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    width: 94.9%;
    margin-top: 40px;
}
.money-analytics {
    margin-bottom: 0;
    font-size: 15px;
    color: green;
    font-weight: 700;
    margin-left: 54px !important;
}
.money-analytics-detail {
    margin-bottom: 0;
    font-size: 11px;
    margin-left: 25px !important;
}
.vendor-user-details {
    float: left;
    width: 38%;
    margin-top: 29px;
    margin-left: 180px;
}
.vendor-user-details > p {
    margin-bottom: 0;
}
.vendor-email-user {
    font-size: 13px;
    font-weight: 700;
    width: 68%;
}
.vendor-email-text {
    font-size: 12px;
}
.vendor-user-details2 {
    float: left;
    width: 38%;
    margin-top: 29px;
    margin-left: 20px;
}
.vendor-user-details2 > p {
    margin-bottom: 0;
}
.analytics-logo {
    float: left;
    margin-top: 20px;
    margin-left: 10px;
    width: 10%;
}
.analytics-logo > img {
    width: 100%;
}