.info-form {
    float: left;
    width: 81%;
    margin-left: 25px;
    margin-top: 35px;
}
.info-bottom {
    float: left;
    border-bottom: 5px solid green;
    width: 100%;
}
.info-name-field {
    float: left;
    width: 100%;
}
.info-name-field > p {
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
    float: left;
    width: 18%;
}
.info-name-field > input {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);

}
.info-country {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
    padding: 5px;
    width: 40%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border: 1px solid #ccc;
}
.info-address2 {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
    padding: 5px;
    width: 40%;
    height: 100px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border: 1px solid #ccc;
}
.info-zip {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
    padding: 5px;
    width: 20%;
}
.info-save {
    margin-left: 215px;
    margin-top: 50px;
    width: 12%;
    float: left;
    padding: 9px;
    border: 1px solid green;
    background-color: green;
    color: #fff;
    border-radius: 5px;
}
.info-cancel {
    margin-top: 58px;
    float: left;
    font-size: 18px;
    margin-left: 40px;
    font-weight: 500;
    color: red;
}