.table-header-title {
  font-size: 18px;
  color: #020202;
  font-weight: 500;
}
.search-table-header {
  margin-bottom: 16px;
}
.table-header-title-drop {
  background: #f8f8f8;
  border-radius: 3px;
  width: 57px;
  height: 45px;
  margin: 0 10px;
}
.table-header-title-drop .custom_dropdown{
  width: auto;
}
.table-header-title-drop .custom_dropdown .dropdown-btn{
  width: auto;
  background-color: #FFFFFF !important;
}
.table-header-title-drop .form-select {
  background-color: #f8f8f8;
}

/* select-------------------------------------> */
.menu::after {
  content: "";
  position: absolute;
  right: 7px;
  top: 18px;
  width: 0.5em;
  height: 0.5em;
  border-left: 0.1rem solid #000;
  border-bottom: 0.1rem solid #000;
  transform: rotate(315deg);
  transition: transform 0.3s;
}

.menu.open::after {
  transform: rotate(135deg);
}

.arrow-up::after {
  transform: rotate(135deg);
  top: 28px;
}

.menu {
  width: -webkit-fill-available;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
}

.selectOps {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font: normal normal normal 15px/20px Roboto;
  border: 1px solid #dee2e6;
}
.selectOps:hover {
  color: #04984a;
}
.selectOps:not(:first-child) {
  position: relative;
}

.selectOps:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 5px;
  width: 90%;
  height: 1px;
  background-color: rgb(255, 255, 255);
}

.menu.open::after {
  transform: rotate(135deg);
}

.menu:not(.open) li:not(:first-of-type) {
  display: none;
}

.menu:not(.open) li:not(:first-of-type) {
  display: none;
}
.open {
  position: absolute !important;
  z-index: 999;
  background: #fff;
  width: inherit;
}