.message-section {
    float: right;
    width: 16%;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    border-radius: 10px;
    margin-right: 10px;
    padding-bottom: 50px;
}
.messages {
    margin-top: 10px;
    text-align: center;
    color: #fff;
}
.message-bubble {
    font-size: 110px;
    color: #fff;
    margin-top: 32px;
    margin-left: 10px;
    float: left;
}
.new-number {
    margin-bottom: 0;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    margin-top: 10px;
    color: yellow;
}
.new-text {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: yellow;
}
.text-numbers {
    float: left;
    margin-left: 15px;
    margin-top: 5px;
}
.divider {
    border-bottom: 1px solid #fff;
    float: left;
    width: 35%;
    margin-top: 10px;
}
.text-numbers2 {
    float: left;
    margin-left: 15px;
}
.new-number2 {
    margin-bottom: 0;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    margin-top: 0px;
    color: #fff;
}
.new-text2 {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #fff;
}