.item-serve {
    float: left;
    width: 20%;
    margin-top: 60px;
}
.product-serve {
    width: 40%;
    display: block;
    margin: 0 auto;
}
.serve-details {
    float: left;
    margin-top: 65px;
}
.serve-name {
    font-weight: 700;
    color: #43c27e;
}
.serve-sku {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    color: #888;
}
.serve-order {
    float: left;
    border: 1px solid #eee;
    background-color: #eee;
    width: 54%;
    margin-left: 30px;
    padding: 12px;
    margin-top: 30px;
}
.serve-quantity {
    float: left;
    margin-bottom: 0;
    color: #888;
}
.serve-amount {
    float: right;
    margin-bottom: 0;
    color: #000;
}
.product-serve-live {
    width: 75%;
    display: block;
    margin: 0 auto;
    margin-left: 40px;
}
.serve-details-live {
    float: left;
    margin-top: 65px;
    margin-left: 85px;
}
.serve-order-live {
    float: left;
    border: 1px solid #eee;
    background-color: #eee;
    width: 97%;
    margin-left: 0px;
    padding: 12px;
    margin-top: 30px;
}