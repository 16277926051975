.create-coupon {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  width: 83%;
}
.coupon-select {
  float: left;
  margin-left: 140px;
  margin-top: 60px;
  width: 73%;
}
.coupon-heading {
  float: left;
  margin-left: 50px;
}
.coupon-heading2 {
  float: left;
  margin-left: 38px;
}
.coupon-select > h5 {
  float: left;
}
.coupon-select > select {
  float: left;
  margin-left: 10px;
  width: 110px;
  padding-bottom: 3px;
  border: 2px solid #ccc;
}
.coupon-code {
  margin-left: 10px;
  width: 45%;
  border: 1px solid #ccc;
}
.coupon-codeArea {
  margin-left: 60px;
  width: 45%;
  border: 1px solid #ccc;
}
.auto-coupon {
  float: left;
  width: 85%;
  margin-left: 148px;
  margin-top: 15px;
  font-size: 12px;
  margin-bottom: 0 !important;
}
.coupon-desc {
  font-size: 10px;
  float: left;
  margin-left: 150px;
}
.coupon-select2 {
  float: left;
  margin-left: 120px;
  margin-top: 60px;
  width: 73%;
}
.coupon-selectArea {
  float: left;
  margin-left: 120px;
  margin-top: 60px;
  width: 73%;
}
.coupon-select2 > h5 {
  float: left;
}
.coupon-select3 {
  float: left;
  margin-left: 100px;
  margin-top: 60px;
  width: 73%;
}
.coupon-select3 > h5 {
  float: left;
}
.coupon-desc2 {
  font-size: 10px;
  margin-left: 182px;
  margin-top: 5px;
}
.coupon-select4 {
  float: left;
  margin-left: 220px;
  margin-top: 60px;
  width: 58%;
}
.coupon-select4 > h5 {
  float: left;
}
.coupon-picker {
  margin-left: 16px;
}
.coupon-select5 {
  float: left;
  margin-left: 245px;
  margin-top: 60px;
  width: 58%;
}
.coupon-select5 > h5 {
  float: left;
}
.coupon-select6 {
  float: left;
  margin-left: 205px;
  margin-top: 60px;
  width: 58%;
}
.coupon-select6 > h5 {
  float: left;
}
.email-options {
  float: left;
  margin-left: 10px;
  width: 160px !important;
  padding-bottom: 3px;
  border: 2px solid #ccc;
}
.save-btn {
  float: right;
  margin-top: 70px;
  margin-right: 180px;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  border-radius: 5px;
  color: #fff;
  padding: 9px;
  width: 10%;
}
.save-btn2 {
  float: left;
  margin-top: 70px;
  margin-left: 100px;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  border-radius: 5px;
  color: #fff;
  padding: 9px;
  width: 14%;
  max-width: 100%;
}
.coupon-heading-feed > h5 {
  float: left;
  margin-left: -41px;
}
.toggle-feed {
  float: left;
  margin-top: -2px;
  margin-left: 17px;
}
