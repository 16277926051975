.fileContainer p {
    display: none;
}
.fileContainer {
    width: 25%;
    float: left;
    margin-left: 10px;
    padding: 5px;
    border-radius: 2px;
    height: 110px;
}
.fileContainer .chooseFileButton {
    padding: 5px;
    border-radius: 2px;
    font-size: 7px;
}
.fileContainer .uploadPictureContainer {
    padding: 0;
    position: absolute;
    top: -89px;
    left: -9px;
    width: 112%;
    height: 120px;
}