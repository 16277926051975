.user-heading {
    float: left;
    margin-top: 25px;
    margin-left: 25px;
    width: 80%;
}
.profile-card {
    float: left;
    width: 25%;
    border: 1px solid #eee;
    margin-left: 25px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.profile-cover {
    float: left;
    width: 100%;
}
.profile-image {
    width: 20%;
    display: block;
    margin: 0 auto;
    position: relative;
    bottom: 30px;
    border-radius: 100px;
}
.profile-details > li {
    list-style: none;
    text-align: center;
}
.profile-details {
    padding-left: 0;
    position: relative;
    bottom: 15px;
}
.profile-designation {
    font-size: 12px;
    color: #888;
}
.profile-btn {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    color: #fff;
    padding: 5px;
}
.details-card {
    float: left;
    width: 50%;
    border: 1px solid #eee;
    margin-left: 35px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    padding-bottom: 20px;
}
.product-options {
    float: left;
    padding-left: 25px;
    margin-top: 40px;
    margin-left: -25px;
    width: 59%;
    max-width: 60%;
  } 
  .product-options > li {
    list-style: none;
    text-align: center;
    color: #fff;
    border: 1px solid #3FAD72;
    background-color: #3FAD72;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;
    width: 17%;
    float: left;
    margin-left: 20px;
  }
  .profile-name2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 46%;
}
.profile-email2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 46%;
    margin-left: 20px;
}
.profile-birth2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 29.5%;
    margin-top: 20px;
}
.gender-select2 {
    padding: 7px;
    width: 29%;
    margin-left: 20px;
    border: 1px solid #ccc;
}
.profile-phone2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 30%;
    margin-top: 20px;
    margin-left: 20px;
}
.profile-address2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 91.5%;
    margin-top: 20px;
    margin-left: 25px;
}
.profile-about2 {
    border: 1px solid #ccc;
    padding: 5px;
    width: 91.5%;
    margin-top: 20px;
    height: 80px;
    margin-left: 25px;
}
.refund-btn {
    border: 1px solid red !important;
    background-color: red !important;
}
.reorder-btn {
    border: 1px solid gold !important;
    background-color: gold !important;
}
.track-btn {
    border: 1px solid blue !important;
    background-color: blue !important;
}
.i-btn {
    border: 1px solid orange !important;
    background-color: orange !important;
}
.i-btn2 {
    border: 1px solid purple !important;
    background-color: purple !important;
}