.attributes-section .bread-crumb {
  margin-bottom: 6px;
}

.attribute.section {
  float: left;
  width: 30%;
}

.attribute-heading {
  margin-left: 25px;
  float: left;
  margin-top: 20px;
}

.attribute-heading>p {
  font-size: 12px;
  font-weight: 400;
  color: #888;
}

.new-att {
  float: left;
  margin-top: 30px;
  width: 100%;
}

.edit-att {
  float: left;
  width: 100%;
  margin: 0;
  margin-top: 25px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.name-att>input {
  width: 65%;
  max-width: 100%;
  padding-left: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.att-desc {
  font-size: 10px;
  margin-top: 5px;
  color: #888;
  font-style: italic;
}

.att-card-border {
  float: left;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 56%;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 125px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.att-details {
  float: left;
  padding-left: 0;
  margin-bottom: 0;
  padding: 5px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  background-color: #3fad72;
}

.att-details>li {
  float: left;
  list-style: none;
  margin-right: 104px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
}

.att-details2>li {
  list-style: none;
  float: left;
  font-size: 12px;
  font-weight: 500;
}

.att-details2 {
  float: left;
  padding-left: 6px;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
}

.size-att {
  margin-left: 110px;
  width: 15%;
}

.select-att {
  width: 15%;
  margin-left: 15px;
}

.custom-att {
  margin-left: 0px;
  width: 19% !important;
}

.term-att {
  margin-left: 40px;
}

.del-att {
  font-size: 9px;
  color: blue;
}

.added-att {
  float: left;
  width: 100%;
  background-color: #3fad7282;
}

.att-setting {
  font-size: 16px;
  margin-left: 60px;
}

.att-setting2 {
  font-size: 16px;
  margin-left: 2px;
}

.att-details2>li>a {
  color: #3fad72;
}

.attribute-submit {
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
}

/* Add attributes */
.add-attribute .attribute-form-label {
  color: #020202;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}

.add-attribute .color-info {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #B1B1B1;
  opacity: 1;
}

.add-attribute .attribute-row {
  margin-top: 25px;
}

.add-attribute .add-attribute-btn {
  margin-top: 18px;
}

.add-attribute .add-child-attributes {
  align-items: center;
  display: flex;
  justify-content: center;
}

.add-attribute .add-child-attributes>a:hover {
  transform: scale(0.7, 0.7);
}

.add-attribute .add-child-attributes #add_icon_rounf {
  width: 30px;
  height: 30px;
}

/* child-attributes */
.add-attribute .child-attributes,
.add-new-child-attributes {
  margin-top: 23px;
}

.add-attribute .child-attributes .child-heading {
  margin-bottom: 26px;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
  font-weight: 600;
}

.child-heading {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.add-button {
  height: 40px;
}

.add-attribute-color {
  padding: 7px 10px;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 20px;
}

.color-box div {
  height: 30px;
  width: 30px;
  border-radius: 3px;
}

.upload-file-new {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: fit-content;
}

.upload-Label {
  color: #B1B1B1;
  font-size: 15px;
  line-height: 20px;
  padding: 9px 10px;
  position: relative;
  height: 42px;
}

.upload-icon {
  position: absolute;
  right: 10px;
}