.video-card2 {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  float: left;
  width: 30%;
  padding-bottom: 60px;
  margin-left: 20px;
}
.video-detail-carding {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin-left: 30px;
  float: left;
  width: 55% !important;
  padding-bottom: 60px;
  margin-top: 30px;
}
.tab-nav {
  float: left;
  margin-left: 20px;
  width: 10%;
  cursor: pointer;
}
.tab1 {
  /* border: 1px solid #3FAD72;
    background-color: #3FAD72;
    float: left;
    border-radius: 21px;
    padding: 7px;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 30px; */
  padding: 15px !important;
  background-color: rgba(42, 143, 86, 1) !important;
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 30px;
  width: 108%;
}
.tab1 > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
}
.active-tab {
  background-color: #fff !important;
  border: 1px solid rgba(42, 143, 86, 1);
}
.active-tab > p {
  color: rgba(42, 143, 86, 1);
}
.tab1-border {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 85.5%;
  margin-left: 20px;
  position: relative;
  bottom: 6px;
}
.nav-payment {
  font-size: 11px !important;
  padding-bottom: 2px;
}

.video-carder {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 60px;
  float: left;
  width: 37%;
}
.video-card2 {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  float: left;
  width: 30%;
  padding-bottom: 60px;
  margin-left: 20px;
}
.video-detail-carding {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin-left: 30px;
  float: left;
  width: 55% !important;
  padding-bottom: 60px;
  margin-top: 30px;
}
.tab-nav {
  float: left;
  margin-left: 22px !important;
  width: 10%;
  cursor: pointer;
}
.tabListing {
  /* border: 1px solid #3FAD72;
    background-color: #3FAD72;
    float: left;
    border-radius: 21px;
    padding: 7px;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 30px; */
  padding: 15px !important;
  /* background-color: rgba(42, 143, 86, 1) !important; */
  margin-left: 10px !important;
  border-radius: 24px 24px 5px 5px !important;
  margin-top: 30px;
  width: 108%;
}
.tabListing > p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 13px;
}
.active-tab {
  background-color: #fff !important;
  border: 1px solid rgba(42, 143, 86, 1);
}
.active-tab > p {
  color: rgba(42, 143, 86, 1);
}
.tab1-border {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 80%;
  max-width: 100%;
  margin-left: 20px;
  position: relative;
  bottom: 6px;
}
.nav-payment {
  font-size: 15px;
  padding-bottom: 2px;
}

.video-carder {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 60px;
  float: left;
  width: 37%;
}
.top-menulist {
  float: left;
  width: 84%;
  max-width: 100%;
}
.listingborder {
  float: left;
  border-bottom: 10px solid rgba(42, 143, 86, 1);
  width: 90%;
  /* margin-left: 20px; */
  position: relative;
  bottom: 6px;
}
