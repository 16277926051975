.coupon-section {
    float: left;
    width: 80%;
    max-width: 100%;
    margin-left: 28px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 40px;
    padding: 20px;
}
.coupon-inner {
    float: left;
    width: 100%;
    border: 1px solid gold;
    background-color: gold;
    border-radius: 14px;
}
.coupon-image {
    float: left;
}
.coupon-text {
    color: #fff;
    font-size: 18px;
    margin-top: 50px;
    margin-left: 45px;
    float: left;
}
.coupon-content {
    margin-left: 270px;
}