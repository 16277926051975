.checkbox:checked:before {
  background-color: green;
}

/* div[role="row"]>div:first-child {
  border-right: none;
  min-width: unset;
  flex: 0 0 0px;
} */
.common-table .MuiDataGrid-footerContainer button:hover {
  border: 1px solid #00833d !important;
  font: normal normal normal 14px Roboto;
  color: #00833d;
}
.common-table .MuiDataGrid-footerContainer button {
  border: 1px solid #dee2e6 !important;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  font: normal normal normal 14px Roboto;
  color: #020202;
}
.common-table .MuiDataGrid-footerContainer nav button.Mui-disabled {
  border: 1px solid #dee2e6 !important;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  font: normal normal normal 14px Roboto;
  color: #666666;
  opacity: 1;
}
.common-table .MuiDataGrid-footerContainer nav button.Mui-selected {
  background: green !important;
  color: #fff !important;
  font: normal normal normal 14px Roboto;
  border: 1px solid #00833d !important;
  border-radius: 3px !important;
}
.MuiDataGrid-row:nth-of-type(odd) {
  background-color: #fff;
}
.MuiDataGrid-row:nth-of-type(even),
.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #f8f8f8;
}

.common-table
  button.MuiButtonBase-root.MuiPaginationItem-outlined.MuiPaginationItem-outlinedPrimary {
  /* background: green !important;
    color: #fff !important; */
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px !important;
}

.common-table .MuiPagination-ul {
  flex-wrap: nowrap;
}

.common-table .MuiPagination-ul li:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.common-table .MuiPagination-ul li > button > svg {
  display: none;
}

.common-table .MuiPagination-ul li:first-child > button:hover {
  border: 1px solid #04984a;
  color: #04984a;
}
.common-table .MuiPagination-ul li:first-child > button::after {
  content: "Previous";
}

.common-table .MuiPagination-ul li:last-child > button:hover {
  border: 1px solid #04984a;
  color: #04984a;
}
.common-table .MuiPagination-ul li:last-child > button::after {
  content: "Next";
}

.common-table .MuiPagination-ul li:last-child {
  /* flex-basis: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .common-table .MuiDataGrid-selectedRowCount{
  display: none;
} */

.common-table {
  border: none !important;
}
.common-table .MuiDataGrid-cellContent,
.MuiPaginationItem-page {
  color: #020202;
}
.common-table .MuiDataGrid-columnHeaderTitle {
  color: #666666;
}
.common-table .MuiDataGrid-main {
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.table-record-list {
  position: absolute;
  color: #666666;
  font: normal normal normal 14px/19px Roboto;
}

.common-table
  .MuiDataGrid-main
  div
  .MuiDataGrid-virtualScroller
  .MuiDataGrid-virtualScrollerContent {
  max-height: 520px;
}
.common-table .MuiDataGrid-main div .MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
}
.common-table
  .MuiDataGrid-main
  div
  .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
.common-table
  .MuiDataGrid-main
  div
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

/* Handle */
.common-table
  .MuiDataGrid-main
  div
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 10px !important;
}
.common-table
  .MuiDataGrid-main
  div
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}
