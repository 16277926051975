.att-details3 {
    float: left;
    padding-left: 0;
    margin-bottom: 0;
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
}
.att-details3 > li {
    width: 20%;
    max-width: 100%;
    float: left;
    list-style: none;
    margin-right: 0px;
    font-size: 13px;
    font-weight: 600;
}
.size-att2 {
    margin-left: 284px;
}

.desc {
    margin-top: 10px;
}
.att-details4 {
    float: left;
    padding-left: 0;
    margin-bottom: 0;
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #ccc;
}
.att-details4 > li {
    float: left;
    list-style: none;
    margin-right: 172px;
    font-size: 13px;
    font-weight: 600;
}
.count-att {
    margin-left: 65px;
}
/* .check-allproducts2 {
    margin-top: 0px;
    margin-bottom: 5px !important;
} */
.category-select {
    border: 1px solid #ccc;
    padding:5px;
}
.desc-text {
    width: 100%;
    height: 140px;
}
.check-allproducts3 {
    margin-left: 28px;
}
.att-save-btn {
    border: 1px solid #3fad72;
    background-color: #3fad72;
    color: #fff;
    border-radius: 5px;
    padding: 7px;
    width: 35%;
    margin-top: 30px;
}