.percentages-section {
        float: right;
        width: 16%;
        max-width: 100%;
        border: 1px solid #fff;
        background-color: #fff;
        box-shadow: 0 0 10px rgb(0 0 0 / 15%);
        border-radius: 10px;
        margin-right: 10px;
        margin-top: 15px;
}
.percent-left {
    float: left;
    margin-left: 10px;
}
.percent-right {
    float: right;
    margin-right: 10px;
}
.percent-left2 {
    float: left;
    margin-left: 40px;
    font-size: 10px;
}
.percent-right2 {
    float: right;
    margin-right: 40px;
    font-size: 10px;
}
.percent-border {
    border-bottom: 1px solid #ccc;
    float: left;
    width: 100%;
}