.order-account-info{
  margin-top: 60px;
}
.order-info {
  float: left;
  margin-top: 30px;
  padding-left: 25px;
  border: 1px solid #3dc16233;
  width: 85%;
  background-color: #3dc16240;
  padding-top: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-top: 6px solid rgb(61 193 98 / 11%);
}
.order-info > h5 {
  color: rgba(61, 193, 98, 1);
  float: left;
}
.order-nav {
  float: right;
  margin-top: 3px;
}
.order-nav > li {
  list-style: none;
  float: left;
  font-size: 14px;
  font-weight: 600;
  margin-right: 30px;
  color: rgba(171, 175, 179, 1);
}
.active-nav {
  color: rgba(42, 143, 86, 1) !important;
}
.item-order {
  border-top: 1px solid #eee;
  float: left;
  width: 74%;
  margin-left: 15px;
  margin-top: 50px;
  border-bottom: 1px solid #eee;
}
.order-items {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  margin-left: 35px;
  float: left;
}
.item-nav {
  float: right;
  margin-top: 10px;
}
.item-nav > li {
  list-style: none;
  float: left;
  font-size: 18px;
  font-weight: 400;
  margin-left: 120px;
}
.item-shirt {
  float: left;
  width: 74.5%;
  padding-left: 30px;
  margin-top: 12px;
  margin-left: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.item-total {
  float: right;
  margin-top: 10px;
}
.item-total > li {
  list-style: none;
  float: left;
  margin-left: 135px;
}
.order-bill {
  float: right;
  margin-top: 20px;
}
.order-bill > li {
  list-style: none;
  float: left;
  font-size: 18px;
  font-weight: 400;
  margin-left: 35px;
}
.total-price {
  font-size: 16px !important;
  font-weight: 400;
  color: rgba(127, 99, 244, 1);
}
.bill-total {
  float: right;
  margin-right: 116px;
  width: 75%;
}
.order-bill2 {
  float: right;
  margin-top: 20px;
  margin-top: 0;
}
.order-bill2 > li {
  list-style: none;
  float: left;
  font-size: 18px;
  font-weight: 400;
  margin-left: 35px;
  margin-right: 57px;
}
.bill-total2 {
  float: right;
  margin-right: 60px;
  width: 75%;
}
.shipping-border {
  border-top: 1px solid #eee;
  float: right;
  width: 21%;
  margin-right: 112px;
}
.order-bill3 {
  float: right;
  margin-top: 20px;
  margin-top: 15px;
}
.order-bill3 > li {
  list-style: none;
  float: left;
  font-size: 18px;
  font-weight: 400;
  margin-left: 85px;
}
.invoice-details {
  float: left;
  width: 81%;
  margin-left: 40px;
  margin-top: 50px;
}
.invoice {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.invoice-input {
  width: 34%;
  border: 1px solid #ccc;
  padding-bottom: 5px;
}
.invoice-input::placeholder {
  font-size: 12px;
  color: #ccc;
  padding-left: 10px;
  padding-bottom: 5px;
}
.invoice-details2 {
  float: left;
  width: 81%;
  margin-left: 40px;
  margin-top: 20px;
}
.invoice-input2 {
  width: 55%;
  border: 1px solid #ccc;
  padding-bottom: 5px;
}
.invoice-input2::placeholder {
  font-size: 12px;
  color: #ccc;
  padding-left: 10px;
  padding-bottom: 5px;
}
.account-info {
  float: right;
  margin-right: 0px;
  font-weight: 700;
}
.customer-details {
  width: 30%;
  float: left;
  position: absolute;
  right: 100px;
  top: 770px;
}
.invoice-input3 {
  width: 100%;
  border: 1px solid #ccc;
  padding-bottom: 5px;
}
.invoice-input3::placeholder {
  font-size: 12px;
  color: #ccc;
  padding-left: 10px;
  padding-bottom: 5px;
}
.print-btn {
  border: 1px solid rgba(42, 143, 86, 1);
  background-color: rgba(42, 143, 86, 1);
  color: #fff;
  padding: 4px;
  width: 30%;
  padding-bottom: 7px;
  border-radius: 3px;
}
.label-btn {
  border: 1px solid rgba(243, 246, 249, 1);
  background-color: rgba(243, 246, 249, 1);
  color: rgba(70, 74, 83, 1);
  padding: 4px;
  width: 30%;
  padding-bottom: 7px;
  border-radius: 3px;
  margin-left: 15px;
}
.account-buttons {
  position: relative;
  left: 40px;
  top: 208px;
}
.address-info {
  float: left;
  color: rgba(61, 193, 98, 1);
  margin-left: 37px;
  margin-top: 205px;
}
.address-border {
  border-bottom: 1px solid #ccc;
  float: left;
  width: 76%;
  margin-left: 34px;
}
.billing-detail {
  float: left;
  margin-left: 36px;
  margin-top: 30px;
}
.billing-detail2 {
  float: right;
  margin-left: 36px;
  margin-top: 30px;
  margin-right: 225px;
}
.bill-address {
  font-size: 16px;
  font-weight: 400;
  color: rgba(171, 175, 179, 1);
}
.print-btn2 {
  border: none;
  background-color: transparent;
  color: #fff;
  padding: 1px;
  width: 100%;
  padding-bottom: 0px;
  border-radius: 3px;
}
.label-btn2 {
  border: 1px solid rgba(243, 246, 249, 1);
  background-color: rgba(243, 246, 249, 1);
  color: rgba(70, 74, 83, 1);
  padding: 4px;
  width: 35%;
  padding-bottom: 7px;
  border-radius: 3px;
  margin-left: 15px;
}
.account-buttons2 {
  position: relative;
  left: 0px;
  top: 50px;
  width: 184%;
  padding-bottom: 50px;
}
