.coupon-select-email {
  float: left;
  margin-left: 126px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-email > h5 {
  float: left;
  width: 22%;
}
.coupon-select-business {
  float: left;
  margin-left: 126px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-business > h5 {
  float: left;
  width: 22%;
}
.coupon-select-password {
  float: left;
  margin-left: 132px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-password > h5 {
  float: left;
  width: 19%;
}
.coupon-heading {
  float: left;
  margin-left: 50px;
}
.coupon-select-country {
  float: left;
  margin-left: 160px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-country > h5 {
  float: left;
}
.coupon-select-city {
  float: left;
  margin-left: 197px;
  margin-top: 60px;
  width: 69.4%;
}
.coupon-select-city > h5 {
  float: left;
}
.coupon-select-street {
  float: left;
  margin-left: 100px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-street > h5 {
  float: left;
}
.coupon-select-post {
  float: left;
  margin-left: 125px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-post > h5 {
  float: left;
}
.coupon-select-phone {
  float: left;
  margin-left: 93px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-phone > h5 {
  float: left;
}
.coupon-select-card {
  float: left;
  margin-left: 160px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-card > h5 {
  float: left;
}
.coupon-select-choice {
  float: left;
  margin-left: 125px;
  margin-top: 60px;
  width: 70%;
}
.coupon-select-choice > h5 {
  float: left;
}
.fileContainer {
  width: 28% !important;
  height: 160px !important;
}
.coupon-area {
  margin-left: 10px;
  width: 45%;
  border: 1px solid #ccc;
  padding-left: 5px;
  height: 140px;
}
.hava-versions {
  float: left;
  margin-left: 95px;
  margin-top: 10px;
}
.reg-vendor {
  float: left;
  width: 21%;
  margin-left: 325px;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.vendor-1 {
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 6px;
  padding-left: 5px;
}
.vend-forms {
  float: left;
  width: 80%;
  border: 1px solid #ccc;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #3fad7247;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.id-box {
  float: left;
  width: 18%;
  position: absolute;
  left: 473px;
  top: 1087px;
}
.id-box > img {
  float: left;
  width: 86%;
}
.coupon-select-new {
  float: left;
  margin-left: 178px;
  margin-top: 60px;
  width: 73%;
}
.coupon-select-new > h5 {
  float: left;
  width: 14.5%;
}
.add-user-select {
  float: left;
  margin-left: 140px;
  margin-top: 60px;
  width: 73%;
}
.add-user-select > h5 {
  float: left;
  width: 20%;
}

.add-user .form-check-label,
.form-check-input {
  text-align: left;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #020202;
  opacity: 1;
  cursor: pointer;
}
.add-user .add-user-btn {
  margin-top: 43px;
}
.invalid-feedback:first-letter {
  text-transform: uppercase;
}
.Upload-name {
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #020202;
  position: absolute;
}
.login-btn, .add-new-category {
  width: 100% !important;
  height: 40px !important;
  color: #ffffff !important;
  font-size: 15px !important;
  text-transform: unset !important;
  font-weight: unset !important;
  border: 1px solid #00833d !important;
  border-radius: 3px !important;
  background-color: #00833d !important;
}
.MuiLoadingButton-loading {
  color: transparent !important;
}
.MuiLoadingButton-loading .MuiLoadingButton-loadingIndicator {
  color: #fff !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.MuiLoadingButton-loading
  .MuiLoadingButton-loadingIndicator
  .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
}
.add-new-category:hover {
  background-color: #078a44 !important;
}
