.frequency-section {
    float: left;
    width: 38%;
    max-width: 100%;
    border: 1px solid rgb(243, 230, 230);
    margin-top: 30px;
    padding-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);

}
.frequency-section-new {
    float: left;
    width: 45%;
    max-width: 100%;
    border: 1px solid rgb(243, 230, 230);
    margin-top: 30px;
    padding-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);

}
.frequency-section2 {
    float: left;
    width: 57%;
    max-width: 100%;
    border: 1px solid rgb(243, 230, 230);
    margin-top: 30px;
    padding-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    /* padding-left: 45px;
    padding-top: 80px; */

}
.marg-left {
    margin-left: 35px;
}
.record-frequency {
    margin-left: 15px;
    margin-top: 10px;
}
.frequency1 {
    float: left;
    border: 1px solid #eee;
    background-color: #eee;
    margin-top: 15px;
    width: 88%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.frequency-text {
    margin-left: 20px;
    margin-top: 20px;
    float: left;
}
.freq-duration {
    float: right;
    margin-right: 30px;
}
.duration1 {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 16px;
}
.border-bottom-map {
    border-bottom: 1px solid #ccc;
    float: left;
    width: 100%;
    margin-top: 20px;
}
.view-all-map {
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    color: #3fad72;
}
.view-all-map > p{
    float: left;
    margin-right: 10px;
    margin-bottom: 0;
}
.revenue-change {
    margin-left: 15px;
    margin-bottom: 0;
    font-size: 16px;
}
.revenue-change1 {
    margin-left: 15px;
    font-weight: 700;
    margin-bottom: 0;
}
.chartjs-render-monitor {
    margin-left: 0 !important;
}
.map-change1 {
    margin-left: 15px;
    font-weight: 700;
    margin-bottom: 0;
    float: left;
    color: blue;
    margin-right: 50px;
    margin-top: 20px;
}
.map-change2 {
    margin-left: 15px;
    font-weight: 700;
    margin-bottom: 0;
    float: left;
    color: goldenrod;
    margin-right: 50px;
    margin-top: 20px;
}
.map-change3 {
    margin-left: 15px;
    font-weight: 700;
    margin-bottom: 0;
    float: left;
    color: red;
    margin-top: 20px;
}
.card-area {
    float: left;
    margin-left: 20px;
    margin-top: 40px;
    width: 95%;
}