.add-product-section {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 100%;
  float: left;
  /* margin-left: 28px; */
  border-radius: 10px;
  margin-top: 60px;
  padding: 0 20px 50px 20px;
}
.add-product-header {
  float: left;
  /* margin-left: 20px; */
  width: 100%;
  margin-top: 20px;
}
.add-product-header > h3 {
  float: left;
  width: 80%;
}
.user-icon {
  font-size: 20px;
  margin-right: 3px;
  position: relative;
}
.user-select {
  border: none;
}
.new-user {
  float: right;
  margin-top: 5px;
}
.save-product-section {
  float: left;
  width: 99%;
  /* border-top: 2px solid darkgrey;
    border-bottom: 2px solid darkgray;
    background-color: #eee; */
}
.product-save {
  padding: 8px;
  width: 10%;
  border: 1px solid #3fad72;
  background: #3fad72;
  color: #fff;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.product-back {
  float: right;
  margin-right: 20px;
  margin-top: 22px;
}
.back-arrow {
  float: left;
  position: relative;
  top: 2px;
  right: 6px;
}
.back-text {
  margin: 0;
  float: left;
  font-size: 14px;
}
.enable-product {
  float: left;
  margin-top: 50px;
  margin-left: 25px;
}
.enable-product > h5 {
  margin: 0;
}
.enable-product > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
}
.edit-name {
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name > h5 {
  margin: 0;
}
.edit-name > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.input-product {
  margin-top: 49px;
  margin-left: 15px;
  width: 62%;
  border: 1px solid #ccc;
}
.edit-name2 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name2 > h5 {
  margin: 0;
  margin-left: 90px;
}
.edit-name2 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name3 {
  float: left;
  margin-top: 50px;
  margin-left: -26px;
}
.edit-name3 > h5 {
  margin: 0;
  margin-left: 83px;
}
.edit-name3 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.advance {
  margin-left: 15px;
  position: relative;
  left: 16px;
  margin-top: 5px;
  margin-bottom: 0;
}
.edit-name4 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name4 > h5 {
  margin: 0;
  margin-left: 46px;
}
.edit-name4 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name10 {
  float: left;
  margin-top: 50px;
  margin-left: 40px;
}
.edit-name10 > h5 {
  margin: 0;
  margin-left: 46px;
}
.edit-name10 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.input-product2 {
  margin-top: 49px;
  margin-left: 15px;
  width: 25%;
  border: 1px solid #ccc;
}
.edit-name5 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name5 > h5 {
  margin: 0;
  margin-left: 16px;
}
.edit-name5 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name6 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name6 > h5 {
  margin: 0;
  margin-left: 62px;
}
.edit-name6 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name7 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name7 > h5 {
  margin: 0;
  margin-left: 69px;
}
.edit-name7 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name8 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name8 > h5 {
  margin: 0;
  margin-left: 65px;
}
.edit-name8 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name9 {
  width: 12%;
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
}
.edit-name9 > h5 {
  margin: 0;
  margin-left: 0px;
}
.edit-name9 > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.product-date {
  margin-top: 52px;
  margin-left: 16px;
  float: left;
}
.product-date2 {
  margin-top: 52px;
  margin-left: 30px;
}
.product-to {
  float: left;
  position: relative;
  top: 54px;
  left: 14px;
}
.fileContainer {
  width: 22%;
}
.edit-name-wholesale {
  float: left;
  margin-top: 50px;
  margin-left: -26px;
}
.edit-name-wholesale > h5 {
  margin: 0;
  margin-left: 55px;
}
.edit-name-wholesale > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.inv-btn {
  margin-right: 20px;
  border: 1px solid blue;
  background-color: blue;
  color: #fff;
  padding: 9px;
  width: 20%;
  max-width: 100%;
  border-radius: 5px;
  margin-left: 275px;
  margin-top: 0px;
}
.prod-btn {
  border: 1px solid #3fad72;
  background-color: #3fad72;
  color: #fff;
  padding: 9px;
  width: 20%;
  border-radius: 5px;
}
.btn-section {
  float: right;
  width: 100%;
}
.additional-images {
  z-index: 99999;
  position: relative;
  bottom: 16px;
  font-size: 20px;
  color: #3fad72;
  cursor: pointer;
  right: 33px;
}
.add-new-images {
  float: left;
  margin-left: 191px;
  width: 100%;
}
.product-images-boxes {
  float: left;
  width: 55%;
}
.subtract-images {
  z-index: 99999;
  position: relative;
  bottom: 16px;
  font-size: 20px;
  color: #3fad72;
  cursor: pointer;
  right: 30px;
}
.seo-btn {
  padding: 8px;
  width: 15%;
  border: 1px solid #3fad73;
  background: #3fad72;
  color: #fff;
  float: left;
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.seo-keyphrase {
  float: left;
  width: 100%;
  margin-left: 50px;
  margin-top: 30px;
}
.keyphrase-input {
  float: left;
  width: 55%;
  margin-top: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border: 1px solid #eee;
}
.keyphrase-text {
  float: left;
  width: 55%;
  margin-top: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border: 1px solid #eee;
  height: 100px;
}
.enable-product2 {
  float: left;
  margin-top: 50px;
  margin-left: 25px;
}
.edit-name2-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name2-redeem > h5 {
  margin: 0;
  margin-left: 70px;
}
.edit-name2-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name3-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name3-redeem > h5 {
  margin: 0;
  margin-left: 50px;
}
.edit-name3-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name4-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name4-redeem > h5 {
  margin: 0;
  margin-left: 50px;
}
.edit-name4-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name5-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name5-redeem > h5 {
  margin: 0;
  margin-left: 19px;
}
.edit-name5-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name6-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name6-redeem > h5 {
  margin: 0;
  margin-left: 65px;
}
.edit-name6-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name7-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name7-redeem > h5 {
  margin: 0;
  margin-left: 65px;
}
.edit-name7-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.edit-name8-redeem {
  max-width: 100%;
  float: left;
  margin-top: 50px;
  margin-left: 50px;
  width: 18%;
}
.edit-name8-redeem > h5 {
  margin: 0;
  margin-left: 65px;
}
.edit-name8-redeem > p {
  margin: 0;
  font-size: 10px;
  color: #888;
  float: right;
  margin-right: 15px;
}
.__padding-create {
  padding: 0 50px;
}
.margin-right-10 {
  margin-right: 1.5rem !important;
}
.padding-none {
  padding-left: 0 !important;
}
.padding-left {
  padding-left: 10px !important;
}
.font-size-btn {
  font-size: 14.5px;
  margin-bottom: 30px;
}
