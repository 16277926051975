.user-text {
  margin-left: 28px;
  float: left;
  margin-bottom: 0;
  margin-top: 10px;
}
.users-section {
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  width: 65%;
  float: left;
  margin-left: 28px;
  border-radius: 10px;
  height: 490px;
  overflow-y: scroll;
  overflow-x: clip;
}
.customized {
  border: none;
  font-size: 16px;
  padding: 3px;
  width: 25%;
  background-color: #3fad72;
  color: #fff;
  float: right;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;
}
.border-bottom {
  border-bottom: 1px solid #eee;
  margin-left: 10px;
  float: left;
  width: 97%;
  margin-top: 10px;
}
.user-list-plus {
  margin-top: 20px;
  float: left;
  width: 100%;
}
.user-list-plus > li {
  list-style: none;
  float: left;
  margin-right: 140px;
  margin-left: 51px;
  margin-top: 10px;
}
.user-list {
  float: left;
  width: 74%;
}
.circle-name {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: grey;
  float: left;
  margin-top: 50px;
}
.user-details {
  float: left;
  width: 100%;
  margin-left: 40px;
}
.user-image {
  width: 6%;
  margin-top: 25px;
  margin-left: 28px;
  float: left;
}
.user-name-plus {
  margin-top: 43px;
  float: left;
  margin-left: 18px;
  width:10%
}
.user-email {
  margin-top: 43px;
  float: left;
  margin-left: 58px;
}
.user-button {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #3fad72;
  margin-top: 30px;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
  margin-left: 38px;
}
.user-button2 {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #4265af;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
  margin-left: 38px;
}
.user-button3 {
  font-size: 10px;
  width: 12%;
  border: none;
  background-color: #f6c262;
  margin-top: 10px;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}
.user-button4 {
  font-size: 9px;
  width: 12%;
  border: none;
  background-color: #f6c262;
  padding: 6px;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}
.user-button-big {
  font-size: 10px;
  width: 4.5%;
  border: none;
  background-color: #4a85fc;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 5px;
  position: absolute;
  height: 58px;
}
.user-button-big2 {
  font-size: 10px;
  width: 4.5%;
  border: none;
  background-color: #e254a6;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 64px;
  position: absolute;
  height: 58px;
}
.user-button-big3 {
  font-size: 10px;
  width: 4.5%;
  border: none;
  background-color: #47c7ad;
  margin-top: 27px;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin-left: 123px;
  position: absolute;
  height: 58px;
}
.notes {
  color: #fff;
  padding-right: 5px;
}
.plus-btn {
  float: right;
  width: 22%;
}
.plus-button {
  font-size: 10px;
  width: 50%;
  border: none;
  background-color: #3fad72;
  margin-top: 45px;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
  margin-left: 38px;
}
.view-all {
  float: right;
  margin-right: 0px;
  margin-top: 25px;
  color: #000;
}
.plus-text {
  margin-left: 28px;
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
}
.user-plus {
  float: left;
  width: 80%;
}