.chat-section {
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  display: block;
  margin: 0 auto;
  width: 100%;
  float: left;
  position: absolute;
  height: 100%;
  top: 0;
}
.chat-icon {
  font-size: 32px;
  margin-left: 20px;
  margin-top: 30px;
  color: #3fad72;
  float: left;
}
.chat {
  float: left;
}
.chat > h6 {
  float: left;
  margin-top: 35px;
  margin-left: 15px;
  margin-bottom: 20px;
}
.chat-intro {
  border: 1px solid lightgrey;
  background-color: #f5f9fc;
  float: left;
  border-radius: 10px;
  width: 74%;
  margin-left: 20px;
}
.active-section {
  float: left;
  width: 18%;
}
.user-chat-image {
  width: 32%;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 30px;
}
.chat-intro > h6 {
  text-align: center;
  margin-bottom: 0;
}
.chat-intro > p {
  text-align: center;
  font-size: 10px;
  margin-top: 3px;
  color: #888;
  margin-bottom: 0;
}
.online {
  color: rgb(199, 233, 199) !important;
  font-weight: 600;
  margin-bottom: 20px !important;
}
.active-chats {
  float: left;
  margin-left: 20px;
  margin-top: 18px;
}
.active-chats > h6 {
  font-size: 13px;
}
.online-chats {
  float: left;
  border: 1px solid lightgray;
  background-color: lightgray;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-top: 16px;
  margin-left: 5px;
}
.num-chats {
  margin-bottom: 0;
  font-size: 12px;
  margin-left: 6px;
  font-weight: 700;
}
.collapse-icon {
  float: left;
  margin-left: 25px;
  font-size: 10px;
  position: relative;
  bottom: 12px;
  left: 22px;
}
.active-image {
  float: left;
  margin-bottom: 10px;
}
.active-user1 {
  margin-left: 35px;
  width: 18%;
  margin-top: 12px;
  float: left;
  border-radius: 30px;
}
.active-image > p {
  margin-top: 24px;
  margin-left: 12px;
  font-weight: 500;
  float: left;
  font-size: 14px;
}
.chat-section2 {
  float: left;
  border: 1px solid #f2f6fc;
  border-radius: 10px;
  background-color: #f2f6fc;
  width: 62%;
  max-width: 100%;
  margin-top: 30px;
  padding-bottom: 15px;
}
.receiver {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  width: 98%;
  max-width: 100%;
}
.receiver-image {
  float: right;
  width: 6%;
}
.online-dot {
  float: right;
  position: relative;
  left: 42px;
  top: 24px;
  color: limegreen;
}
.receiver-chat-border {
  float: right;
  border: 1px solid #3fad72;
  border-radius: 10px;
  width: auto;
  padding: 9px;
  background-color: #3fad72;
}
.receiver-chat-border > p {
  margin: 0;
  font-size: 12px;
  color: #fff;
}
.sender {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  width: 98%;
  max-width: 100%;
}
.sender-image {
  float: left;
  width: 6%;
}
.sender-chat-border {
  float: left;
  border: 1px solid #fff;
  border-radius: 10px;
  width: auto;
  padding: 9px;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.sender-chat-border > p {
  margin: 0;
  font-size: 12px;
  color: #888;
}
.online-dot2 {
  float: left;
  position: relative;
  left: -13px;
  top: 24px;
  color: limegreen;
}
.chat-ago {
  float: right;
  font-size: 8px;
  position: relative;
  top: 39px;
  left: 60px;
}
.chat-ago2 {
  float: left;
  font-size: 8px;
  position: relative;
  top: 40px;
  left: -62px;
}
.send-button-border {
  border: 1px solid #fefdff;
  background-color: #fefdff;
  float: left;
  width: 95%;
  margin-left: 15px;
  margin-top: 40px;
  border-radius: 8px;
}
.attach {
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  float: left;
}
.message-input {
  float: left;
  margin-top: 8px;
  margin-left: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 78%;
}
.emoji {
  position: relative;
  right: 115px;
  top: 7px;
  color: goldenrod;
}
.chat-send-btn {
  border: 1px solid #3fad72;
  background-color: #3fad72;
  font-size: 12px;
  padding: 4px;
  width: 11%;
  margin-top: 7px;
  float: left;
  margin-left: 12px;
  color: #fff;
}
.send-icon {
  font-size: 10px;
  margin-left: 4px;
  color: #fff;
}
.active3 {
  background-color: #eee;
  padding-bottom: 5px;
  float: left;
  width: 100%;
}
.search-icon-chat {
  font-size: 20px;
  color: green;
  position: relative;
  top: 32px;
  right: -71px;
}
.search-bar-chat {
  width: 75%;
  padding: 5px;
  padding-left: 48px;
  border-radius: 6px;
  margin-top: 30px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  float: right;
}
.usercards-section {
  float: left;
  width: 18%;
  margin-left: 10px;
  margin-top: 15px;
}
.calling-chat {
  width: 16%;
  display: block;
  margin: 0 auto;
  margin-top: 25px;
}
.msgCall {
  float: right;
  width: 90%;
  border: 1px solid #3fad72;
  background-color: #3fad72;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 15px;
}
.call-phone {
  float: left;
    border: 1px solid #fff;
    background-color: #fff;
    color: #3fad72;
    border-radius: 50px;
    padding: 5px;
    font-size: 40px;
    margin-left: 5px;
    margin-top: 5px;
}
.call-numbers {
  float: left;
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 3px;
}
.chat-messenger {
  float: left;
    font-size: 19px;
    color: #2a8f56;
    margin-left: 60px;
    margin-top: 35px;
    margin-bottom: 10px;
}
.search-area-product1 {
  float: left;
  position: relative;
  top: -29px;
  left: -37px;
  width: 100%;
}
.call-homeicon {
  font-size: 22px;
    margin-left: 8px;
    margin-top: 10px;
    color: #3fad72;
}